import React from 'react'
import { useSelector } from 'react-redux'

export default function HeaderApp ({ children }) {
  const shouldRender = useSelector((state) => (
    state.app.get('renderHeader', true)
  ))
  return shouldRender ? (
    <div id="header-app">
      <div className="header-app">
        {children}
      </div>
    </div>
  ) : null
}
