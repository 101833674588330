import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS } from 'services/user-account/actions'
import { SET_USER_DATA_LANGUAGE } from 'services/user/actions'
import { List, Map } from 'immutable'
import _get from 'lodash/get'
import { getPrimary as getPrimaryLanguage } from 'services/languages'
import { EN } from 'services/languages/constants'
import { getAuthIsLoggedIn } from 'services/auth'
import { LTM } from 'services/currency'
import { get as getConfig } from 'config'
import * as actions from './actions'

const config = getConfig()
const plansGraphQLActive = !!_get(config, ['features', 'checkout', 'planGraphQL'])

// -----------------------------------
// Watcher for checking data server side
// -----------------------------------

export function onPlansAppBootstrapPhase ({ after }) {
  return after(SET_APP_BOOTSTRAP_PHASE, async ({
    dispatch,
    state,
    prevState,
  }) => {
    const { app, auth, user } = state
    const { app: prevApp } = prevState
    const authToken = auth.get('jwt')
    const prevBootstrapComplete = prevApp.get('bootstrapComplete')
    const nextBootstrapComplete = app.get('bootstrapComplete')
    const language = getPrimaryLanguage(user.getIn(['data', 'language'], List([EN])))

    if (
      !authToken
      && nextBootstrapComplete
      && nextBootstrapComplete !== prevBootstrapComplete
    ) {
      // get plans on bootstrap complete for anonymous user on any page
      dispatch(actions.getPlansData({ language }))
    }
  })
    .when(() => !plansGraphQLActive)
}

// ---------------------------------------
// Watcher for getting user account details
// Get their currencyIso from userAccount
// and pass currencyOverride param to plans endpoint
// --------------------------------------
export function watchUserAccountBillingSubscriptionsData ({ after }) {
  return after(
    SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
    async ({ dispatch, state, action }) => {
      const { user } = state
      const { payload } = action
      const { data = Map() } = payload || Map()
      const language = getPrimaryLanguage(user.getIn(['data', 'language'], List([EN])))
      const currencyIso = data.getIn(['subscriptions', 'currencyIso'])
      const latamPricing = data.getIn(['subscriptions', 'latamPricing'])
      const currency = latamPricing ? LTM : currencyIso

      dispatch(actions.getPlansData({ language, currency }))
    },
  )
    .when(() => !plansGraphQLActive)
}

export function watchUserDataLanguage ({ after }) {
  return after(
    SET_USER_DATA_LANGUAGE,
    async ({ dispatch, state }) => {
      const { user, auth, userAccount } = state
      const authToken = auth.get('jwt')
      const language = getPrimaryLanguage(user.getIn(['data', 'language'], List([EN])))
      const currencyIso = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'currencyIso'])
      const latamPricing = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'latamPricing'])
      const currency = latamPricing ? LTM : currencyIso

      if (!authToken) {
        dispatch(actions.getPlansData({ language, currency }))
      }

      // only get plans data if the currencyIso is already set in the user account data
      if (authToken && currencyIso) {
        dispatch(actions.getPlansData({ language, currency }))
      }
    },
  )
    .when(() => !plansGraphQLActive)
}

// ---------------------------------------
// Watcher for settings plans data when logged in
// --------------------------------------
export function watchSetPlansData ({ after }) {
  return after(
    actions.SET_PLANS_DATA,
    async ({ dispatch, state }) => {
      const { user, userAccount, plans } = state
      const language = getPrimaryLanguage(user.getIn(['data', 'language'], List([EN])))
      const defaultPlans = plans.getIn(['data', 'plans'], List())
      const selectedPlan = plans.get('selection', Map())
      const currencyIso = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'currencyIso'])
      const latamPricing = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'latamPricing'])
      const currency = latamPricing ? LTM : currencyIso
      // find the matching sku in the newly fetched plans
      // that matches the previously selected sku
      const updatedSelectedPlan = defaultPlans.find((plan) => plan.get('sku') === selectedPlan.get('sku')) || Map()
      // if there is a plan selected
      if (
        selectedPlan.size > 0
        && defaultPlans.size > 0
        && updatedSelectedPlan.size > 0
        && !selectedPlan.equals(updatedSelectedPlan)
      ) {
        // @TODO: should not use Thunk here, we need to figure out a better solution
        // there is a lot of logic inside setPlansLocalizedSelection,
        // so we don't want to repeat it in multiple locations
        dispatch(
          actions.setPlansLocalizedSelection({ plan: updatedSelectedPlan, language, currency }),
        )
      }
    },
  )
    .when(({ state }) => {
      const { auth } = state
      return getAuthIsLoggedIn(auth) === true && !plansGraphQLActive
    })
}
