import toUpper from 'lodash/toUpper'
import { setSessionStorage } from 'services/local-preferences'
import { FACEBOOK_PIXEL_TEST_CODE_KEY } from 'services/event-tracking'

export default function init (state) {
  const { resolver } = state
  const fbPixelTestCode = toUpper(resolver.getIn(
    ['query', 'facebook_pixel_test_code'],
  ) || '')

  if (fbPixelTestCode) {
    setSessionStorage(FACEBOOK_PIXEL_TEST_CODE_KEY, fbPixelTestCode)
  }
}
