import { Map, fromJS } from 'immutable'
import * as actions from './actions'

export const initialState = Map()

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case actions.SET_VELOCITY_CHECK_PROCESSING:
      return state
        .set('velocityCheckProcessing', action.payload)
    case actions.SET_VELOCITY_CHECK_DATA:
      return state
        .setIn(['data', 'velocityCheck'], fromJS(action.payload.data))
        .set('velocityCheckProcessing', action.payload.processing)
    default:
      return state
  }
}
