import _get from 'lodash/get'
import { getSessionStorage } from 'services/local-preferences'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { SET_RESOLVER_DATA } from 'services/resolver/actions'
import { selectUserExternalId } from 'services/user/selectors'
import { URL_GAIA_PROD } from 'services/url/constants'
import { get as getCookie } from 'cookies-js'
import { get as getConfig } from 'config'
import {
  CREATE_SERVER_EVENT,
} from 'services/event-tracking/actions'
import { SET_CHECKOUT_INITIATED } from 'services/checkout/actions'
import {
  FACEBOOK_PIXEL_TEST_CODE_KEY,
  FACEBOOK_EVENT_NAME_PAGE_VIEW,
  FACEBOOK_EVENT_NAME_LEAD,
  FACEBOOK_EVENT_NAME_INITIATE_CHECKOUT,
  FACEBOOK_EVENT_ACTION_SOURCE,
  SERVER_EVENT_PAGE_VIEW,
  SERVER_EVENT_INITIATE_CHECKOUT,
  SERVER_EVENT_LEAD,
  postEventData,
} from '.'
import * as ACTIONS from './actions'

const config = getConfig()
const serverEventsActive = !!_get(config, ['features', 'events', 'server'])

export function watchCreateServerEvent ({ after }) {
  return after([
    ACTIONS.CREATE_SERVER_EVENT,
  ], async ({ action, state }) => {
    const { auth } = state
    const { payload } = action
    const { data } = payload
    const facebookPixelTestCode = getSessionStorage(FACEBOOK_PIXEL_TEST_CODE_KEY)
    postEventData({ auth, data, facebookPixelTestCode })
  })
}

export function watchServerEventPageView ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_RESOLVER_DATA,
  ], async ({
    action,
    state,
    prevState,
    dispatch,
  }) => {
    const { resolver, app } = state
    const { app: prevApp } = prevState
    const { type } = action
    const fbp = getCookie('_fbp')
    const externalId = selectUserExternalId(state)
    const facebookData = {
      event: SERVER_EVENT_PAGE_VIEW,
      data: {
        eventName: FACEBOOK_EVENT_NAME_PAGE_VIEW,
        actionSource: FACEBOOK_EVENT_ACTION_SOURCE,
        eventSourceUrl: `${URL_GAIA_PROD}${resolver.get('path')}`,
        ...(fbp) && { fbp },
        data: {
          externalId,
        },
      },
    }

    if (type === SET_APP_BOOTSTRAP_PHASE) {
      const nextBootstrapComplete = app.get('bootstrapComplete')
      const prevBootstrapComplete = prevApp.get('bootstrapComplete')
      // only fire once on initial page load
      if (nextBootstrapComplete && nextBootstrapComplete !== prevBootstrapComplete) {
        dispatch({
          type: CREATE_SERVER_EVENT,
          payload: facebookData,
        })
      }
    }

    if (type === SET_RESOLVER_DATA) {
      dispatch({
        type: CREATE_SERVER_EVENT,
        payload: facebookData,
      })
    }
  })
    .when(() => {
      return serverEventsActive
    })
}

export function watchServerEventCheckoutInitiated ({ after }) {
  return after([
    SET_CHECKOUT_INITIATED,
  ], async ({
    state,
    dispatch,
  }) => {
    const { resolver } = state
    const fbp = getCookie('_fbp')
    const externalId = selectUserExternalId(state)
    const path = resolver.get('path')
    const facebookData = {
      event: SERVER_EVENT_INITIATE_CHECKOUT,
      data: {
        eventName: FACEBOOK_EVENT_NAME_INITIATE_CHECKOUT,
        actionSource: FACEBOOK_EVENT_ACTION_SOURCE,
        eventSourceUrl: `${URL_GAIA_PROD}${path}`,
        ...(fbp) && { fbp },
        data: {
          externalId,
        },
      },
    }

    dispatch({
      type: CREATE_SERVER_EVENT,
      payload: facebookData,
    })
  })
    .when(() => {
      return serverEventsActive
    })
}

export function watchServerEventLead ({ after }) {
  return after([
    ACTIONS.SET_EVENT_LEAD,
  ], async ({
    state,
    action,
    dispatch,
  }) => {
    const { resolver } = state
    const { payload: email } = action
    const fbp = getCookie('_fbp')
    const externalId = selectUserExternalId(state)
    const path = resolver.get('path')
    const facebookData = {
      event: SERVER_EVENT_LEAD,
      data: {
        eventName: FACEBOOK_EVENT_NAME_LEAD,
        actionSource: FACEBOOK_EVENT_ACTION_SOURCE,
        eventSourceUrl: `${URL_GAIA_PROD}${path}`,
        ...(fbp) && { fbp },
        data: {
          email,
          externalId,
        },
      },
    }

    dispatch({
      type: CREATE_SERVER_EVENT,
      payload: facebookData,
    })
  })
    .when(() => {
      return serverEventsActive
    })
}
