/**
 * Page manager screen watchers
 * @module services/pm-screen/watchers
 */
import { Map } from 'immutable'
import { isSynced as resolverIsSynced } from 'services/resolver/synced'
import { SET_RESOLVER_DATA } from 'services/resolver/actions'
import { getUserLanguagePrimary } from 'services/user'
import { BOOTSTRAP_PHASE_COMPLETE } from 'services/app'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { SET_USER_DATA_LANGUAGE } from 'services/user/actions'
import { SET_AUTH_LOGIN_SUCCESS } from 'services/auth/actions'
import { PM_SCREEN_TYPE_MEMBER_HOME } from './types'
import { GET_PM_SCREEN, getPmScreen as getPmScreenAction } from './actions'
import { getPmScreen } from './watchers-helpers'
import { getPmScreenTypeFromResolver } from '.'

/**
 * Load a page manager screen by path
 */
export function onPmScreenShouldLoad ({ after }) {
  return after(
    [
      SET_RESOLVER_DATA,
      SET_APP_BOOTSTRAP_PHASE,
      SET_USER_DATA_LANGUAGE,
      SET_AUTH_LOGIN_SUCCESS,
    ],
    async ({ dispatch, state }) => {
      const {
        app = Map(),
        auth = Map(),
        user = Map(),
        resolver = Map(),
      } = state
      const language = getUserLanguagePrimary(user)
      const resolverSynced = resolverIsSynced(resolver, global.location)
      const resolverProcessing = resolver.get('processing')
      const bootstrapComplete = app.get('bootstrapPhase') === BOOTSTRAP_PHASE_COMPLETE
      if (!bootstrapComplete || resolverProcessing || !resolverSynced) {
        return
      }
      const pmScreenType = getPmScreenTypeFromResolver({ resolver, auth })
      if (pmScreenType) {
        const sectionsLimit = pmScreenType === PM_SCREEN_TYPE_MEMBER_HOME ? 4 : undefined

        dispatch(getPmScreenAction({ screenType: pmScreenType, language, sectionsLimit }))
      }
    },
  )
}

/**
 * Handle get page manager screen action
 */
export function onGetPmScreen ({ after }) {
  return after(GET_PM_SCREEN, async ({ dispatch, state, action }) => {
    const { payload: { screenType, language, sectionsLimit } = {} } = action
    const { auth = Map(), pmScreen = Map() } = state

    await getPmScreen({
      dispatch,
      auth,
      pmScreen,
      screenType,
      language,
      sectionsLimit,
    })
  })
}
