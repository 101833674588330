import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _some from 'lodash/some'
import { Map, fromJS } from 'immutable'
import {
  LOGIN_CODE_BLOCKED_USER,
  LOGIN_CODE_INVALID_TOKEN,
  LOGIN_CODE_EXPIRED_LOGIN_SESSION,
} from 'services/auth/login-codes'
import { userProfilesSwitch } from 'services/user-profiles/actions'
import { apiGetOnboardingStatus } from 'services/onboarding'
import { parse as parseUrl, format as formatUrl } from 'url'
import { URL_GET_STARTED, URL_HOME } from 'services/url/constants'
import { parse as parseQuery } from 'services/query-string'
import { setAppRenderable } from 'services/app/actions'
import {
  login, logout, loginProfile, renew, setTokenDataInStorage,
} from '.'

export const RESET_AUTH_DATA = 'RESET_AUTH_DATA'
export const SET_AUTH_DATA = 'SET_AUTH_DATA'
export const SET_AUTH_RENEW_TIMES = 'SET_AUTH_RENEW_TIMES'
export const SET_AUTH_LOGIN_SUCCESS = 'SET_AUTH_LOGIN_SUCCESS'
export const INCREMENT_AUTH_RENEWAL_COUNT = 'INCREMENT_AUTH_RENEWAL_COUNT'
export const RESET_AUTH_RENEWAL_COUNT = 'RESET_AUTH_RENEWAL_COUNT'
export const SET_AUTH_LOGIN_FAILED = 'SET_AUTH_LOGIN_FAILED'
export const SET_ATOMIC_AUTH_DATA = 'SET_ATOMIC_AUTH_DATA'
export const AUTH_CHANGE_PROFILE = 'AUTH_CHANGE_PROFILE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_RENEW_CHECK = 'AUTH_RENEW_CHECK'
export const GET_SHOPIFY_MULTIPASS_TOKEN = 'GET_SHOPIFY_MULTIPASS_TOKEN'
export const SET_SHOPIFY_MULTIPASS_TOKEN_ERROR = 'SET_SHOPIFY_MULTIPASS_TOKEN_ERROR'
export const SHOPIFY_MULTIPASS_ERROR_MESSAGE = 'SHOPIFY_MULTIPASS_ERROR_MESSAGE'
/**
 * Login codes that cause the application to logout
 */
const LOGIN_CODES_DO_LOGOUT = [
  LOGIN_CODE_BLOCKED_USER,
  LOGIN_CODE_INVALID_TOKEN,
  LOGIN_CODE_EXPIRED_LOGIN_SESSION,
]

function onAuthError (dispatch) {
  dispatch({ type: AUTH_ERROR })
}

export function resetAuthData () {
  return { type: RESET_AUTH_DATA }
}

export function authRenewCheck () {
  return { type: AUTH_RENEW_CHECK }
}

export function getShopifyMultipassToken (redirect) {
  return {
    type: GET_SHOPIFY_MULTIPASS_TOKEN,
    payload: { redirect },
  }
}

export function setShopifyMultipassTokenError (errorMessage) {
  return {
    type: SET_SHOPIFY_MULTIPASS_TOKEN_ERROR,
    payload: { errorMessage },
  }
}

export function setAuthData (data, processing = false) {
  return {
    type: SET_AUTH_DATA,
    payload: { data, processing },
  }
}

export function setAtomicAuthData (data) {
  return {
    type: SET_ATOMIC_AUTH_DATA,
    payload: data,
  }
}

export function setAuthLoginFailed (codes, statusCode) {
  return {
    type: SET_AUTH_LOGIN_FAILED,
    payload: { codes, statusCode },
  }
}

export function setAuthLoginSuccess (value) {
  return {
    type: SET_AUTH_LOGIN_SUCCESS,
    payload: value,
  }
}

/**
 * Increment the auth renewal count by 1 optional setting processing and processingRenew
 * @param {Object} options The options
 * @param {Boolean} [options.processing=true] The processing value
 * @param {Boolean} [options.processingRenew=true] The process renew value
 */
export function incrementAuthRenewalCount (options = {}) {
  const { processing = true, processingRenew = true } = options
  return {
    type: INCREMENT_AUTH_RENEWAL_COUNT,
    payload: { processing, processingRenew },
  }
}

/**
 * Reset the auth renewal count optional setting processing and processingRenew
 * @param {Object} options The options
 * @param {Boolean} [options.processing=false] The processing value
 * @param {Boolean} [options.processingRenew=false] The process renew value
 */
export function resetAuthRenewalCount (options = {}) {
  const { processing = false, processingRenew = false } = options
  return {
    type: RESET_AUTH_RENEWAL_COUNT,
    payload: { processing, processingRenew },
  }
}

export function setAuthPersistentData (data, processing = false) {
  return async function setAuthPersistentDataThunk (dispatch) {
    setTokenDataInStorage(data)
    dispatch(setAuthData(data, processing))
  }
}

export function doAuthRenew (auth, logoutAllDevices = false) {
  return async function doAuthRenewThunk (dispatch) {
    try {
      dispatch(incrementAuthRenewalCount())
      const data = await renew({ auth, logoutAllDevices })
      const codes = _get(data, 'auth.codes') || []
      if (_get(data, 'auth.success')) {
        dispatch(setAuthPersistentData(data.auth))
        dispatch(resetAuthRenewalCount())
      } else {
        onAuthError(dispatch)
        // If the user is blocked logged them out
        // or if their login session has expired, log out
        const doLogout = _some(LOGIN_CODES_DO_LOGOUT, (v) => _includes(codes, v))

        if (doLogout) {
          dispatch(doAuthLogout(auth))
        }
      }
      return _get(data, 'auth')
    } catch (e) {
      onAuthError(dispatch)
      throw e
    }
  }
}

export function doAuthLogin ({ username, password }) {
  return async function doAuthLoginThunk (dispatch) {
    try {
      dispatch({ type: AUTH_LOGIN })
      const data = await login({ username, password })
      const { statusCode } = data
      const { codes = Map() } = data
      if (_get(data, 'auth.success')) {
        dispatch(setAuthPersistentData(data.auth))
        dispatch(setAuthLoginSuccess(true))
      } else {
        dispatch(setAuthLoginFailed(codes, statusCode))
        onAuthError(dispatch)
      }
      return _get(data, 'auth')
    } catch (e) {
      const statusCode = e.status || 500
      const codes = ['serviceUnavailable']
      dispatch(setAuthLoginFailed(codes, statusCode))
      onAuthError(dispatch)
      throw e
    }
  }
}

function navigateOrRedirect (url = URL_HOME) {
  if (global && global.location) {
    let destinationUrl = url
    const parsedUrl = parseUrl(global.location.href)
    const parsedQuery = parseQuery(_get(parsedUrl, 'search'))
    const redirectString = _get(parsedQuery, 'redirect')

    if (redirectString) {
      const redirectParsed = parseUrl(redirectString)
      const redirectQuery = parseQuery(_get(redirectParsed, 'search'))
      destinationUrl = formatUrl({
        protocol: _get(redirectParsed, 'protocol'),
        hostname: _get(redirectParsed, 'host'),
        pathname: _get(redirectParsed, 'pathname'),
        query: {
          ...redirectQuery,
          redirectedFrom: true,
        },
      })
    }
    setTimeout(() => global.location.assign(destinationUrl), 100)
  }
}

export function changeAuthProfile ({
  auth, uid, userAccountId, shouldRedirect = true,
}) {
  return async function changeAuthProfileThunk (dispatch) {
    try {
      dispatch({ type: AUTH_CHANGE_PROFILE })
      const data = await loginProfile({ auth, uid, userAccountId })
      const { statusCode } = data
      const authCodes = _get(data, 'auth.codes') || []
      const { codes = Map() } = data

      if (_get(data, 'auth.success')) {
        dispatch(setAuthPersistentData(data.auth))
        dispatch(setAuthLoginSuccess(true))
        // set userProfilesSwitch so that the interstitial will continue to show
        // until the page reloads when the profile is switched
        dispatch(userProfilesSwitch(true))

        if (shouldRedirect) {
          let url = URL_HOME
          const onboardingStatus = await apiGetOnboardingStatus({ auth: fromJS(_get(data, 'auth')) })
          const { onboardComplete } = onboardingStatus

          if (!onboardComplete) {
            url = URL_GET_STARTED
          }

          // go to the homepage or onboarding when profile is switched
          navigateOrRedirect(url)
        } else dispatch(setAppRenderable(true))
      } else {
        dispatch(setAuthLoginFailed(codes, statusCode))
        onAuthError(dispatch)

        // If the user is blocked logged them out
        // or if their login session has expired, log out
        const doLogout = _some(LOGIN_CODES_DO_LOGOUT, (v) => _includes(authCodes, v))

        if (doLogout) {
          dispatch(doAuthLogout(auth))
        }
      }
      return _get(data, 'auth')
    } catch (e) {
      const statusCode = e.status || 500
      const codes = ['serviceUnavailable']
      dispatch(setAuthLoginFailed(codes, statusCode))
      onAuthError(dispatch)
      throw e
    }
  }
}

export function doAuthLogout (auth) {
  return async function doAuthLogoutThunk (dispatch) {
    try {
      dispatch({ type: AUTH_LOGOUT })
      await logout({ auth })
    } catch (e) {
      // Not important to tell users why it failed so do nothing
    }
    setTokenDataInStorage(null)
    dispatch(resetAuthData())

    // Redirect to homepage which will also cause app to restart
    // or if a redirect is in the query, direct the user to that location
    navigateOrRedirect()

    return null
  }
}
