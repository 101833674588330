import ls from 'local-storage'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _parseInt from 'lodash/parseInt'
import _merge from 'lodash/merge'
import _size from 'lodash/size'
import _omit from 'lodash/omit'
import _reduce from 'lodash/reduce'
import _includes from 'lodash/includes'
import { log as logError } from 'log/error'
import { get as getConfig } from 'config'
import { Map, fromJS, List } from 'immutable'
import {
  complianceCategoryInActiveGroups,
  getFormattedActiveGroups,
  COMPLIANCE_MANAGEMENT_CAGETORY_FUNCTIONAL,
  COMPLIANCE_MANAGEMENT_ALWAYS_ALLOWED_LOCAL_PREFS,
  COMPLIANCE_MANAGEMENT_ALLOWED_UTM_LOCAL_PREFS,
} from 'services/compliance-management'

const config = getConfig()
// this is for the old cookie banner (Gaia's home-built one)
const dataPrivacyCompliance = _get(config, ['features', 'anonymousUser', 'dataPrivacyCompliance'])
// this is for the OneTrust cookie banner
const oneTrustEnabled = !!_get(config, ['features', 'oneTrust'])
// this limits the local tracking in conjunction with OneTrust
const limitLocalTracking = !!_get(config, ['features', 'limitLocalTracking'])

export const LOCAL_PREFERENCE_COOKIE_OPTIN_ACCEPTED = 'cookieOptInAccepted'
export const LOCAL_PREFERENCE_COOKIE_OPTIN_ACCEPTED_DATE = 'cookieOptInTimestamp'
export const LOCAL_PREFERENCES_EMAIL_SIGNUP_ADDRESS = 'emailSignupAddress'
export const LOCAL_PREFERENCE_VIDEO_PLAYBACK_RATE = 'playbackRate'
export const LOCAL_PREFERENCE_CONSENT_MANAGEMENT_ACTIVE_GROUPS = 'consentManagementActiveGroups'
export const LOCAL_PREFERENCE_INBOUND_TRACKING = 'inboundTracking'
export const LOCAL_PREFERENCE_SHARE_EMAIL = 'shareEmail'

// this is for the old cookie banner
export function canSetCookie (uid, authToken) {
  if (!getLocalPreferences(uid, LOCAL_PREFERENCE_COOKIE_OPTIN_ACCEPTED) && !authToken) {
    return false
  }
  return true
}

// this is for the new OneTrust cookie banner
export function getConsentManagementPersistentPreferences (options) {
  const { uid, auth } = options

  return getLocalPreferences(uid, LOCAL_PREFERENCE_CONSENT_MANAGEMENT_ACTIVE_GROUPS, auth)
}

export function setLocalStorage (key, value, uid, auth = Map(), force = false) {
  const authToken = auth.get('jwt')
  let valueObj = value
  let anonymousValues = _get(value, -1, {})

  // we do not want LOCAL_PREFERENCES_EMAIL_SIGNUP_ADDRESS to end up getting set in local storage,
  // only session storage
  if (
    _size(anonymousValues) > 0
    && _get(anonymousValues, [LOCAL_PREFERENCES_EMAIL_SIGNUP_ADDRESS])
  ) {
    anonymousValues = _omit(anonymousValues, LOCAL_PREFERENCES_EMAIL_SIGNUP_ADDRESS)
  }

  valueObj = { ...value, [-1]: anonymousValues }

  if (oneTrustEnabled) {
    return ls.set(key, valueObj)
  }

  // force should only be set for the cookie acceptance banner
  // and should not ever be used for anything else
  // if the user is anonymous and has opted in to cookies,
  // save data to local storage
  if (force || (canSetCookie(uid, authToken) && dataPrivacyCompliance)) {
    return ls.set(key, valueObj)
  }

  if (!dataPrivacyCompliance) {
    // support original behavior if feature toggle is off
    return ls.set(key, valueObj)
  }
  return // eslint-disable-line
}

export function getLocalStorage (key) {
  return ls.get(key)
}

export function removeLocalStorage (key) {
  if (key) {
    ls.remove(key)
    return
  }
  ls.clear()
}

export function getSessionStorage (key, defaultValue = null) {
  if (typeof sessionStorage !== 'undefined') {
    const value = sessionStorage.getItem(key) || defaultValue

    // if the value IS the default, exit
    if (value === defaultValue) {
      return value
    }
    // all of these can be JSON parsed (boolean, int, float, null)
    // AND quoted value, array, or object
    if (/^(true|false|null|\d+\.?\d+)$/i.test(value)
      || /^("|\[|\{)/.test(value)) {
      try {
        const result = JSON.parse(value)
        return result
      } catch (e) {
        // do nothing
      }
    }
    return value
  }
  return defaultValue
}

export function setSessionStorage (key, value) {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.setItem(key, JSON.stringify(value))
  }
  return null
}

export function clearSessionStorage (key) {
  if (typeof sessionStorage !== 'undefined') {
    if (key) {
      sessionStorage.removeItem(key)
      return
    }
    sessionStorage.clear()
  }
}

function getData () {
  const data = getLocalStorage('gaia') || {}
  const anonLanguageAlertBarAccepted = _get(data, [-1, 'user', 'data', 'languageAlertBarAccepted'])
  const session = getSessionStorage('gaia', {})
  // set anonymous languageAlertBarAccepted for session because local storage should always win
  // without setting this here, when local storage and session are merged below
  // session will overwrite what is in local storage
  _set(session, [-1, 'user', 'data', 'languageAlertBarAccepted'], anonLanguageAlertBarAccepted)

  try {
    // Fix double encoded JSON data.
    if (
      _isString(data)
      && data.indexOf('{') === 0
      && data.lastIndexOf('}') === data.length - 1
    ) {
      const obj = JSON.parse(data)

      return _merge(obj, session)
    }
  } catch (e) {
    // Do nothing we will return null below
    logError(e)
    return null
  }
  return _merge(data, session)
}

export function getLocalPreferences (uid, key) {
  if (!_isNumber(uid)) {
    // eslint-disable-next-line no-param-reassign
    uid = -1
  }
  let localData = {}
  try {
    localData = getData()
    if (!localData) {
      return null
    }
    return _get(localData, `${uid}.${key}`, null)
  } catch (e) {
    // Do nothing we will return null below
    logError(e)
  }
  return null
}

export function setLocalPreferences (uid, key, value, auth, force) {
  if (!_isNumber(uid)) {
    // eslint-disable-next-line no-param-reassign
    uid = -1
  }
  let userPrefs = {}
  let localData = {}
  let updatedValue = value
  const userAuth = auth || Map()

  try {
    localData = getData() || {}
    userPrefs = _get(localData, uid, {})

    if (oneTrustEnabled && limitLocalTracking && !userAuth.get('jwt')) {
      const anonymousConsentActiveGroups = getFormattedActiveGroups() || []
      const inFunctionalCategory = complianceCategoryInActiveGroups(
        fromJS(anonymousConsentActiveGroups),
        COMPLIANCE_MANAGEMENT_CAGETORY_FUNCTIONAL,
      )

      // remove certain inboundTracking values from the local data if not in the proper category
      if (!inFunctionalCategory) {
        const inboundTracking = _get(userPrefs, LOCAL_PREFERENCE_INBOUND_TRACKING)

        if (_size(inboundTracking)) {
          const filteredInboundTracking = _reduce(inboundTracking, (acc, itemValue, itemKey) => {
            if (!_includes(COMPLIANCE_MANAGEMENT_ALWAYS_ALLOWED_LOCAL_PREFS, itemKey)) {
              return acc
            }
            return _set(acc, itemKey, itemValue)
          }, {})

          // Find any allowed utm key/values
          const utmValues = _reduce(inboundTracking, (acc, itemValue, itemKey) => {
            if (!_includes(COMPLIANCE_MANAGEMENT_ALLOWED_UTM_LOCAL_PREFS, itemKey)) {
              return acc
            }
            return _set(acc, itemKey, itemValue)
          }, {})

          // Create the utm string from the allowed values
          const utmString = _reduce(utmValues, (acc, v, k) => {
            acc.push(`${k}=${encodeURIComponent(v)}`)
            return acc
          }, [])
            .join('&')
          const utm = {
            utm: utmString,
          }

          // set the utm strings
          if (_size(filteredInboundTracking) && _size(utmString)) {
            _set(filteredInboundTracking, 'strings', utm)
          }

          userPrefs = _set(userPrefs, LOCAL_PREFERENCE_INBOUND_TRACKING, filteredInboundTracking)
        }
      }

      // if not in the proper category, filter the incoming inboundTracking values
      if (!inFunctionalCategory && key === LOCAL_PREFERENCE_INBOUND_TRACKING) {
        if (Map.isMap(updatedValue) && updatedValue.size) {
          const filteredInboundTracking = updatedValue.filter((v, k) => {
            return List(COMPLIANCE_MANAGEMENT_ALWAYS_ALLOWED_LOCAL_PREFS).includes(k)
          })

          // Find any allowed utm key/values
          const utmValues = updatedValue.filter((v, k) => {
            return List(COMPLIANCE_MANAGEMENT_ALLOWED_UTM_LOCAL_PREFS).includes(k)
          })

          // Create the utm string from the allowed values
          const utmString = utmValues.reduce((acc, v, k) => {
            return acc.push(`${k}=${encodeURIComponent(v)}`)
          }, List())
            .join('&')
          const utm = Map({
            utm: utmString,
          })

          // set the utm strings
          updatedValue = filteredInboundTracking.withMutations((mutatableInboundTracking) => {
            mutatableInboundTracking.set('strings', utm)
          })
        }
      }
    }
  } catch (e) {
    // Do nothing
    logError(e)
  }

  userPrefs[key] = updatedValue
  localData[_parseInt(uid)] = userPrefs

  // 'force' should only be used when setting the cookie banner acceptance
  // no local storage or cookies should be set unless acceptance has been set
  setSessionStorage('gaia', localData)

  return setLocalStorage('gaia', localData, uid, auth, force)
}
