import _get from 'lodash/get'
import _set from 'lodash/set'
import { post as apiPost, TYPE_BROOKLYN_JSON } from 'api-client'
import { fromJS, Map, List } from 'immutable'
import { TYPE_CONTENT_SERIES, TYPE_CONTENT_VIDEO } from 'services/content-type'
import {
  PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_MONTHLY,
  PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL,
  PLAN_SKU_ANNUAL,
  PLAN_SKU_LIVE,
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_INCREASED_PRICE_MONTHLY,
  PLAN_SKU_INCREASED_PRICE_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_GAIA_PLUS,
} from 'services/plans'

export const FACEBOOK_PIXEL_TEST_CODE_KEY = 'FACEBOOK_PIXEL_TEST_CODE'
export const FACEBOOK_EVENT_NAME_PURCHASE = 'Purchase'
export const FACEBOOK_EVENT_NAME_PAGE_VIEW = 'PageView'
export const FACEBOOK_EVENT_NAME_LEAD = 'Lead'
export const FACEBOOK_EVENT_NAME_INITIATE_CHECKOUT = 'InitiateCheckout'
export const FACEBOOK_EVENT_NAME_VIEW_CONTENT = 'ViewContent'
export const FACEBOOK_EVENT_ACTION_SOURCE = 'website'
export const YOGA_HOME_ARTICLE_CLICKED = 'Yoga home article clicked'
export const YOGA_HOME_TEACHER_CLICKED = 'Yoga home teacher clicked'
export const YOGA_HOME_PILL_CLICKED = 'Yoga home pill clicked'
export const ROW_SCROLL_ARROW_CLICKED = 'Row scroll arrow clicked'
export const YOGA_HOME_LINK_CLICKED = 'Yoga home link clicked'
export const SET_EVENT_LEFT_ARROW_CLICKED = 'Left arrow clicked'
export const SET_EVENT_RIGHT_ARROW_CLICKED = 'Right arrow clicked'
export const AUTOCOMPLETE_SUGGESTION_CLICKED = 'Autocomplete suggestion clicked'
export const SHARE_MODAL_OPENED = 'Share modal opened'
export const GLOBAL_LOGIN_MODAL_EVENT_CATEGORY = 'Global Login Modal'
export const PURCHASED_MONTHLY_WITH_TRIAL = 'Purchased Monthly With Trial'
export const PURCHASED_MONTHLY_DIRECT = 'Purchased Monthly Direct'
export const PURCHASED_ANNUAL_WITH_TRIAL = 'Purchased Annual With Trial'
export const PURCHASED_ANNUAL_DIRECT = 'Purchased Annual Direct'
export const PURCHASED_EVENTS_PLUS = 'Purchased Events Plus'
export const PURCHASED_PROMOTIONAL_PLAN = 'Purchased Promotional Plan'
export const EVENT_VIDEO_PLAYBACK = 'Video Played'
export const EVENT_USER_ENGAGEMENT_ADD_COMMENT = 'User Engagement Add Comment'
export const EVENT_USER_ENGAGEMENT_SHARE_VIDEO = 'user_engagement_share_video'
export const EVENT_USER_ENGAGEMENT_SHARE_PLAYLIST = 'playlist_shared'
export const EVENT_NAVIGATION_USER_CLICK = 'navigation_user_click'
export const EVENT_USER_NEW_TO_GAIA_CLICK = 'user_login_new_to_gaia_click'
export const EVENT_CATEGORY_PROFILE_SELECTOR = 'ProfileSelector'
export const EVENT_VALUE_FOCUSED = 'focused'
export const EVENT_VALUE_INVALID = 'invalid'
export const EVENT_VALUE_IN_USE = 'in_use'
export const EVENT_VALUE_RESET = 'reset'
export const EVENT_VALUE_SHOW = 'show'
export const EVENT_VALUE_HIDE = 'hide'
export const EVENT_VALUE_CLICKED = 'clicked'
export const EVENT_VALUE_CHECKED = 'checked'
export const EVENT_VALUE_UNCHECKED = 'unchecked'
export const EVENT_VALUE_DISABLED = 'disabled'
export const EVENT_VALUE_ENABLED = 'enabled'
export const EVENT_VALUE_SELECTED = 'selected'
export const EVENT_VALUE_CLOSED = 'closed'
export const EVENT_VALUE_FAILED = 'failed'
export const VIEW_WATCH_HISTORY_CLICKED = 'view all watch history clicked'
export const IN_PROGRESS_CLICKED = 'in progress clicked'
export const FINISHED_CLICKED = 'finished clicked'
export const NEW_VIDEOS_CLICKED = 'view all new videos clicked'
export const VIEW_ALL_PLAYLIST_CLICKED = 'view all playlist clicked'
export const VIDEO_VIEW = 'Video View'
export const QUALIFIED = 'Qualified'
export const LOAD_MORE = 'load more clicked'
export const FILTERTAB_CLICKED = 'FilterTab Clicked'
export const IN_PROGRESS_TAB_CLICKED = 'in progress tab clicked'
export const FINISHED_TAB_CLICKED = 'finished tab clicked'
export const HOVER_ROW_SCROLLED = 'hover row scrolled'
export const TOPIC_TAB_CLICKED = 'topic tab clicked'
export const TOPIC_SECTION_SCROLLED = 'topic section scrolled'
export const VIEW_ALL_TOPICS_CLICKED = 'view all topics clicked'
export const VIEW_ALL_SERIES_CLICKED = 'view all series clicked'
export const VIEW_ALL_RECENTLY_ADDED_CLICKED = 'view all recently added clicked'
export const SERIES_CLICK = 'Series Click'
export const TITLE_CLICK = 'Title Click'
export const PLAYLIST_ADD = 'Playlist Add'
export const PLAY_EVENT = 'Play Event'
export const EVENT_ACTION_SPOTLIGHT_EXPANDED = 'expanded'
export const EVENT_ACTION_SPOTLIGHT_MINIMIZED = 'minimized'
export const EVENT_ACTION_SELECT = 'select'
export const EVENT_ACTION_DESELECT = 'deselect'
export const EVENT_ACTION_FAVORITE = 'favorite'
export const EVENT_ACTION_UNFAVORITE = 'unfavorite'
export const EVENT_ACTION_WATCHED = 'watched'
export const EVENT_ACTION_PLAY_CLICK = 'Play Click'
export const EVENT_ACTION_TILE_CLICK = 'Click Tile'
export const EVENT_ACTION_PRODUCT_TILE_CLICK = 'product_tile_clicked'
export const EVENT_ACTION_PRIMARY_PLAY_CLICKED = 'primary_play_clicked'
export const EVENT_ACTION_VIDEO_PRIMARY_PLAY_CLICKED = 'Watch Now Clicked'
export const EVENT_ACTION_SERIES_PRIMARY_PLAY_CLICKED = 'Featured Episode Play Button Clicked'
export const EVENT_ACTION_REMOVED = 'Removed'
export const EVENT_ACTION_REVERTED = 'Reverted'
export const EVENT_ACTION_CLICK_DETAIL_PAGE = 'Click to Detail Page'
// following new GA4 pattern (lowercase separated by underscore) suggested by Infotrust
export const EVENT_ACTION_AUDIO_CHANGED = 'audio_changed'
export const EVENT_LABEL_LETS_GO = "Let's Go"
export const EVENT_LABEL_SKIP = 'skip & start watching'
export const EVENT_LABEL_INTERESTS = 'Interests'
export const EVENT_LABEL_COMPLETE = 'complete'
export const EVENT_LABEL_ENGAGED = 'engaged'
export const EVENT_LABEL_REDDIT = 'reddit'
export const EVENT_LABEL_FACEBOOK = 'facebook'
export const EVENT_LABEL_TWITTER = 'twitter'
export const EVENT_LABEL_EMAIL = 'email'
export const EVENT_LABEL_EMBED = 'embed'
export const EVENT_LABEL_GENERATE_LINK = 'generate link'
export const NOTIFICATIONS = 'Notifications'
export const NOTIFICATIONS_BELL_EVENT = 'notifications bell clicked'
export const NOTIFICATIONS_ITEM_EVENT = 'notification clicked'
export const NOTIFICATIONS_VIEW_ALL_EVENT = 'notifications view all clicked'
export const NOTIFICATIONS_WATCH_NOW_EVENT = 'notifications watch now clicked'
export const NOTIFICATIONS_MANAGE_EVENT = 'notifications manage clicked'
export const NOTIFICATIONS_FOLLOW_CLICKED = 'notifications follow clicked'
export const NOTIFICATIONS_UNFOLLOW_CLICKED = 'notifications unfollow clicked'
export const NOTIFICATIONS_IMPRESSED = 'notifications impressed'
export const NOTIFICATIONS_RECOMMENDED = 'notification of recommendation'
export const NOTIFICATIONS_NEW_CONTENT = 'notification of episode'
export const CLICK_ACTION = 'Click'
export const CUSTOM_EVENT = 'customEvent'
export const MERCH_EVENT = 'merchandiseEvent'
export const NAVIGATION_GEAR_EVENT = 'navigation gear'
export const MERCH_CAMPAIGN = 'Merchandising Campaign'
export const IMPRESSION_ACTION = 'Impression'
const CLOSE_ACTION = 'Close'
export const HIDE_ACTION = 'Hide'
export const UNHIDE_ACTION = 'Unhide'
const CATEGORY_CUSTOM_ROW = 'Custom Row'
const CATEGORY_HIDDEN_CONTENT = 'Content Hidden'
const CATEGORY_SPOTLIGHT_TOGGLE = 'Spotlight Toggle'
const CATEGORY_TOOLTIP_IMPRESSION = 'Experiment - Tooltip'
const CATEGORY_TOOLTIP_CLOSE = 'Tooltip'
const CATEGORY_ENDSTATE_AUTOPLAY = 'Endstate Autoplay'
const CATEGORY_VIDEOINFO = 'Video Player Info'
const CATEGORY_NOTIFICATIONS_SETTINGS = 'settings'
const TOGGLED = 'Toggled'
const CATEGORY_CUSTOM_SPOTLIGHT = 'Custom Spotlight'
const CATEGORY_ONBOARDING_14 = 'Onboarding 1.4'
const CATEGORY_SETTINGS_AND_PREFERENCES = 'Settings & preferences'
const CATEGORY_VIDEO_PLAYBACK = 'video playback'
const CATEGORY_PROMOTION = 'Promotion'
const CATEGORY_TILE_CLICK = 'Tile Click'
export const CATEGORY_NAVIGATION_V1 = 'navigation v1'
export const CATEGORY_NAVIGATION_V2 = 'navigation v2'
export const CATEGORY_NAVIGATION_V3 = 'navigation v3'
const REMOVED_ROWS_SETTINGS_EVENT_CATEGORY = 'Custom Row'
export const REMOVED_ROWS_SETTINGS_ACTION_REMOVED = 'removed'
export const REMOVED_ROWS_SETTINGS_ACTION_REVERTED = 'reverted'
const EMAIL_OPT_IN_SETTINGS_EVENT_CATEGORY = 'settings'
export const EMAIL_OPT_IN_SETTINGS_ON = 'on'
export const EMAIL_OPT_IN_SETTINGS_OFF = 'off'
export const EMAIL_OPT_IN_SETTINGS_ACTION_TOGGLED = 'email opt-in'
const SITE_LANGUAGE_SETTINGS_EVENT_CATEGORY = 'settings'
export const SITE_LANGUAGE_SETTINGS_ACTION_SAVE = 'site language'
export const SITE_LANGUAGE_SETTINGS_ACTION_CANCEL = 'cancel'
const CONTENT_LANGUAGE_SETTINGS_EVENT_CATEGORY = 'settings'
export const CONTENT_LANGUAGE_SETTINGS_ACTION_SAVE = 'content language'
export const CONTENT_LANGUAGE_SETTINGS_ACTION_CANCEL = 'cancel'
// Server events
export const SERVER_EVENT_PURCHASE = 'Purchase'
export const SERVER_EVENT_PAGE_VIEW = 'PageView'
export const SERVER_EVENT_LEAD = 'Lead'
export const SERVER_EVENT_INITIATE_CHECKOUT = 'InitiateCheckout'
export const SERVER_EVENT_VIEW_CONTENT = 'ViewContent'
// Detail Page V2
export const EVENT_EPISODE_LIST_PLAY_CLICK = 'episode_list_play_click'
export const EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICK = 'episode_list_add_to_playlist_click'
export const EVENT_EPISODE_LIST_TITLE_CLICK = 'episode_list_title_click'

// GA4 events
export const EVENT_PLAY_CLICK = 'play_click'
export const EVENT_PLAY_LIST_CREATED = 'playlist_created'
export const EVENT_PLAY_LIST_DELETED = 'playlist_deleted'
export const EVENT_PLAY_LIST_SHARED = 'playlist_shared'
export const EVENT_REMOVED_FROM_PLAY_LIST = 'removed_from_playlist'
export const EVENT_ADDED_TO_PLAY_LIST = 'added_to_playlist'
export const EVENT_ACCOUNT_PAUSED = 'account_paused'
export const EVENT_ACCOUNT_UNPAUSED = 'account_unpaused'
export const EVENT_ROW_IMPRESSION = 'row_impression'
export const EVENT_TOPIC_CLICKED = 'topic_clicked'
export const EVENT_PRIMARY_LANGUAGE_CHANGED = 'language_changed'
export const EVENT_SECONDARY_LANGUAGE_CHANGED = 'secondary_language_changed'
export const EVENT_EMAIL_CAPTURED = 'email_captured'
export const EVENT_EMAIL_PERMISSION_TOGGLED = 'email_permission_toggled'
export const EVENT_AUTOPLAY_TOGGLED = 'autoplay_toggled'
export const EVENT_COMMENT = 'comment'
export const EVENT_ITEM_REMOVED_FROM_HISTORY = 'item_removed_from_history'
export const EVENT_SERIES_FOLLOWED = 'series_followed'
export const EVENT_SERIES_UNFOLLOWED = 'series_unfollowed'
export const EVENT_NOTIFICATIONS_OPENED = 'notifications_opened'
export const EVENT_NAME_TAG_CLICKED = 'tag_clicked'
export const EVENT_NOTIFICATIONS_MANAGE_CLICKED = 'notifications_manage_clicked'
export const EVENT_NOTIFICATIONS_VIEW_ALL_CLICKED = 'notifications_view_all_clicked'
export const EVENT_CANCEL_FLOW_REASON_SELECTED = 'cancel_flow_reason_selected'
export const EVENT_CANCEL_FLOW_FREE_MONTH_SELECTED = 'cancel_flow_free_month_selected'
export const EVENT_CANCEL_FLOW_CHANGE_PLAN_SELECTED = 'cancel_flow_change_plan_selected'
export const EVENT_CANCEL_FLOW_FREE_MONTH_REJECTED = 'cancel_flow_free_month_rejected'
export const EVENT_SPOTLIGHT_TOGGLED = 'spotlight_toggled'
export const EVENT_NAV_CLICKED = 'nav_clicked'
export const EVENT_PROMO_UNIT_IMPRESSION = 'promo_unit_impression'
export const EVENT_PROMO_UNIT_CLICK = 'promo_unit_click'
export const EVENT_CLICK_TO_DETAILS_PAGE = 'click_to_detail_page'
export const EVENT_VIDEO_PLAYER_OPENED = 'video_player_opened'
export const EVENT_VIDEO_LOADS_AND_STARTS_PLAY = 'video_loads_and_starts_to_play'
export const EVENT_SHARE_INITIATED = 'share_initiated'
export const EVENT_SHARE_COMPLETED = 'share_completed'
export const EVENT_VIDEO_QUALIFIED_VIEW = 'video_qualified_view'
export const EVENT_VIDEO_ENGAGED_VIEW = 'video_engaged_view'
export const EVENT_VIDEO_COMPLETED = 'video_completed'
export const EVENT_VIDEO_SPEED_CHANGED = 'video_speed_changed'
export const EVENT_VIDEO_AUDIO_CHANGED = 'video_audio_changed'
export const EVENT_VIDEO_SUBTITLES_SET = 'video_subtitles_set'
export const EVENT_PREVIEW_PLAYED = 'preview_played'
export const EVENT_PLAYER_INFO_TOGGLED = 'player_info_toggled'
export const EVENT_SPOTLIGHT_IMPRESSION = 'spotlight_impression'
export const EVENT_SPOTLIGHT_PLAY_TOGGLE = 'spotlight_play_toggle'
export const EVENT_SPOTLIGHT_AUDIO_TOGGLE = 'spotlight_audio_toggle'
export const EVENT_CREATE_ACCOUNT_PRECURSOR = 'create_account_precursor'
export const EVENT_SIGN_UP = 'sign_up'
export const EVENT_PLAN_SELECT_PRECURSOR = 'plan_select_precursor'
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout'
export const EVENT_PAYMENT_INFO_PAGE = 'payment_info_page'
export const EVENT_PURCHASE = 'purchase'
export const EVENT_PURCHASE_PLAN_UPGRADE = 'purchase_plan_upgrade'
export const EVENT_PURCHASED_MONTHLY_WITH_TRIAL = 'purchased_monthly_with_trial'
export const EVENT_PURCHASED_MONTHLY_DIRECT = 'purchased_monthly_direct'
export const EVENT_PURCHASED_ANNUAL_WITH_TRIAL = 'purchased_annual_with_trial'
export const EVENT_PURCHASED_ANNUAL_DIRECT = 'purchased_annual_direct'
export const EVENT_PURCHASED_EVENTS_PLUS = 'purchased_events_plus'
export const EVENT_PURCHASED_PROMOTIONAL_PLAN = 'purchased_promotional_plan'
export const EVENT_CHECKOUT_UPDATE_EMAIL = 'checkout_update_email'
export const EVENT_FILTER_APPLIED = 'filter_applied'
export const EVENT_CONTENT_RATING = 'content_rating'
export const EVENT_CHROMECAST_CONNECTED = 'chromecast_connected'
export const EVENT_AIRPLAY_CONNECTED = 'airplay_connected'
export const EVENT_CUSTOM_EVENT = 'custom_event'
export const PAYPAL_POPUP_OPENED_EVENT = 'paypal_popup_opened'
export const EVENT_START_FREE_TRIAL_CLICKED = 'start_free_trial_clicked'
export const TAG_CLICKED_EVENT = 'tag_clicked'
export const ARTICLE_CLICKED_EVENT = 'article_clicked'
export const USER_LOGIN_NEW_TO_GAIA_CLICK_EVENT = 'user_login_new_to_gaia_click'
export const NOTIFICATIONS_RECOMMENDED_PERMISSION_TOGGLED_EVENT = 'notifications_recommended_permission_toggled'
export const NOTIFICATIONS_NEW_CONTENT_PERMISSION_TOGGLED_EVENT = 'notifications_new_content_permission_toggled'
export const TESTAROSSA_SPOTLIGHT_CLICKED_EVENT = 'testarossa_spotlight_clicked'
export const PAGEVIEW_EVENT = 'Pageview'
export const EVENT_SHARE_OFFER_CLICKED = 'share_offer_clicked'
export const EVENT_PLAYLIST_TILE_CTA_CLICKED = 'playlist_tile_cta_clicked'

export const BELL_EVENT_DATA = Map({
  event: CUSTOM_EVENT,
  eventName: NOTIFICATIONS_BELL_EVENT,
  eventCategory: NOTIFICATIONS,
  eventAction: CLICK_ACTION,
})
export const VIEW_ALL_EVENT_DATA = Map({
  event: CUSTOM_EVENT,
  eventName: NOTIFICATIONS_VIEW_ALL_EVENT,
  eventCategory: NOTIFICATIONS,
  eventAction: CLICK_ACTION,
})
export const WATCH_NOW_EVENT_DATA = Map({
  event: CUSTOM_EVENT,
  eventName: NOTIFICATIONS_WATCH_NOW_EVENT,
  eventCategory: NOTIFICATIONS,
  eventAction: CLICK_ACTION,
})
export const MANAGE_EVENT_DATA = Map({
  event: CUSTOM_EVENT,
  eventName: NOTIFICATIONS_MANAGE_EVENT,
  eventCategory: NOTIFICATIONS,
  eventAction: CLICK_ACTION,
})
export const ITEM_DATA = Map({
  event: CUSTOM_EVENT,
  eventName: NOTIFICATIONS_ITEM_EVENT,
  eventCategory: NOTIFICATIONS,
  eventAction: CLICK_ACTION,
})
export const MERCHANDISING_IMPRESSION_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: MERCH_CAMPAIGN,
  eventAction: IMPRESSION_ACTION,
  nonInteraction: 'True',
})
export const PROMOTION_IMPRESSION_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_PROMOTION,
  eventAction: IMPRESSION_ACTION,
  nonInteraction: 'True',
})
export const NAVIGATION_GEAR_CLICK_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: NAVIGATION_GEAR_EVENT,
  eventAction: CLICK_ACTION,
  eventLabel: 'gear icon',
})
export const MERCHANDISING_CLICK_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: MERCH_CAMPAIGN,
  nonInteraction: 'false',
})
export const SPOTLIGHT_TOGGLE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_SPOTLIGHT_TOGGLE,
  eventAction: TOGGLED,
})
export const NOTIFICATIONS_RECOMMENDED_TOGGLE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_NOTIFICATIONS_SETTINGS,
  eventAction: NOTIFICATIONS_RECOMMENDED,
})
export const NOTIFICATIONS_NEW_CONTENT_TOGGLE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_NOTIFICATIONS_SETTINGS,
  eventAction: NOTIFICATIONS_NEW_CONTENT,
})
export const HIDDEN_CONTENT_HIDE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_HIDDEN_CONTENT,
  eventAction: HIDE_ACTION,
})
export const HIDDEN_CONTENT_UNHIDE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_HIDDEN_CONTENT,
  eventAction: UNHIDE_ACTION,
})
export const CUSTOM_ROW_CLICK_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_CUSTOM_ROW,
  nonInteraction: 'False',
  impressionClicks: 1,
})
export const CUSTOM_ROW_IMPRESSION_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_CUSTOM_ROW,
  eventAction: IMPRESSION_ACTION,
  nonInteraction: 'True',
})
export const CUSTOM_ROW_REMOVED_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_CUSTOM_ROW,
  eventAction: EVENT_ACTION_REMOVED,
})
export const CUSTOM_ROW_REVERTED_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_CUSTOM_ROW,
  eventAction: EVENT_ACTION_REVERTED,
})
export const TOOLTIP_IMPRESSION_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_TOOLTIP_IMPRESSION,
  eventAction: IMPRESSION_ACTION,
  nonInteraction: 'True',
})
export const TOOLTIP_CLOSE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_TOOLTIP_CLOSE,
  eventAction: CLOSE_ACTION,
  nonInteraction: 'false',
})
export const HIDE_WATCHED_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: 'Watch History',
  eventAction: EVENT_ACTION_REMOVED,
  nonInteraction: 'false',
})
export const ENDSTATE_AUTOPLAY_TOGGLED = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_ENDSTATE_AUTOPLAY,
  eventAction: TOGGLED,
  nonInteraction: 'false',
})
export const VIDEOINFO_TOGGLED = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_VIDEOINFO,
  eventAction: TOGGLED,
  nonInteraction: 'false',
})
export const SPOTLIGHT_AD_IMPRESSION_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_CUSTOM_SPOTLIGHT,
  eventAction: IMPRESSION_ACTION,
  eventLabel: 'Testarossa Variation Name',
  nonInteraction: 'True',
})
export const SPOTLIGHT_AD_CLICK_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_CUSTOM_SPOTLIGHT,
  eventAction: CLICK_ACTION,
  eventLabel: 'Testarossa Variation Name',
  nonInteraction: 'false',
})
export const ONBOARDING_CLICK_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_ONBOARDING_14,
  eventAction: CLICK_ACTION,
})
export const ONBOARDING_SELECT_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_ONBOARDING_14,
  eventAction: EVENT_ACTION_SELECT,
})
export const ONBOARDING_FAVORITE_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_ONBOARDING_14,
  eventAction: EVENT_ACTION_FAVORITE,
})
export const SETTINGS_AND_PREFERENCES_EVENT = fromJS({
  event: MERCH_EVENT,
  eventCategory: CATEGORY_SETTINGS_AND_PREFERENCES,
  eventAction: CLICK_ACTION,
})
export const PRODUCT_TILE_CLICKED_EVENT = fromJS({
  event: EVENT_ACTION_PRODUCT_TILE_CLICK,
  eventName: EVENT_ACTION_PRODUCT_TILE_CLICK,
})
export const VIDEO_PRIMARY_PLAY_CLICKED_EVENT = fromJS({
  event: EVENT_ACTION_PRIMARY_PLAY_CLICKED,
  eventAction: EVENT_ACTION_VIDEO_PRIMARY_PLAY_CLICKED,
  button_location: EVENT_ACTION_VIDEO_PRIMARY_PLAY_CLICKED,
})
export const SERIES_PRIMARY_PLAY_CLICKED_EVENT = fromJS({
  event: EVENT_ACTION_PRIMARY_PLAY_CLICKED,
  eventAction: EVENT_ACTION_SERIES_PRIMARY_PLAY_CLICKED,
  button_location: EVENT_ACTION_SERIES_PRIMARY_PLAY_CLICKED,
})
export const EVENT_TAG_CLICKED = fromJS({
  event: EVENT_NAME_TAG_CLICKED,
  eventName: EVENT_NAME_TAG_CLICKED,
})
export const EVENT_EPISODE_LIST_PLAY_CLICKED = fromJS({
  event: EVENT_EPISODE_LIST_PLAY_CLICK,
  eventName: EVENT_EPISODE_LIST_PLAY_CLICK,
})
export const EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED = fromJS({
  event: EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICK,
  eventName: EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICK,
})
export const EVENT_EPISODE_LIST_TITLE_CLICKED = fromJS({
  event: EVENT_EPISODE_LIST_TITLE_CLICK,
  eventName: EVENT_EPISODE_LIST_TITLE_CLICK,
})
export const EVENT_DEFAULT_VALUES = fromJS({
  properties: {},
})
export const NAVIGATION_EVENT_LABELS = {
  LOGO: 'logo',
  IAF: 'invite a friend',
  CAREER: 'work at gaia',
  LANGUAGE_GLOBE: 'language globe',
  NOTIFICATIONS: 'notifications',
  SETTINGS: 'settings',
  USER_MENU: 'user menu',
}

// GA4 events
export const PLAY_CLICK_GA4_EVENT = fromJS({
  event: EVENT_PLAY_CLICK,
})
export const PLAY_LIST_CREATED_GA4_EVENT = fromJS({
  event: EVENT_PLAY_LIST_CREATED,
})
export const PLAY_LIST_DELETED_GA4_EVENT = fromJS({
  event: EVENT_PLAY_LIST_DELETED,
})
export const PLAY_LIST_SHARED_GA4_EVENT = fromJS({
  event: EVENT_PLAY_LIST_SHARED,
})
export const REMOVED_FROM_PLAY_LIST_GA4_EVENT = fromJS({
  event: EVENT_REMOVED_FROM_PLAY_LIST,
  ui_location: 'Manage Playlist Modal',
})
export const ADDED_TO_PLAY_LIST_GA4_EVENT = fromJS({
  event: EVENT_ADDED_TO_PLAY_LIST,
  ui_location: 'Manage Playlist Modal',
})
export const ACCOUNT_PAUSED_GA4_EVENT = fromJS({
  event: EVENT_ACCOUNT_PAUSED,
})
export const ACCOUNT_UNPAUSED_GA4_EVENT = fromJS({
  event: EVENT_ACCOUNT_UNPAUSED,
})
export const ROW_IMPRESSION_GA4_EVENT = fromJS({
  event: EVENT_ROW_IMPRESSION,
})
export const TOPIC_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_TOPIC_CLICKED,
})
export const PRIMARY_LANGUAGE_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_PRIMARY_LANGUAGE_CHANGED,
})
export const SECONDARY_LANGUAGE_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_SECONDARY_LANGUAGE_CHANGED,
})
export const EMAIL_CAPTURED_GA4_EVENT = fromJS({
  event: EVENT_EMAIL_CAPTURED,
})
export const AUTOPLAY_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_AUTOPLAY_TOGGLED,
})
export const COMMENT_GA4_EVENT = fromJS({
  event: EVENT_COMMENT,
})
export const ITEM_REMOVED_FROM_HISTORY_GA4_EVENT = fromJS({
  event: EVENT_ITEM_REMOVED_FROM_HISTORY,
})
export const SERIES_FOLLOWED_GA4_EVENT = fromJS({
  event: EVENT_SERIES_FOLLOWED,
})
export const SERIES_UNFOLLOWED_GA4_EVENT = fromJS({
  event: EVENT_SERIES_UNFOLLOWED,
})
export const NOTIFICATIONS_OPENED_GA4_EVENT = fromJS({
  event: EVENT_NOTIFICATIONS_OPENED,
})
export const NOTIFICATIONS_MANAGE_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NOTIFICATIONS_MANAGE_CLICKED,
})
export const NOTIFICATIONS_VIEW_ALL_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NOTIFICATIONS_VIEW_ALL_CLICKED,
})
export const CANCEL_FLOW_REASON_SELECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_REASON_SELECTED,
})
export const CANCEL_FLOW_FREE_MONTH_SELECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_FREE_MONTH_SELECTED,
})
export const CANCEL_FLOW_CHANGE_PLAN_SELECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_CHANGE_PLAN_SELECTED,
})
export const SPOTLIGHT_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_TOGGLED,
})
export const NAV_V1_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NAV_CLICKED,
  nav_version: 'v1',
})
export const NAV_V2_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NAV_CLICKED,
  nav_version: 'v2',
})
export const NAV_V4_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NAV_CLICKED,
  nav_version: 'v4',
})
export const PROMO_UNIT_IMPRESSION_GA4_EVENT = fromJS({
  event: EVENT_PROMO_UNIT_IMPRESSION,
})
export const PROMO_UNIT_CLICK_GA4_EVENT = fromJS({
  event: EVENT_PROMO_UNIT_CLICK,
})
export const CLICK_TO_DETAILS_PAGE_GA4_EVENT = fromJS({
  event: EVENT_CLICK_TO_DETAILS_PAGE,
})
export const VIDEO_PLAYER_OPENED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_PLAYER_OPENED,
})
export const VIDEO_LOADS_AND_STARTS_PLAY_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_LOADS_AND_STARTS_PLAY,
})
export const SHARE_INITIATED_GA4_EVENT = fromJS({
  event: EVENT_SHARE_INITIATED,
  full_share: true, // note: this will change in the future but right now is set to true
})
export const SHARE_COMPLETED_GA4_EVENT = fromJS({
  event: EVENT_SHARE_COMPLETED,
})
export const VIDEO_QUALIFIED_VIEW_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_QUALIFIED_VIEW,
})
export const VIDEO_ENGAGED_VIEW_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_ENGAGED_VIEW,
})
export const VIDEO_COMPLETED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_COMPLETED,
})
export const VIDEO_SPEED_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_SPEED_CHANGED,
})
export const VIDEO_AUDIO_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_AUDIO_CHANGED,
})
export const VIDEO_SUBTITLES_SET_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_SUBTITLES_SET,
})
export const PREVIEW_PLAYED_GA4_EVENT = fromJS({
  event: EVENT_PREVIEW_PLAYED,
})
export const PLAYER_INFO_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_PLAYER_INFO_TOGGLED,
})
export const SPOTLIGHT_IMPRESSION_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_IMPRESSION,
})
export const SPOTLIGHT_PLAY_TOGGLE_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_PLAY_TOGGLE,
})
export const SPOTLIGHT_AUDIO_TOGGLE_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_AUDIO_TOGGLE,
})
export const CRETATE_ACCOUNT_PRECURSOR_GA4_EVENT = fromJS({
  event: EVENT_CREATE_ACCOUNT_PRECURSOR,
})
export const SIGN_UP_GA4_EVENT = fromJS({
  event: EVENT_SIGN_UP,
})
export const PLAN_SELECT_PRECURSOR_GA4_EVENT = fromJS({
  event: EVENT_PLAN_SELECT_PRECURSOR,
})
export const BEGIN_CHECKOUT_GA4_EVENT = fromJS({
  event: EVENT_BEGIN_CHECKOUT,
})
export const PAYMENT_INFO_PAGE_GA4_EVENT = fromJS({
  event: EVENT_PAYMENT_INFO_PAGE,
})
export const PURCHASE_GA4_EVENT = fromJS({
  event: EVENT_PURCHASE,
})
export const PURCHASED_MONTHLY_WITH_TRIAL_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_MONTHLY_WITH_TRIAL,
})
export const PURCHASED_MONTHLY_DIRECT_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_MONTHLY_DIRECT,
})
export const PURCHASED_ANNUAL_WITH_TRIAL_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_ANNUAL_WITH_TRIAL,
})
export const PURCHASED_ANNUAL_DIRECT_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_ANNUAL_DIRECT,
})
export const PURCHASED_EVENTS_PLUS_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_EVENTS_PLUS,
})
export const PURCHASED_PROMOTIONAL_PLAN_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_PROMOTIONAL_PLAN,
})
export const FILTER_APPLIED_GA4_EVENT = fromJS({
  event: EVENT_FILTER_APPLIED,
})
export const CONTENT_RATING_GA4_EVENT = fromJS({
  event: EVENT_CONTENT_RATING,
})
export const CHROMECAST_CONNECTED_GA4_EVENT = fromJS({
  event: EVENT_CHROMECAST_CONNECTED,
})
export const AIRPLAY_CONNECTED_GA4_EVENT = fromJS({
  event: EVENT_AIRPLAY_CONNECTED,
})
export const CHECKOUT_UPDATE_EMAIL_GA4_EVENT = fromJS({
  event: EVENT_CHECKOUT_UPDATE_EMAIL,
})
export const CUSTOM_EVENT_GA4_EVENT = fromJS({
  event: EVENT_CUSTOM_EVENT,
})
export const PAYPAL_POPUP_OPENED_GA4_EVENT = fromJS({
  event: PAYPAL_POPUP_OPENED_EVENT,
})
export const START_FREE_TRIAL_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_START_FREE_TRIAL_CLICKED,
})
export const TAG_CLICKED_GA4_EVENT = fromJS({
  event: TAG_CLICKED_EVENT,
})
export const ARTICLE_CLICKED_GA4_EVENT = fromJS({
  event: ARTICLE_CLICKED_EVENT,
})
export const USER_LOGIN_NEW_TO_GAIA_CLICK_GA4_EVENT = fromJS({
  event: USER_LOGIN_NEW_TO_GAIA_CLICK_EVENT,
})
export const NOTIFICATIONS_RECOMMENDED_PERMISSION_TOGGLED_GA4_EVENT = fromJS({
  event: NOTIFICATIONS_RECOMMENDED_PERMISSION_TOGGLED_EVENT,
})
export const NOTIFICATIONS_NEW_CONTENT_PERMISSION_TOGGLED_GA4_EVENT = fromJS({
  event: NOTIFICATIONS_NEW_CONTENT_PERMISSION_TOGGLED_EVENT,
})
export const TESTAROSSA_SPOTLIGHT_CLICKED_GA4_EVENT = fromJS({
  event: TESTAROSSA_SPOTLIGHT_CLICKED_EVENT,
})
export const PAGEVIEW_GA4_EVENT = fromJS({
  event: PAGEVIEW_EVENT,
})
export const CANCEL_FLOW_FREE_MONTH_REJECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_FREE_MONTH_REJECTED,
})
export const EMAIL_PERMISSION_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_EMAIL_PERMISSION_TOGGLED,
})
export const PURCHASE_PLAN_UPGRADE_GA4_EVENT = fromJS({
  event: EVENT_PURCHASE_PLAN_UPGRADE,
})
export const SHARE_OFFER_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_SHARE_OFFER_CLICKED,
})
export const PLAYLIST_TILE_CTA_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_PLAYLIST_TILE_CTA_CLICKED,
})

export function getNavClickedGa4Event (navVersion) {
  let navClickedEvent = fromJS({})
  switch (navVersion) {
    case 'v4':
      navClickedEvent = NAV_V4_CLICKED_GA4_EVENT
      break
    case 'v1':
      navClickedEvent = NAV_V1_CLICKED_GA4_EVENT
      break
    case 'v2':
    default:
      navClickedEvent = NAV_V2_CLICKED_GA4_EVENT
      break
  }

  return navClickedEvent
}

export function buildRemovedRowsSettingsObject (eventLabel, eventAction) {
  return fromJS({
    event: MERCH_EVENT,
    eventCategory: REMOVED_ROWS_SETTINGS_EVENT_CATEGORY,
    eventAction,
    eventLabel,
  })
}

export function buildEmailOptInSettingsObject (eventLabel) {
  return fromJS({
    event: MERCH_EVENT,
    eventCategory: EMAIL_OPT_IN_SETTINGS_EVENT_CATEGORY,
    eventAction: EMAIL_OPT_IN_SETTINGS_ACTION_TOGGLED,
    eventLabel,
  })
}

export function buildSiteLanguageSettingsObject (eventLabel, eventAction) {
  return fromJS({
    event: MERCH_EVENT,
    eventCategory: SITE_LANGUAGE_SETTINGS_EVENT_CATEGORY,
    eventAction,
    eventLabel,
  })
}

export function buildContentLanguageSettingsObject (
  eventLabel,
  eventAction,
  eventValue,
) {
  return fromJS({
    event: MERCH_EVENT,
    eventCategory: CONTENT_LANGUAGE_SETTINGS_EVENT_CATEGORY,
    eventAction,
    eventLabel,
    eventValue,
  })
}

export function buildCustomDataLayerEvent (data) {
  const {
    eventCategory, eventAction, eventLabel, event,
  } = data
  const newEvent = Map({
    event,
    eventCategory,
    eventAction,
    eventLabel,
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEmailDataLayerObject (option) {
  return {
    event: CUSTOM_EVENT,
    eventCategory: 'email collection',
    eventAction: 'email submitted',
    eventLabel: option,
  }
}

export function buildCheckoutStepDataLayerObject (options) {
  const { step, selection } = options
  const id = selection ? selection.get('sku') : ''
  const price = selection ? selection.get('firstPayment') : ''
  const name = selection ? selection.get('heading') : ''
  const brand = selection ? '(not set)' : ''
  const category = selection ? 'plans' : ''
  const quantity = 1
  const product = {
    name,
    id,
    price,
    category,
    brand,
  }

  // If there is a selection in the cart, set the quantity
  if (selection) {
    _set(product, 'quantity', quantity)
  }

  return Map({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step,
        },
        products: [product],
      },
    },
  })
}

function getPurchaseEventData (planSku) {
  let eventData
  switch (planSku) {
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
      eventData = {
        name: PURCHASED_MONTHLY_WITH_TRIAL,
        event: EVENT_PURCHASED_MONTHLY_WITH_TRIAL,
        value: 60,
      }
      break
    case PLAN_SKU_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_MONTHLY:
      eventData = {
        name: PURCHASED_MONTHLY_DIRECT,
        event: EVENT_PURCHASED_MONTHLY_DIRECT,
        value: 120,
      }
      break
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
      eventData = {
        name: PURCHASED_ANNUAL_WITH_TRIAL,
        event: EVENT_PURCHASED_ANNUAL_WITH_TRIAL,
        value: 130,
      }
      break
    case PLAN_SKU_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ANNUAL:
      eventData = {
        name: PURCHASED_ANNUAL_DIRECT,
        event: EVENT_PURCHASED_ANNUAL_DIRECT,
        value: 220,
      }
      break
    case PLAN_SKU_LIVE:
    case PLAN_SKU_INCREASED_PRICE_GAIA_PLUS:
      eventData = {
        name: PURCHASED_EVENTS_PLUS,
        event: EVENT_PURCHASED_EVENTS_PLUS,
        value: 400,
      }
      break
    default:
      eventData = {
        name: PURCHASED_PROMOTIONAL_PLAN,
        event: EVENT_PURCHASED_PROMOTIONAL_PLAN,
        value: 60,
      }
      break
  }

  return eventData
}

export function buildSignUpObject (options) {
  const { uid } = options

  return Map({
    event: EVENT_SIGN_UP,
    userId: uid,
  })
}

export function buildBeginCheckoutObject (options) {
  const { selectedPlan, user } = options
  const sku = selectedPlan.get('sku')
  const currency = selectedPlan.get('currencyIso')
  const itemName = selectedPlan.get('name')
  const userId = user.getIn(['data', 'uid'])

  const gaEvent = getPurchaseEventData(sku)

  return Map(
    {
      event: EVENT_BEGIN_CHECKOUT,
      userId,
      ecommerce: {
        currency,
        value: gaEvent.value,
        price: gaEvent.value,
        items: [
          {
            item_id: sku,
            item_name: itemName,
          },
        ],
      },
    },
  )
}

export function buildPurchaseObject (options) {
  const {
    transactionId,
    userId,
    currency,
    itemId,
    itemName,
  } = options

  const gaEvent = getPurchaseEventData(itemId)

  return Map({
    event: EVENT_PURCHASE,
    userId,
    ecommerce: {
      transaction_id: transactionId,
      currency,
      value: gaEvent.value,
      price: gaEvent.value,
      items: [{
        item_id: itemId,
        item_name: itemName,
      }],
    },
  })
}

export function buildPurchasedPlanObject (options) {
  const {
    transactionId,
    userId,
    currency,
    itemId,
    itemName,
  } = options

  const gaEvent = getPurchaseEventData(itemId)

  return Map({
    event: gaEvent.event,
    eventName: gaEvent.name,
    ecommerce: {
      transaction_id: transactionId,
      userId,
      currency,
      value: gaEvent.value,
      items: [{
        item_id: itemId,
        item_name: itemName,
        quantity: transactionId ? 1 : 0,
      }],
    },
  })
}

export function sentAtDate (date = new Date()) {
  return date.toISOString()
}

export function buildEventVideoPlayed (data) {
  const {
    video, media, upstreamContext, videoAnalyticsId, contentType, page,
  } = data
  let newEvent = Map({
    event: EVENT_VIDEO_PLAYBACK,
    videoId: video.get('id', -1),
    mediaId: media.get('id', -1),
    videoPlayEventId: videoAnalyticsId,
    pageLanguage: page.get('lang'),
    contentLanguage: media.getIn(['data', 'mediaLang']),
    contentType,
  })

  const hasPlayEventData = Map.isMap(upstreamContext)
    && upstreamContext.size > 0
    && upstreamContext.getIn(['playEventData'])

  // add row - play event data
  if (hasPlayEventData) {
    newEvent = newEvent.mergeDeep({
      rowName: upstreamContext.getIn(['playEventData', 'rowName']),
      tileNumber: upstreamContext.getIn(['playEventData', 'tileNumber']),
      rowPlacement: upstreamContext.getIn(['playEventData', 'rowPlacement']),
    })
  }

  const event = createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
  return event
}

export function buildEventVideoCompleted (data) {
  const {
    media, upstreamContext, videoAnalyticsId, contentType,
  } = data
  const newEvent = Map({
    event: CUSTOM_EVENT,
    eventCategory: CATEGORY_VIDEO_PLAYBACK,
    eventAction: EVENT_ACTION_WATCHED,
    eventLabel: EVENT_LABEL_COMPLETE,
    mediaId: media.get('id', -1),
    videoPlayEventId: videoAnalyticsId,
    contentLanguage: media.getIn(['data', 'mediaLang']),
    contentType,
  })

  const event = createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
  return event
}

export function buildEventVideoEngaged (data) {
  const {
    media, upstreamContext, videoAnalyticsId, contentType,
  } = data
  const newEvent = Map({
    event: CUSTOM_EVENT,
    eventCategory: CATEGORY_VIDEO_PLAYBACK,
    eventAction: EVENT_ACTION_WATCHED,
    eventLabel: EVENT_LABEL_ENGAGED,
    mediaId: media.get('id', -1),
    videoPlayEventId: videoAnalyticsId,
    contentLanguage: media.getIn(['data', 'mediaLang']),
    contentType,
  })

  const event = createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
  return event
}

export function buildEventTileClick (data) {
  const {
    id,
    type,
    title,
    action,
    pageUrl,
    feature,
    itemIndex,
    mediaLanguage,
    upstreamContext,
  } = data
  const newEvent = Map({
    event: CUSTOM_EVENT,
    eventCategory: CATEGORY_TILE_CLICK,
    eventAction: action,
    eventLabel: title,
    ...(type !== TYPE_CONTENT_SERIES) && {
      mediaId: feature.get('id', -1),
      tileNumber: itemIndex,
      pageUrl,
    },
    contentId: id,
    contentType: type,
    contentLanguage: mediaLanguage,
  })

  const event = createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
  return event
}

export function buildEventNavigationUserClick (data) {
  const { eventLabel, eventCategory } = data
  const newEvent = Map({
    event: EVENT_NAVIGATION_USER_CLICK,
    eventCategory,
    eventLabel,
  })

  const event = createDefaultEvent(data)
    .mergeDeep(newEvent)
  return event
}

function removeDestId (upstreamContext) {
  let newUpstreamContext = upstreamContext
  if (upstreamContext && upstreamContext.has('destId')) {
    newUpstreamContext = upstreamContext.delete('destId')
  }
  return newUpstreamContext
}

function cleanContext (upstreamContext) {
  return upstreamContext.filter((item) => item !== null)
}

export function buildEventVideoImpressed (data) {
  const newEvent = Map({
    event: 'Video Impressed',
  })
  const { upstreamContext } = data
  let newUpstreamContext = removeDestId(upstreamContext)
  newUpstreamContext = cleanContext(upstreamContext)
  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(newUpstreamContext))
    .mergeDeep(newEvent)
}

export function buildEventSeriesImpressed (data) {
  const newEvent = Map({
    event: 'Series Impressed',
  })
  const { upstreamContext } = data
  let newUpstreamContext = removeDestId(upstreamContext)
  newUpstreamContext = cleanContext(upstreamContext)
  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(newUpstreamContext))
    .mergeDeep(newEvent)
}

export function buildEventVideoVisited (data) {
  const { id } = data
  const newEvent = Map({
    event: 'Video Visited',
    videoId: id,
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventEpisodeVisited (data) {
  const { id } = data
  const newEvent = Map({
    event: 'Episode Visited',
    videoId: id,
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventSeriesVisited (data) {
  const { id } = data
  const newEvent = Map({
    event: 'Series Visited',
    seriesId: id,
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventVideoView (data, status) {
  const {
    video, media, upstreamContext, videoAnalyticsId, contentType,
  } = data
  const newEvent = Map({
    event: `${VIDEO_VIEW} ${status}`,
    videoId: video.get('id', -1),
    mediaId: media.get('id', -1),
    contentLanguage: media.getIn(['data', 'mediaLang']),
    videoPlayEventId: videoAnalyticsId,
    contentType,
  })
  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
}

export function buildEventPlaylistVideoAdded (data) {
  const { id, upstreamContext } = data
  const newEvent = Map({
    event: 'Playlist Video Added',
    videoId: id,
  })

  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
}

export function buildEventGiftVideoViewed (data) {
  const { siteSegment } = data
  const newEvent = fromJS({
    event: 'Gift Video Viewed',
    siteSegment: {
      id: siteSegment.get('id'),
      name: siteSegment.get('name'),
    },
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventPageViewed (data) {
  const { upstreamContext, gaiaScreen } = data
  const newEvent = Map({
    event: 'Page Viewed',
  })

  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(createGaiaScreenProperties(gaiaScreen))
    .mergeDeep(newEvent)
}

export function buildEventExperimentVariantDecided (data) {
  const newEvent = Map({
    event: 'Experiment Variant Decided',
    experiment: _get(data, 'experiment'),
    variant: _get(data, 'variant'),
    tags: _get(data, 'tags', null),
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventExperimentData (data) {
  const newEvent = Map({
    event: 'Experiment Data',
    experiment: _get(data, 'experiment'),
    variant: _get(data, 'variant'),
    tags: _get(data, 'tags', null),
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventUserMenuItemClicked (data) {
  const newEvent = Map({
    event: 'User Menu Item Clicked',
    navItem: _get(data, 'navItem'),
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventUserInteraction (data) {
  const newEvent = Map({
    event: 'User Interaction',
    eventCategory: _get(data, 'category'),
    eventAction: _get(data, 'action'),
    eventLabel: _get(data, 'label', null),
    eventValue: _get(data, 'value', null),
  })
  return createDefaultEvent(data).mergeDeep(newEvent)
}

export function buildEventUserAddComment (data) {
  const { media, video } = data

  const newEvent = Map({
    event: EVENT_USER_ENGAGEMENT_ADD_COMMENT,
    mediaId: media.get('id', -1),
    contentId: video.get('id', -1),
    contentLanguage: media.getIn(['data', 'mediaLang']),
  })

  const event = createDefaultEvent(data)
    .mergeDeep(newEvent)
  return event
}

export function buildEventVideoAudioChanged (data) {
  const { lang } = data

  const newEvent = Map({
    event: EVENT_ACTION_AUDIO_CHANGED,
    eventLabel: lang,
  })

  const event = createDefaultEvent(data)
    .mergeDeep(newEvent)
  return event
}

export function buildEventUserShareVideo (data) {
  const { contentId, eventLabel } = data

  const newEvent = Map({
    event: EVENT_USER_ENGAGEMENT_SHARE_VIDEO,
    eventLabel,
    contentId,
  })

  const event = createDefaultEvent(data)
    .mergeDeep(newEvent)
  return event
}

export function buildEventUserSharePlaylist (data) {
  const newEvent = Map({ event: EVENT_USER_ENGAGEMENT_SHARE_PLAYLIST })
    .mergeDeep(data)

  const event = createDefaultEvent(data)
    .mergeDeep(newEvent)
  return event
}

export function buildEventNewToGaiaClick () {
  const newEvent = Map({
    event: EVENT_USER_NEW_TO_GAIA_CLICK,
  })

  return newEvent
}

export function buildPictureInPictureToggled (data) {
  const { upstreamContext } = data
  const newEvent = Map({
    event: 'Picture In Picture Toggled',
  })
  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(newEvent)
}

export function buildEpisodeListPlayClicked (data) {
  const { id, title } = data
  const event = EVENT_EPISODE_LIST_PLAY_CLICKED
    .set('contentId', id)
    .set('content_name', title)

  return event
}

export function buildEpisodeListAddToPlaylistClicked (data) {
  const { id, title } = data
  const event = EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED
    .set('contentId', id)
    .set('content_name', title)

  return event
}

export function buildEpisodeListTitleClicked (data) {
  const { id, title } = data
  const event = EVENT_EPISODE_LIST_TITLE_CLICKED
    .set('contentId', id)
    .set('content_name', title)

  return event
}

export function createDefaultEvent (data) {
  const {
    auth,
    page = Map(),
    location,
    windowLocation = global.location,
    date,
    app = Map(),
    language = List(),
  } = data

  const userId = auth ? auth.get('uid', 0) : -1

  const newEvent = fromJS({
    timestamp: sentAtDate(date),
    userId,
    context: {
      app: {
        name: app.get('name'),
        version: app.get('version'),
      },
      page: {
        hash: _get(location, 'hash'),
        path: _get(location, 'pathname'),
        search: _get(location, 'search'),
        title: page.get('title'),
        url: _get(windowLocation, 'href', null),
      },
      traits: {
        preferredLanguages: language.toJS(),
      },
    },
  })
  return EVENT_DEFAULT_VALUES.mergeDeep(newEvent)
}

/**
 * Creates an event properties node for tracking events
 * @param  {Immutable.Map} data map of single-level event properties
 * @return {Immutable.Map} event-final structured properties
 */
export function createCustomContextProperties (data = Map()) {
  if (Map.isMap(data) && data.size > 0) {
    let contentType = data.get('contentType')
    let contentId = data.get('contentId')
    if (data.has('videoId')) {
      contentType = TYPE_CONTENT_VIDEO
      contentId = data.get('videoId')
    } else if (data.has('seriesId')) {
      contentType = TYPE_CONTENT_SERIES
      contentId = data.get('seriesId')
    }
    let properties = fromJS({
      contentId,
      contentType,
      properties: {
        gaiaContext: data,
      },
    })
    if (data.has('videoSpeed')) {
      properties = properties.setIn(
        ['properties', 'videoSpeed'],
        data.get('videoSpeed'),
      )
    }
    if (data.has('isPictureInPictureToggled')) {
      properties = properties.setIn(
        ['properties', 'isPictureInPictureToggled'],
        data.get('isPictureInPictureToggled'),
      )
    }
    return properties
  }
  return Map()
}

export function createDefaultContextProperties (data = Map(), attributes = Map()) {
  if (Map.isMap(data) && data.size > 0) {
    const properties = fromJS({
      properties: {
        gaiaContext: data,
        ...attributes,
      },
    })
    return properties
  }
  return Map()
}

/**
 * Creates an event properties node for tracking events
 * @param  {Immutable.Map} data map of single-level event properties
 * @return {Immutable.Map} event-final structured properties
 */
export function createGaiaScreenProperties (data = Map()) {
  if (Map.isMap(data) && data.size > 0) {
    const properties = fromJS({
      properties: {
        gaiaScreen: data,
      },
    })
    return properties
  }

  return Map()
}

/**
 * Sends event data to server
 * @param  {Immutable.Map} data   map of single-level event properties
 */
export async function postEventData (options) {
  try {
    const { auth, data, facebookPixelTestCode } = options
    const path = facebookPixelTestCode ? `v1/events?test=${facebookPixelTestCode}` : 'v1/events'
    apiPost(path, data, { auth }, TYPE_BROOKLYN_JSON)
    // we don't need to store anything from this response right now
  } catch (e) {
    // silent fail
  }
  return null
}

/**
 * Returns the event object for the purchased 'planSku' event
 * @param  {String} planSku string with the sku of the purchased plan
 * @return {Immutable.Map} event data
 */
export function getPurchasedPlanEventData (planSku) {
  let purchasedPlanEventData = null
  switch (planSku) {
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
      purchasedPlanEventData = PURCHASED_MONTHLY_WITH_TRIAL_GA4_EVENT
      break
    case PLAN_SKU_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_MONTHLY:
      purchasedPlanEventData = PURCHASED_MONTHLY_DIRECT_GA4_EVENT
      break
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
      purchasedPlanEventData = PURCHASED_ANNUAL_WITH_TRIAL_GA4_EVENT
      break
    case PLAN_SKU_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ANNUAL:
      purchasedPlanEventData = PURCHASED_ANNUAL_DIRECT_GA4_EVENT
      break
    case PLAN_SKU_LIVE:
    case PLAN_SKU_INCREASED_PRICE_GAIA_PLUS:
      purchasedPlanEventData = PURCHASED_EVENTS_PLUS_GA4_EVENT
      break
    default:
      purchasedPlanEventData = PURCHASED_PROMOTIONAL_PLAN_GA4_EVENT
      break
  }

  return purchasedPlanEventData
}

/**
 * Builds the ecommerce data for the checkout events
 * @param  {Immutable.Map} selectedPlan map with the selected plan data
 * @param  {String} transactionId string with the id of the transaction
 * @return {Object} ecommerce object
 */
export function buildCheckoutEcommerceObject (selectedPlan, transactionId) {
  if (!Map.isMap(selectedPlan) && selectedPlan.size === 0) return {}

  const sku = selectedPlan.get('sku')
  const currency = selectedPlan.get('currencyIso')
  const itemName = selectedPlan.get('name')

  const purchaseEventData = getPurchaseEventData(sku)
  const itemsArray = [
    {
      item_id: sku,
      item_name: itemName,
      price: purchaseEventData.value,
    },
  ]

  return {
    ecommerce: {
      currency,
      ...(transactionId && { transaction_id: transactionId }),
      value: purchaseEventData.value,
      price: purchaseEventData.value,
      items: itemsArray,
    },
  }
}
