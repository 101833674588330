import { Promise as BluebirdPromise } from 'bluebird'
import { fromJS, List } from 'immutable'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import languages from './languages.json'
import {
  EN,
  EN_US,
  DE,
  FR,
  ES,
} from './constants'

/**
 * Get languages from local data.
 */
export function get () {
  return new BluebirdPromise(((resolve) => {
    resolve(fromJS(languages))
  }))
}

/**
 * Check if the language or if the primary language is default i.e. english.
 */
export function isDefault (language) {
  const primaryLanguage = getPrimary(language)
  return primaryLanguage === EN || primaryLanguage === EN_US
}

/**
 * Get the primary language from a List, Array or string
 * @param {import('immutable').List|String[]|String} language The requested language
 */
export function getPrimary (language) {
  if (List.isList(language)) {
    return language.first()
  } if (_isArray(language)) {
    return _get(language, 0)
  }
  return language
}

/**
 * Get the allowed languages array.
 */
export function getAllowedLanguages () {
  return [
    EN,
    ES,
    DE,
    FR,
  ]
}
