import _isBool from 'lodash/isBoolean'
import { getAuthIsLoggedIn } from 'services/auth'
import { addNewRelicAction, BI_NEW_RELIC_ACTION_NAME } from 'services/app/_newrelic'
import { Map } from 'immutable'
import { isMemberHome } from 'services/url'
import { selectUserExternalId } from 'services/user/selectors'
import {
  EVENT_ACTION_PLAY_CLICK,
  EVENT_ACTION_TILE_CLICK,
  IMPRESSION_ACTION,
} from '.'

/**
 * add inTrialSegment custom dimension to all events
 * @param {import('redux').Store} store A redux store
 * @param {import('immutable').Map} event An immutable map
 * @returns {import('immutable').Map} event with trial segment
 */
function addExtraData (store, event) {
  const { userAccount = Map(), auth = Map() } = store.getState()
  const inTrialSegment = getAuthIsLoggedIn(auth)
    ? userAccount.getIn(
      [
        'details',
        'data',
        'billing',
        'subscriptions',
        'plan',
        'inTrialSegment',
      ],
      false,
    )
    : undefined
  const externalId = selectUserExternalId(store.getState())
  const eventExtraData = Map({
    ...(_isBool(inTrialSegment)) && { inTrialSegment },
    ...(externalId) && { externalId },
  })
  const extendedEvent = event.mergeDeep(eventExtraData)

  return extendedEvent
}

/**
 * Push event to Google Tag Manager dataLayer object
 * @param {Object} event
 */
function pushEventToGoogleTagMagager (event) {
  const { dataLayer } = global
  /**
   * flush all unwanted properties
   * src: https://developers.google.com/tag-platform/devguides/datalayer#reset
   */
  dataLayer.push(function resetDataLayer () {
    this.reset()
  })
  dataLayer.push(event)
}

const acceptedListOfEventsAction = [
  EVENT_ACTION_PLAY_CLICK,
  EVENT_ACTION_TILE_CLICK,
  IMPRESSION_ACTION,
]
/**
 * @param {Object} event
 * @param {import('redux').Store} store A redux store
 */
function pushEventToNewRelic (event, store) {
  /*
   * The main purpose of sending events to newRelic is to monitor engagement on the user's home page
   */
  const { auth = Map() } = store.getState()
  const isLoggedIn = getAuthIsLoggedIn(auth)
  const { path } = event
  if (!isMemberHome(path, isLoggedIn)) {
    return
  }

  const { eventAction } = event
  if (!acceptedListOfEventsAction.includes(eventAction)) {
    return
  }

  addNewRelicAction(BI_NEW_RELIC_ACTION_NAME, event)
}

/**
 * Push events to analytic services
 * @param {import('redux').Store} store A redux store
 * @param {import('immutable').Map} event An immutable map
 */
export function sendEvent (store, event) {
  const isGA4Event = !!event.get('membership_status')
  const immutableEventWithExtraData = isGA4Event ? event : addExtraData(store, event)
  const eventWithExtraData = immutableEventWithExtraData.toJS()

  pushEventToGoogleTagMagager(eventWithExtraData)

  pushEventToNewRelic(eventWithExtraData, store)
}

export default sendEvent
