import {
  get as apiGet,
  TYPE_BROOKLYN_JSON,
} from 'api-client'
import {
  fromJS,
  List,
  Map,
  Set,
} from 'immutable'
import _get from 'lodash/get'
import _toNumber from 'lodash/toNumber'
import _template from 'lodash/template'
import _includes from 'lodash/includes'
import { getPrimary as getPrimaryLanguage } from 'services/languages'
import {
  USD,
  EUR,
  renderSymbol as renderCurrencySymbol,
} from 'services/currency'
import { get as getConfig } from 'config'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-cycle
import { selectPlanByPlanId } from './selectors'

const config = getConfig()

// Gift SKUs
export const PLAN_SKU_GIFT_THREE_MONTH = 'GAIA 3MO GIFT'
export const PLAN_SKU_GIFT_ANNUAL = 'GAIA 1Y GIFT'
export const PLAN_SKU_GIFT_EVENTS_PLUS = 'GAIA EVENTS GIFT'
export const PLAN_SKU_BULK_GIFT_THREE_MONTH = 'GAIA 3MO BULK GIFT'
export const PLAN_SKU_BULK_GIFT_ANNUAL = 'GAIA 1Y BULK GIFT'
export const PLAN_SKU_BULK_GIFT_EVENTS_PLUS = 'GAIA EVENTS BULK GIFT'
// Gift Plan IDs
export const PLAN_ID_GIFT_THREE_MONTH = _get(config, ['planIds', 'threeMonthGift'])
export const PLAN_ID_GIFT_ANNUAL = _get(config, ['planIds', 'annualGift'])
export const PLAN_ID_GIFT_LIVE = _get(config, ['planIds', 'liveGift'])
export const PLAN_ID_BULK_GIFT_THREE_MONTH = _get(config, ['planIds', 'bulkThreeMonthGift'])
export const PLAN_ID_BULK_GIFT_ANNUAL = _get(config, ['planIds', 'bulkAnnualGift'])
export const PLAN_ID_BULK_GIFT_LIVE = _get(config, ['planIds', 'bulkLiveGift'])

export const GIFT_PLANS_ID_MAP = fromJS({
  [PLAN_ID_GIFT_THREE_MONTH]: PLAN_ID_GIFT_THREE_MONTH,
  [PLAN_ID_GIFT_ANNUAL]: PLAN_ID_GIFT_ANNUAL,
  [PLAN_ID_GIFT_LIVE]: PLAN_ID_GIFT_LIVE,
  [PLAN_ID_BULK_GIFT_THREE_MONTH]: PLAN_ID_GIFT_THREE_MONTH,
  [PLAN_ID_BULK_GIFT_ANNUAL]: PLAN_ID_GIFT_ANNUAL,
  [PLAN_ID_BULK_GIFT_LIVE]: PLAN_ID_GIFT_LIVE,
})

// Checkout SKUs
export const PLAN_SKU_MONTHLY = 'G 1M HH'
export const PLAN_SKU_ANNUAL = 'G 1Y NB'
export const PLAN_SKU_LEGACY_MONTHLY = 'GTV SB ME 1M'
export const PLAN_SKU_LEGACY_ANNUAL = 'GTV SB ME DA NT'
export const PLAN_SKU_LIVE = 'GTV 1Y LIVE'
export const PLAN_SKU_LIVE_DISCOUNTED = 'GTV 1Y LIVE DFY'
export const PLAN_SKU_NINETY_NINE_CENT_TWO_WEEKS = 'G 2WMO CB'
export const PLAN_SKU_THREE_MONTH = 'G 3MMO UM'
export const PLAN_SKU_BUY_ONE_GET_ONE_FREE = 'GAIA 2 MO'
export const PLAN_SKU_BUY_TWO_GET_TWO_FREE = 'GAIA 4 MO'
export const PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY = 'G 7DMO TY'
export const PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL = 'G 7DF ANNUAL'
export const PLAN_SKU_TWO_WEEK_FREE_TRIAL_TO_MONTHLY = 'G 14DMO TY'
export const PLAN_SKU_TWO_WEEK_FREE_TRIAL_TO_ANNUAL = 'G 14DF ANNUAL'
export const PLAN_SKU_FREE_TRIAL = 'GAIA FREE TRIAL'
export const PLAN_SKU_ONE_DOLLAR_THIRTY_DAYS_TO_MONTHLY = 'G 30DMO TY'
export const PLAN_SKU_DECLINE_303 = 'GTV DECLINE'
export const PLAN_SKU_SUMMIT_MONTHLY = 'G 1M TS'
export const PLAN_SKU_SUMMIT_MONTHLY_2 = 'G 1M TS2'
export const PLAN_SKU_SUMMIT_MONTHLY_3 = 'G 1M TS3'
export const PLAN_SKU_SUMMIT_MONTHLY_4 = 'G 1M TS4'
export const PLAN_SKU_SUMMIT_MONTHLY_5 = 'G 1M TS5'
export const PLAN_SKU_SUMMIT_MONTHLY_6 = 'G 1M TS6'
export const PLAN_SKU_SUMMIT_ANNUAL = 'G 1Y TS'
export const PLAN_SKU_SUMMIT_ANNUAL_2 = 'G 1Y TS2'
export const PLAN_SKU_SUMMIT_ANNUAL_3 = 'G 1Y TS3'
export const PLAN_SKU_SUMMIT_ANNUAL_4 = 'G 1Y TS4'
export const PLAN_SKU_SUMMIT_ANNUAL_5 = 'G 1Y TS5'
export const PLAN_SKU_SUMMIT_ANNUAL_6 = 'G 1Y TS6'
export const PLAN_SKU_ONE_MONTH_TRIAL_TO_ANNUAL = 'G 30DMO TYA'
export const PLAN_SKU_INCREASED_PRICE_MONTHLY = 'G 1M N24'
export const PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY = 'G 7DMO N24'
export const PLAN_SKU_INCREASED_PRICE_ANNUAL = 'G 1Y N24'
export const PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL = 'G 7DFAN N24'
export const PLAN_SKU_INCREASED_PRICE_GAIA_PLUS = 'G GP N24'
export const PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL = 'G 14MO ANNUAL'
export const PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_2 = 'G 14MO ANNUAL2'
export const PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_3 = 'G 14MO ANNUAL3'
export const PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_4 = 'G 14MO ANNUAL4'
export const PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_5 = 'G 14MO ANNUAL5'
export const PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_6 = 'G 14MO ANNUAL6'

// Checkout Plan IDs
export const PLAN_ID_NINETY_NINE_CENT_TWO_WEEKS = _get(config, ['planIds', 'fourteenDay99Cent'])
export const PLAN_ID_MONTHLY = _get(config, ['planIds', 'monthly'])
export const PLAN_ID_ANNUAL = _get(config, ['planIds', 'annual'])
export const PLAN_ID_LIVE = _get(config, ['planIds', 'live'])
export const PLAN_ID_LIVE_DISCOUNTED = _get(config, ['planIds', 'liveDiscounted'])
export const PLAN_ID_THREE_MONTH = _get(config, ['planIds', 'threeMonth'])
export const PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_MONTHLY = _get(config, ['planIds', 'oneWeekFreeTrialToMonthly'])
export const PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_ANNUAL = _get(config, ['planIds', 'oneWeekFreeTrialToAnnual'])
export const PLAN_ID_TWO_WEEK_FREE_TRIAL_TO_MONTHLY = _get(config, ['planIds', 'twoWeekFreeTrialToMonthly'])
export const PLAN_ID_TWO_WEEK_FREE_TRIAL_TO_ANNUAL = _get(config, ['planIds', 'twoWeekFreeTrialToAnnual'])
export const PLAN_ID_LEGACY_MONTHLY = _get(config, ['planIds', 'legacyMonthly'])
export const PLAN_ID_LEGACY_ANNUAL = _get(config, ['planIds', 'legacyAnnual'])
export const PLAN_ID_BUY_ONE_GET_ONE_FREE = _get(config, ['planIds', 'buyOneGetOneFree'])
export const PLAN_ID_BUY_TWO_GET_TWO_FREE = _get(config, ['planIds', 'buyTwoGetTwoFree'])
export const PLAN_ID_FREE_TRIAL = _get(config, ['planIds', 'freeTrial'])
export const PLAN_ID_ONE_DOLLAR_THIRTY_DAYS_TO_MONTHLY = _get(config, ['planIds', 'oneDollarThirtyDaysToMonthly'])
export const PLAN_ID_COMPLIMENTARY = _get(config, ['planIds', 'compPlan'])
export const PLAN_ID_DECLINE_303 = '2c92c0f96b93b7fb016b9539517a3825'
export const PLAN_ID_SUMMIT_MONTHLY = _get(config, ['planIds', 'summitMonthly'])
export const PLAN_ID_SUMMIT_MONTHLY_2 = _get(config, ['planIds', 'summitMonthly2'])
export const PLAN_ID_SUMMIT_MONTHLY_3 = _get(config, ['planIds', 'summitMonthly3'])
export const PLAN_ID_SUMMIT_MONTHLY_4 = _get(config, ['planIds', 'summitMonthly4'])
export const PLAN_ID_SUMMIT_MONTHLY_5 = _get(config, ['planIds', 'summitMonthly5'])
export const PLAN_ID_SUMMIT_MONTHLY_6 = _get(config, ['planIds', 'summitMonthly6'])
export const PLAN_ID_SUMMIT_ANNUAL = _get(config, ['planIds', 'summitAnnual'])
export const PLAN_ID_SUMMIT_ANNUAL_2 = _get(config, ['planIds', 'summitAnnual2'])
export const PLAN_ID_SUMMIT_ANNUAL_3 = _get(config, ['planIds', 'summitAnnual3'])
export const PLAN_ID_SUMMIT_ANNUAL_4 = _get(config, ['planIds', 'summitAnnual4'])
export const PLAN_ID_SUMMIT_ANNUAL_5 = _get(config, ['planIds', 'summitAnnual5'])
export const PLAN_ID_SUMMIT_ANNUAL_6 = _get(config, ['planIds', 'summitAnnual6'])
export const PLAN_ID_ONE_MONTH_TRIAL_TO_ANNUAL = _get(config, ['planIds', 'oneMonthTrialToAnnual'])
export const PLAN_ID_INCREASED_PRICE_MONTHLY = _get(config, ['planIds', 'increasedPriceMonthly'])
export const PLAN_ID_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY = _get(config, ['planIds', 'increasedPriceOneWeekFreeTrialToMonthly'])
export const PLAN_ID_INCREASED_PRICE_ANNUAL = _get(config, ['planIds', 'increasedPriceAnnual'])
export const PLAN_ID_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL = _get(config, ['planIds', 'increasedPriceOneWeekFreeTrialToAnnual'])
export const PLAN_ID_INCREASED_PRICE_GAIA_PLUS = _get(config, ['planIds', 'increasedPriceGaiaPlus'])
export const PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL = _get(config, ['planIds', 'fourteenMonthsToAnnual'])
export const PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_2 = _get(config, ['planIds', 'fourteenMonthsToAnnual2'])
export const PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_3 = _get(config, ['planIds', 'fourteenMonthsToAnnual3'])
export const PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_4 = _get(config, ['planIds', 'fourteenMonthsToAnnual4'])
export const PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_5 = _get(config, ['planIds', 'fourteenMonthsToAnnual5'])
export const PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_6 = _get(config, ['planIds', 'fourteenMonthsToAnnual6'])

export const PLAN_SUBSCRIPTION_ANNUAL = 'annual'
export const PLAN_SUBSCRIPTION_MONTHLY = 'monthly'
export const PLAN_SUBSCRIPTION_LIVE = 'live'
export const PLAN_SUBSCRIPTION_COMP = 'comp'
export const PLAN_SUBSCRIPTION_GIFT = 'gift'
export const PLAN_SUBSCRIPTION_UNKNOWN = 'unknown'
export const PLAN_SUBSCRIPTION_CANCELLED = 'Cancelled'
export const PLAN_TYPE_UPGRADE = 'Upgrade'
export const PLAN_TYPE_DOWNGRADE = 'Downgrade'

export const PLAN_PERIOD_SEVEN_DAYS = 'P7D'
export const PLAN_PERIOD_FOURTEEN_DAYS = 'P14D'
export const PLAN_TRIAL_PERIOD_SEVEN_DAYS = 7
export const PLAN_TRIAL_PERIOD_FOURTEEN_DAYS = 14

export const ANONYMOUS_PLAN_SKUS = List([
  PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL,
  PLAN_SKU_LIVE,
])

export const AUTHENTICATED_PLAN_SKUS = List([
  PLAN_SKU_MONTHLY,
  PLAN_SKU_ANNUAL,
  PLAN_SKU_LIVE,
])

export const GIFT_PLAN_SKUS = List([
  PLAN_SKU_GIFT_THREE_MONTH,
  PLAN_SKU_GIFT_ANNUAL,
  PLAN_SKU_GIFT_EVENTS_PLUS,
  PLAN_SKU_BULK_GIFT_THREE_MONTH,
  PLAN_SKU_BULK_GIFT_ANNUAL,
  PLAN_SKU_BULK_GIFT_EVENTS_PLUS,
])

export const ANONYMOUS_PLAN_SKUS_PRICE_INCREASE = List([
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_GAIA_PLUS,
])

export const AUTHENTICATED_PLAN_SKUS_PRICE_INCREASE = List([
  PLAN_SKU_INCREASED_PRICE_MONTHLY,
  PLAN_SKU_INCREASED_PRICE_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_GAIA_PLUS,
])

export const PRIMARY_PLAN_SKUS = Set().union(
  ANONYMOUS_PLAN_SKUS,
  AUTHENTICATED_PLAN_SKUS,
  ANONYMOUS_PLAN_SKUS_PRICE_INCREASE,
  AUTHENTICATED_PLAN_SKUS_PRICE_INCREASE,
)

// AP-16214 Black Friday Checkout - 2024
export const ANONYMOUS_PLAN_SKUS_FOURTEEN_MONTHS = List([
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_GAIA_PLUS,
])

export const FOURTEEN_MONTHS_PLAN_SKUS = List([
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL,
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_2,
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_3,
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_4,
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_5,
  PLAN_SKU_FOURTEEN_MONTHS_TO_ANNUAL_6,
])

export function getPlanSkus (props = {}) {
  const {
    isGift,
    auth,
    user,
    userAccount = Map(),
    plans = Map(),
    isAccountChangePlan = false,
  } = props

  if (isGift) {
    return GIFT_PLAN_SKUS
  }

  const userRatePlanId = user.getIn(['data', 'freeTrialNoBillingInfo', 'ratePlanId'])
  const userBillingAccountId = user.getIn(['data', 'billing_account_id'])
  const allowAuthenticatedFreeTrialSkus = userRatePlanId === PLAN_ID_FREE_TRIAL && !userBillingAccountId && auth.get('jwt')
  const checkoutComplete = user.getIn(['data', 'checkout_complete'])

  // Plan Price Increase
  const customAuthPlanSkus = getUserAuthPlanList(plans, userAccount)

  // AP-16214 Black Friday Checkout - 2024
  const useFourteenMonthsPlan = !!_get(config, ['features', 'checkoutFourteenMonths'])

  const anonPlanSkus = useFourteenMonthsPlan
    ? ANONYMOUS_PLAN_SKUS_FOURTEEN_MONTHS
    : ANONYMOUS_PLAN_SKUS_PRICE_INCREASE
  const authPlanSkus = isAccountChangePlan
    ? AUTHENTICATED_PLAN_SKUS_PRICE_INCREASE
    : customAuthPlanSkus

  let skus = anonPlanSkus

  if (allowAuthenticatedFreeTrialSkus) {
    skus = anonPlanSkus
  } else if (checkoutComplete) {
    skus = authPlanSkus
  }

  return skus
}

const PLAN_SCHEMA = fromJS({
  id: null,
  heading: null,
  title: null,
  sku: null,
  firstPayment: null,
  billingPeriod: [],
  currencyIso: USD,
  currencySymbol: '$',
  costs: [],
})

/**
 * Determine if the users plan is monthly, comp, or annual, or live.
 * @param {String} productRatePlanId - The product rate plan id from Zuora.
 * @returns {String} The type (monthly, annual, live, unknown)
 */
export function getPlanSubscriptionType (productRatePlanId) {
  switch (productRatePlanId) {
    case PLAN_ID_NINETY_NINE_CENT_TWO_WEEKS:
    case PLAN_ID_MONTHLY:
    case PLAN_ID_THREE_MONTH:
    case PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
    case PLAN_ID_TWO_WEEK_FREE_TRIAL_TO_MONTHLY:
    case PLAN_ID_LEGACY_MONTHLY:
    case PLAN_ID_BUY_ONE_GET_ONE_FREE:
    case PLAN_ID_BUY_TWO_GET_TWO_FREE:
    case PLAN_ID_ONE_DOLLAR_THIRTY_DAYS_TO_MONTHLY:
    case PLAN_ID_SUMMIT_MONTHLY:
    case PLAN_ID_SUMMIT_MONTHLY_2:
    case PLAN_ID_SUMMIT_MONTHLY_3:
    case PLAN_ID_SUMMIT_MONTHLY_4:
    case PLAN_ID_SUMMIT_MONTHLY_5:
    case PLAN_ID_SUMMIT_MONTHLY_6:
    case PLAN_ID_INCREASED_PRICE_MONTHLY:
    case PLAN_ID_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
      return PLAN_SUBSCRIPTION_MONTHLY
    case PLAN_ID_ANNUAL:
    case PLAN_ID_LEGACY_ANNUAL:
    case PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_ID_TWO_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_ID_ONE_MONTH_TRIAL_TO_ANNUAL:
    case PLAN_ID_SUMMIT_ANNUAL:
    case PLAN_ID_SUMMIT_ANNUAL_2:
    case PLAN_ID_SUMMIT_ANNUAL_3:
    case PLAN_ID_SUMMIT_ANNUAL_4:
    case PLAN_ID_SUMMIT_ANNUAL_5:
    case PLAN_ID_SUMMIT_ANNUAL_6:
    case PLAN_ID_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_ID_INCREASED_PRICE_ANNUAL:
    case PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL:
    case PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_2:
    case PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_3:
    case PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_4:
    case PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_5:
    case PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_6:
      return PLAN_SUBSCRIPTION_ANNUAL
    case PLAN_ID_LIVE:
    case PLAN_ID_LIVE_DISCOUNTED:
    case PLAN_ID_INCREASED_PRICE_GAIA_PLUS:
      return PLAN_SUBSCRIPTION_LIVE
    case PLAN_ID_GIFT_THREE_MONTH:
    case PLAN_ID_GIFT_ANNUAL:
    case PLAN_ID_GIFT_LIVE:
    case PLAN_ID_BULK_GIFT_THREE_MONTH:
    case PLAN_ID_BULK_GIFT_ANNUAL:
    case PLAN_ID_BULK_GIFT_LIVE:
      return PLAN_SUBSCRIPTION_GIFT
    default:
      return PLAN_SUBSCRIPTION_UNKNOWN
  }
}

export function determinePlanUpgradeDowngrade (selectedId, currentId) {
  const currentPlanType = getPlanSubscriptionType(currentId)
  const selectedPlanType = getPlanSubscriptionType(selectedId)

  if (currentPlanType === PLAN_SUBSCRIPTION_LIVE && selectedPlanType === PLAN_SUBSCRIPTION_ANNUAL) {
    return PLAN_TYPE_DOWNGRADE
  } if (currentPlanType === PLAN_SUBSCRIPTION_LIVE
     && selectedPlanType === PLAN_SUBSCRIPTION_MONTHLY) {
    return PLAN_TYPE_DOWNGRADE
  } if (currentPlanType === PLAN_SUBSCRIPTION_MONTHLY
    && selectedPlanType === PLAN_SUBSCRIPTION_ANNUAL) {
    return PLAN_TYPE_UPGRADE
  } if (currentPlanType === PLAN_SUBSCRIPTION_MONTHLY
     && selectedPlanType === PLAN_SUBSCRIPTION_LIVE) {
    return PLAN_TYPE_UPGRADE
  } if (currentPlanType === PLAN_SUBSCRIPTION_ANNUAL
    && selectedPlanType === PLAN_SUBSCRIPTION_LIVE) {
    return PLAN_TYPE_UPGRADE
  } if (currentPlanType === PLAN_SUBSCRIPTION_ANNUAL
    && selectedPlanType === PLAN_SUBSCRIPTION_MONTHLY) {
    return PLAN_TYPE_DOWNGRADE
  }
  return null
}

/**
 * Get number of days of the trial period.
 * @param {List} segments - The plan segments
 * @returns {Number} The number of days of the trial period
 */
export function getFreeTrialPeriod (segments = List()) {
  // if we have no segment, or if there is only 1 segment,
  // this plan has no trial period
  if (!List.isList(segments) || segments.size <= 1) {
    return 0
  }

  const firstSegmentPeriod = segments.first().get('period')
  const firstSegmentPrice = Math.ceil(_toNumber(segments.first().get('price', 0)))

  // if the price of the first segment is greater than zero,
  // then it is not a free trial
  if (firstSegmentPrice > 0) {
    return 0
  }

  switch (firstSegmentPeriod) {
    case PLAN_PERIOD_SEVEN_DAYS:
      return PLAN_TRIAL_PERIOD_SEVEN_DAYS
    case PLAN_PERIOD_FOURTEEN_DAYS:
      return PLAN_TRIAL_PERIOD_FOURTEEN_DAYS
    default:
      return 0
  }
}

/**
 * Get plans data for the primary plans, currently uses data from this service.
 * This data is a default values and strings for translations
 * {object} options - The options.
 * {string} options.language - The the language string,
 */
export function getPlans (options = {}) {
  const { language } = options
  const userLangauge = getPrimaryLanguage(language)

  switch (userLangauge) {
    case 'es':
    case 'es-LA':
      return import('./lang_es-LA.json').then(fromJS)
    case 'de':
    case 'de-DE':
      return import('./lang_de-DE.json').then(fromJS)
    case 'fr':
    case 'fr-FR':
      return import('./lang_fr-FR.json').then(fromJS)
    case 'en':
    case 'en-US':
    default:
      return import('./lang_en.json').then(fromJS)
  }
}

/**
 * Get a plan by SKU from the Billing API.
 * And then merging the data from the Billing API with
 * the default values and strings for translation
 * {object} options - The options.
 * {List} options.skus - A immutable List of SKUs to be looked up.
 * {defaultPlans=} options.defaultPlans - A List of plans to be merged with the response.
 * {currencyOverride} options.currencyOverride - an optional currency string to use for
 * for logged in users instead of country
 */
export async function getPlansLocalized (options = {}) {
  const {
    skus,
    language,
    defaultPlans = List(),
    currencyOverride,
    countryOverride,
    userAccount = Map(),
  } = options
  if (!List.isList(skus)) {
    throw new Error('Get plans localized requires a list of skus')
  }
  try {
    const data = { skus: skus.toJS(), language }
    if (countryOverride) data.countryOverride = countryOverride
    if (currencyOverride) data.currencyOverride = currencyOverride
    const res = await apiGet('v1/billing/plans', data, null, TYPE_BROOKLYN_JSON)
    return handlePlansLocalized(res, defaultPlans, language, false, userAccount)
  } catch (e) {
    return handlePlansLocalized({}, defaultPlans, language, true, userAccount)
  }
}

export function handlePlansLocalized (res, defaultPlans, language, _dataError, userAccount) {
  const plans = fromJS(_get(res, 'body', []))

  return Map({
    _dataError,
    language,
    plans: plans.map((val) => {
      const defaultPlan = defaultPlans.find((item) => item.get('sku') === val.get('sku'))
      const plan = _dataError && defaultPlan ? defaultPlan : val
      const productRatePlanId = plan.get('id')
      const savingsAnnualToMonth = annualPlanPercentSavingsFromPlanData(
        plans,
        userAccount,
        productRatePlanId,
      )
      return createPlanLocalized(plan, defaultPlan, _dataError, savingsAnnualToMonth)
    }),
  })
}

export function createPlanLocalized (
  plan,
  defaultPlan,
  _dataError,
  savingsAnnualToMonth,
) {
  const billingPeriod = plan.get('billingPeriod', List())
  const entitlementDays = plan.get('entitlementDays', null)
  let shortDetails

  /* eslint-disable no-template-curly-in-string */
  if (defaultPlan) {
    shortDetails = formatShortDetails(defaultPlan.get('shortDetails', ''), plan, entitlementDays)
  }

  const dataPlan = PLAN_SCHEMA.merge({
    id: plan.get('id', null),
    heading: plan.get('heading', null),
    title: plan.get('title', null),
    sku: plan.get('sku', null),
    firstPayment: plan.get('firstPayment', null),
    billingPeriod: billingPeriod && billingPeriod.size > 0
      ? billingPeriod.map((period) => renderPlanTemplate(plan, period))
      : '',
    shortDetails,
    currencyIso: plan.get('currencyIso', USD),
    currencySymbol: plan.get('currencySymbol', '$'),
    costs: plan.get('costs', List()),
    segments: plan.get('segments', List()),
    country: plan.get('country', ''),
    entitlementDays,
    checkoutEnabled: plan.get('checkoutEnabled', false),
  })

  // We were provide a plan which means we should merge the plan.
  if (defaultPlan) {
    const defaultValueProps = formatValueProps(defaultPlan, plan, savingsAnnualToMonth)
    const defaultBillingPeriod = formatBillingPeriod(defaultPlan, entitlementDays)

    // Render the details from the defaultPlan using the fetched plan.
    return defaultPlan.merge(Map({
      id: plan.get('id', null),
      title: formatShortDetails(defaultPlan.get('title', ''), plan, entitlementDays),
      currencyIso: plan.get('currencyIso', USD),
      currencySymbol: plan.get('currencySymbol', '$'),
      costs: plan.get('costs', List()),
      segments: plan.get('segments', List()),
      billingPeriod: defaultBillingPeriod,
      shortDetails,
      country: plan.get('country', ''),
      entitlementDays: plan.get('entitlementDays', null),
      valueProps: defaultValueProps,
      checkoutEnabled: plan.get('checkoutEnabled', false),
    }))
  }
  return dataPlan
  /* eslint-enable no-template-curly-in-string */
}

/**
 * Replace text tokens using plan data.
 * {Map} plan - The plan data as an immutable map.
 * {string} text - The text with tokens to replace.
 */
export function renderPlanTemplate (plan, template) {
  try {
    return _template(template)(plan.toJS())
  } catch (e) {
    // Do nothing
  }
  return template
}

export function annualPlanPercentSavings (plans, userAccount) {
  if (!plans || !Map.isMap(plans)) {
    return null
  }

  return annualPlanPercentSavingsFromPlanData(plans, userAccount, null)
}

function annualPlanPercentSavingsFromPlanData (
  plans,
  userAccount = Map(),
  productRatePlanId = null,
) {
  // Get plan increase status and user plan list
  const planData = List.isList(plans) ? plans : plans.getIn(['data', 'plans'])
  const userPlanList = getUserAuthPlanList(plans, userAccount)

  // Get current plan data
  const currentPlanProductRatePlanId = productRatePlanId || getActiveProductPlanRateId(userAccount)
  const currentPlanPrice = productRatePlanId
    || (Map.isMap(userAccount) && userAccount.size === 0)
    ? 0 : getCurrentPlanPrice(userAccount)
  const currentPlanType = getPlanSubscriptionType(currentPlanProductRatePlanId)

  // Get monthly and annual plans data
  const isMonthlyPlan = currentPlanType === PLAN_SUBSCRIPTION_MONTHLY
  const isAnnualPlan = currentPlanType === PLAN_SUBSCRIPTION_ANNUAL
  const monthlyPlanSku = userPlanList.get(0)
  const annualPlanSku = userPlanList.get(1)
  const annualPlan = productRatePlanId && isAnnualPlan
    ? planData.find((plan) => plan.get('id') === productRatePlanId) || Map()
    : planData.find((plan) => plan.get('sku') === annualPlanSku) || Map()
  const monthlyPlan = productRatePlanId && isMonthlyPlan
    ? planData.find((plan) => plan.get('id') === productRatePlanId) || Map()
    : planData.find((plan) => plan.get('sku') === monthlyPlanSku) || Map()
  const annualPeriod = isFourteenMonthPlan(annualPlan.get('sku')) ? 14 : 12

  const monthlyPlanPrice = isMonthlyPlan && currentPlanPrice > 1
    ? currentPlanPrice
    : monthlyPlan.get('costs', List([0])).last()
  const annualPlanPricePerMonth = isAnnualPlan && currentPlanPrice > 1
    ? currentPlanPrice / annualPeriod
    : (annualPlan.get('costs', List([0])).last()) / annualPeriod

  const priceDifference = (monthlyPlanPrice - annualPlanPricePerMonth).toFixed(2)
  const percentSavings = Math.floor(((priceDifference / monthlyPlanPrice) * 100)
    .toFixed(2))
  return percentSavings
}

export function getCurrentPlanPrice (userAccount) {
  // Get plan IDs from the user account
  const currentPlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'productRatePlanId'], 0)
  const nextPlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'nextPlan', 'productRatePlanId'], 0)
  const nextBillAmount = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'nextBillAmount'], 0)
  const newPlanRateId = getNewRatePlanId(currentPlanId)

  if (nextPlanId === 0 && nextBillAmount) return nextBillAmount

  // Retrieve plans data
  const currentPlanType = getPlanSubscriptionType(currentPlanId)
  const nextPlanType = getPlanSubscriptionType(nextPlanId)
  const currentPlan = useSelector(selectPlanByPlanId(currentPlanId)) || Map()
  const nextPlan = useSelector(selectPlanByPlanId(nextPlanId)) || Map()
  const newPlan = useSelector(selectPlanByPlanId(newPlanRateId)) || Map()
  const currentPlanCharges = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'charges'], Map()).last() || Map()

  // Get pricing details
  const currentPlanPrice = currentPlan.getIn(['costs'], Map()).last() || 0
  const nextPlanPrice = nextPlan.getIn(['costs'], Map()).last() || 0
  const lastPaidPrice = currentPlanCharges.get('price', 0)
  const newPlanPrice = newPlan.get('costs', List()).last()

  // Determine if we are in a special case
  // trial segment, complimentary plan, gift or increased price)
  const isSpecialCase = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'inTrialSegment'], false)
    || currentPlanId === PLAN_ID_COMPLIMENTARY
    || nextPlanId === PLAN_ID_COMPLIMENTARY
    || planIsGift(currentPlan)
    || planIsGift(nextPlan)
  const isPriceIncreased = (!isSpecialCase && lastPaidPrice !== currentPlanPrice)
    || (nextPlanType === currentPlanType && currentPlanPrice < nextPlanPrice)
  const isNextPlanPriceIncresed = AUTHENTICATED_PLAN_SKUS_PRICE_INCREASE.includes(nextPlan.get('sku'))
  const hasPlanChange = nextPlanId !== currentPlanId

  // Return values
  if (isSpecialCase) return nextBillAmount
  if (isNextPlanPriceIncresed) return newPlanPrice
  if (isPriceIncreased) return nextPlanPrice
  if (hasPlanChange) return currentPlanPrice
  return lastPaidPrice
}

export function formatPrice (currencyIso, amount, showIso, footnote) {
  if (!currencyIso || !amount) {
    return undefined
  }

  let price = ''

  if (currencyIso === EUR) {
    price = `${String(amount).replace(/\.00$/, '').replace(/\./, ',')} ${renderCurrencySymbol(currencyIso)}`
  } else {
    price = `${renderCurrencySymbol(currencyIso)}${String(amount).replace(/\.00$/, '')}`
  }

  if (showIso) {
    price = `${price}${footnote || ''} ${currencyIso}`
  }

  return price
}

export function formatPriceUnaltered (currencyIso, amount) {
  if (!currencyIso || !amount) {
    return undefined
  }

  let price = ''

  if (currencyIso === EUR) {
    price = `${String(amount).replace(/\./, ',')} ${renderCurrencySymbol(currencyIso)}`
  } else {
    price = `${renderCurrencySymbol(currencyIso)}${String(amount)}`
  }

  return price
}

export function getNextPriceFromPlan (plan) {
  if (!plan) {
    return null
  }
  const priceNext = plan.getIn(['segments', 1, 'price'])
  if (!priceNext) {
    return 'N/A'
  }

  // if currency is EUR, format EUR pricing
  if (plan.get('currencyIso') === EUR) {
    return `${String(priceNext).replace(/\.00$/, '').replace(/\./, ',')} ${plan.get('currencySymbol')}`
  }
  return `${plan.get('currencySymbol')}${String(priceNext).replace(/\.00$/, '')}`
}

/**
 * Parse dynamic keywords and return formatted plan short details
 * @param {String} details - The static plan details
 * @param {Map} plan - The plan
 * @param {String} entitlementDays - Number of entitlement days
 * @param {String} currencyIso - The current currency ISO
 * @returns {String} The formatted plan short details
 */
export function formatShortDetails (details, plan, entitlementDays) {
  const costs = plan.get('costs')
  const initialCost = costs.first()
  const finalCost = costs.last()
  const currencyIso = plan.get('currencyIso', USD)
  const currencySymbol = plan.get('currencySymbol', '$')
  const showCurrencyIso = currencyIso !== EUR
  // We need to add an additional $ for older iOS devices
  // The older devices were not showing prices properly due to the leading $ sign and number
  // $11.99 would show up as 1.99, and the device was thinking the $1 was a regex capture group
  // Having $$11.99 is proper regex spec for printing out $11.99
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_a_parameter
  const initialPrice = currencySymbol === '$' ? `$${formatPrice(currencyIso, initialCost, true)}` : formatPrice(currencyIso, initialCost, showCurrencyIso)
  const finalPrice = currencySymbol === '$' ? `$${formatPrice(currencyIso, finalCost, true)}` : formatPrice(currencyIso, finalCost, showCurrencyIso)

  return String(details)
    .replace(/\$\{ initialCost \}/g, initialPrice)
    .replace(/\$\{ finalCost \}/g, finalPrice)
    .replace(/\$\{ entitlementDays \}/g, entitlementDays)
}

/**
 * Parse dynamic keywords and return formatted billing period
 * @param {Map} plan - The plan
 * @returns {String} The formatted plan billing period
 */
export function formatBillingPeriod (plan, entitlementDays) {
  const billingPeriod = plan.get('billingPeriod', List())
  const costs = plan.get('costs')
  const finalCost = costs.last()
  const currencyIso = plan.get('currencyIso', USD)
  const currencySymbol = plan.get('currencySymbol', '$')
  const showCurrencyIso = currencyIso !== EUR
  const finalPrice = currencySymbol === '$' ? `$${formatPrice(currencyIso, finalCost, true)}` : formatPrice(currencyIso, finalCost, showCurrencyIso)

  return billingPeriod
    .map((bp) => bp
      .replace(/\$\{ finalCost \}/g, finalPrice)
      .replace(/\$\{ entitlementDays \}/g, entitlementDays))
}

/**
 * Filter out and adjust value props based upon user language, etc.
 * @param {Map} defaultPlan - The static plan
 * @param {Map} plan - The dynamic plan
 * @param {List} language - List of user languages
 * @param {String} savings annual savings compared to monthly
 * @returns {Map} The final set of value props
 */
export function formatValueProps (
  defaultPlan,
  plan,
  savingsAnnualToMonth,
) {
  const initialCost = plan.get('costs').first()
  const currencyIso = plan.get('currencyIso', USD)
  const currencySymbol = plan.get('currencySymbol', '$')
  const showCurrencyIso = currencyIso !== EUR
  const initialPrice = currencySymbol === '$' ? `$${formatPrice(currencyIso, initialCost, true)}` : formatPrice(currencyIso, initialCost, showCurrencyIso)

  const valueProps = defaultPlan.get('valueProps', Map()).reduce((acc, prop) => {
    let valueProp = prop

    if (_includes(prop, '${ initialCost }')) { // eslint-disable-line
      valueProp = prop.replace(/\$\{ initialCost \}/g, initialPrice)
    }

    if (_includes(prop, '${ savingsPercent }')) { // eslint-disable-line
      valueProp = prop.replace(/\$\{ savingsPercent \}\s/g, `${savingsAnnualToMonth}% `)
    }

    return acc.push(valueProp)
  }, List()).filter((p) => p)

  return valueProps
}

/* Selector function to find a plan from our list of plans by SKU */
export function findPlanInDataList (plans, sku) {
  const selectedPlan = plans.getIn(['data', 'plans']).find((p) => p.get('sku') === sku)
  return selectedPlan
}

/**
 * @param {String} sku
 * @returns {String} encoded SKU
 */
export const encodeUrlSku = (sku = '') => {
  return sku.replace(/\s+/g, '').toLowerCase()
}

/**
 * @param {String} sku encoded SKU
 * @returns {String} decodded SKU
 */
export function decodeGiftUrlSku (sku) {
  const giftPlanSku = GIFT_PLAN_SKUS.find((giftSku) => {
    const encodedGiftSku = encodeUrlSku(giftSku)
    return encodedGiftSku === sku.toLowerCase()
  })
  if (typeof giftPlanSku === 'undefined') {
    return ''
  }
  return giftPlanSku
}

/**
 * determine if a sku is a gift
 * @param {String} sku
 * @returns {Boolean}
 */
export function skuIsGift (sku = '') {
  return GIFT_PLAN_SKUS.includes(sku)
}

/**
 * determine if a plan is a gift
 * @param {Map} plan
 * @returns {Boolean}
 */
export function planIsGift (plan = Map()) {
  const sku = plan.get('sku', '')
  return skuIsGift(sku)
}

/**
 * filters out Gift skus from a list
 * @param {List} skus
 * @returns {List}
 */
export function filterOutGiftSkus (skus = List()) {
  return skus.filter((sku) => {
    return sku && !skuIsGift(sku)
  }).toSet().toList()
}

/**
 * Determine if the price increase plans should be used
 * @param {Map} plans
 * @param {Map} userAccount
 */
export function getUserAuthPlanList (plans, userAccount) {
  // prevent errors
  if (!Map.isMap(userAccount)) {
    return AUTHENTICATED_PLAN_SKUS_PRICE_INCREASE
  }

  const currentPlans = plans.getIn(['data', 'plans'], List())
  const userProductRatePlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'productRatePlanId'], 0)
  const userCurrentPlan = currentPlans.find((plan) => plan.get('id') === userProductRatePlanId)
  const userCurrentPlanSku = Map.isMap(userCurrentPlan) ? userCurrentPlan.get('sku', null) : null
  const subscriptionType = getPlanSubscriptionType(userProductRatePlanId)

  return List([
    subscriptionType === PLAN_SUBSCRIPTION_MONTHLY && userCurrentPlanSku
      ? userCurrentPlanSku : PLAN_SKU_INCREASED_PRICE_MONTHLY,
    subscriptionType === PLAN_SUBSCRIPTION_ANNUAL && userCurrentPlanSku
      ? userCurrentPlanSku : PLAN_SKU_INCREASED_PRICE_ANNUAL,
    subscriptionType === PLAN_SUBSCRIPTION_LIVE && userCurrentPlanSku
      ? userCurrentPlanSku : PLAN_SKU_INCREASED_PRICE_GAIA_PLUS,
  ])
}

/**
 * Replace old sku to a PI sku
 * @param {String} sku
 */
export function replaceSkuToPriceIncreasedSku (sku) {
  const cleanedSku = encodeUrlSku(sku)

  switch (cleanedSku) {
    case encodeUrlSku(PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY):
      return PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY
    case encodeUrlSku(PLAN_SKU_MONTHLY):
      return PLAN_SKU_INCREASED_PRICE_MONTHLY
    case encodeUrlSku(PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL):
      return PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL
    case encodeUrlSku(PLAN_SKU_ANNUAL):
      return PLAN_SKU_INCREASED_PRICE_ANNUAL
    case encodeUrlSku(PLAN_SKU_LIVE):
      return PLAN_SKU_INCREASED_PRICE_GAIA_PLUS
    default:
      return sku
  }
}

/**
 * Get the new price increased plan id
 * @returns {String} New Plan Id
 */
export function getNewRatePlanId (currentRatePlanId) {
  const currentSubscriptionType = getPlanSubscriptionType(currentRatePlanId)

  if (currentSubscriptionType === PLAN_SUBSCRIPTION_LIVE) {
    return PLAN_ID_INCREASED_PRICE_GAIA_PLUS
  }

  if (currentSubscriptionType === PLAN_SUBSCRIPTION_ANNUAL) {
    return PLAN_ID_INCREASED_PRICE_ANNUAL
  }

  return PLAN_ID_INCREASED_PRICE_MONTHLY
}

/**
 * Get active product plan rate id
 * @returns {Map} UserAccount
 */
export function getActiveProductPlanRateId (userAccount) {
  let userProductRatePlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'productRatePlanId'], 0)
  const userPlanType = getPlanSubscriptionType(userProductRatePlanId)
  const isInTrial = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'inTrialSegment'])
  const nextPlanProductRatePlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'nextPlan', 'productRatePlanId'])

  // Get next plan data for compensatory users or downgrades
  if (userProductRatePlanId === PLAN_ID_COMPLIMENTARY
    || userPlanType === PLAN_SUBSCRIPTION_UNKNOWN
    || (isInTrial && nextPlanProductRatePlanId !== userProductRatePlanId)) {
    userProductRatePlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'nextPlan', 'productRatePlanId'])
      || userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'productRatePlanId'], 0)
  }

  return userProductRatePlanId
}

/**
 * Determine if the plan sku is a fourteen month plan promotion
 * @returns {Boolean}
 */
export function isFourteenMonthPlan (sku) {
  return FOURTEEN_MONTHS_PLAN_SKUS.includes(sku)
}
