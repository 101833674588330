import { useContext } from 'react'
import HistoryContext from './HistoryContext'

export function useHistory () {
  const history = useContext(HistoryContext)
  if (typeof history === 'undefined') {
    throw new Error('useHistory must be used within a HistoryContext')
  }
  return history
}

export default useHistory
