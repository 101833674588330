/**
 * We need to use this function instead of calling Date directly to write testable code
 * for more details see https://github.com/testdouble/contributing-tests/wiki/Don%27t-mock-what-you-don%27t-own
 * @returns {Date} today Date object
 */
export function getToday () {
  return new Date()
}

export default getToday
