import PropTypes from 'prop-types'
import { Component } from 'react'
import { Map } from 'immutable'
import _isBool from 'lodash/isBoolean'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect as connectRedux } from 'react-redux'
import compose from 'recompose/compose'
import { getBoundActions } from 'actions'
import { isSynced as resolverIsSynced } from 'services/resolver/synced'
import { createGaiaScreenModel } from 'services/resolver'
import { RESOLVER_TYPE_NODE } from 'services/resolver/types'
import { getAuthIsLoggedIn } from 'services/auth'
import { selectUserExternalId } from 'services/user/selectors'
import { selectMembershipStatus } from 'services/user-account/selectors'
import { PAGEVIEW_GA4_EVENT } from 'services/event-tracking'

const TYPE_PAGE_CATEGORY_UPDATE_DETAIL = 'detail'
const TYPE_PAGE_CATEGORY_UPDATE_VIDEO = 'video'

function getDataLayerName ({ dataLayerName }) {
  return dataLayerName || 'dataLayer'
}

function pushPageCategoryUpdate (props, type = null) {
  const { detail, video, subscriptions } = props
  const detailSiteSegment = detail.getIn(['data', 'siteSegment', 'name'], undefined)
  const videoSiteSegment = video.getIn(['data', 'siteSegment', 'name'], undefined)
  const inTrialSegment = subscriptions.getIn(['plan', 'inTrialSegment'], undefined)
  const externalId = selectUserExternalId(props)
  // this sets siteSegment for the server side load
  let siteSegment = detailSiteSegment || videoSiteSegment || undefined
  // this sets siteSegment for the client side load
  if (type === TYPE_PAGE_CATEGORY_UPDATE_DETAIL) {
    siteSegment = detailSiteSegment
  }
  if (type === TYPE_PAGE_CATEGORY_UPDATE_VIDEO) {
    siteSegment = videoSiteSegment
  }

  setTimeout(() => {
    const data = {
      event: 'pageCategoryUpdate',
      pageCategory: siteSegment,
      externalId,
    }
    if (_isBool(inTrialSegment)) {
      data.inTrialSegment = inTrialSegment
    }

    window[getDataLayerName(props)].push(data)
  }, 0)
}

function resolverChanged (resolver, nextResolver) {
  return !resolver.get('data').equals(nextResolver.get('data'))
}

class GoogleTagManager extends Component {
  componentDidMount () {
    this.sendIntialEvents()
  }

  componentDidUpdate (prevProps) {
    if (!process.env.BROWSER) {
      return
    }

    const { props: nextProps } = this
    const {
      auth,
      location,
      resolver,
      page,
      video,
      setEventPageViewed,
      clearUpstreamContext,
      setGa4InitEvent,
      setDefaultGa4Event,
    } = prevProps
    const {
      auth: nextAuth,
      resolver: nextResolver,
      location: nextLocation,
      page: nextPage,
      video: nextVideo,
      app: nextApp,
      upstreamContext,
    } = nextProps
    const resolverSynced = resolverIsSynced(nextResolver, nextLocation)
    const resolverType = nextResolver.getIn(['data', 'type'])
    const queryChanged = location.search !== nextLocation.search
    const nextPagePath = nextPage.get('path')
    const previousPagePath = page.get('path')
    const nextVideoId = nextVideo.getIn(['data', 'id'])
    const previousVideoId = video.getIn(['data', 'id'])

    this.sendIntialEvents()
    this.sendLoginEvent()

    // login and logout page views
    if (!getAuthIsLoggedIn(auth) && getAuthIsLoggedIn(nextAuth)) {
      this.LoginEventTrigger = true
    } else if (getAuthIsLoggedIn(auth) && !getAuthIsLoggedIn(nextAuth)) {
      setDefaultGa4Event(PAGEVIEW_GA4_EVENT)
    }

    // Track all location changes through the resolver so we can leverage
    // its path info
    if (resolverChanged(resolver, nextResolver)) {
      setGa4InitEvent()
      setDefaultGa4Event(PAGEVIEW_GA4_EVENT)
      setEventPageViewed({
        auth: nextAuth,
        location: nextLocation,
        page: nextPage,
        app: nextApp,
        upstreamContext,
        gaiaScreen: createGaiaScreenModel({ resolver: nextResolver }),
      })
      /**
       * Don't clear upstreamContext if we are redirecting to the VideoPlayer
       * VideoPlayer needs the upstreamContext for the 'video played' event
       * - If we clear upstreamContext here, it is cleared before the 'video
       *   played' event is fired by VideoPlayer
       */
      if (upstreamContext && (!upstreamContext.get('mhTileData') && !nextLocation.query.fullplayer)) {
        clearUpstreamContext()
      }
    }

    // Add siteSegment as pageCategory to the dataLayer
    if (resolverSynced && resolverType === RESOLVER_TYPE_NODE) {
      // a crappy workaround to keep some things from firing twice...
      let triggerVideo = true
      // for detail pages
      if (nextPagePath !== previousPagePath
        || (nextPagePath === previousPagePath && queryChanged)
      ) {
        triggerVideo = false
        pushPageCategoryUpdate(nextProps, TYPE_PAGE_CATEGORY_UPDATE_DETAIL)
      }
      // for video pages
      if (nextVideoId !== previousVideoId && triggerVideo) {
        pushPageCategoryUpdate(nextProps, TYPE_PAGE_CATEGORY_UPDATE_VIDEO)
      }
    }
  }

  sendIntialEvents () {
    if (this.initialEventSent) return
    const { props } = this
    const {
      app,
      auth,
      resolver,
      location,
      page,
      setEventPageViewed,
      subscriptions,
      setGa4InitEvent,
      setDefaultGa4Event,
    } = props
    const resolverType = resolver.getIn(['data', 'type'], null)
    if (!getAuthIsLoggedIn(auth) || (getAuthIsLoggedIn(auth) && subscriptions.size > 0)) {
      setGa4InitEvent()
      setDefaultGa4Event(PAGEVIEW_GA4_EVENT)

      // Create screen/page event model based on location path and path info
      const gaiaScreen = createGaiaScreenModel({ resolver })
      setEventPageViewed({
        auth, location, page, app, gaiaScreen,
      })

      if (resolverType === RESOLVER_TYPE_NODE) {
        pushPageCategoryUpdate(props)
      }
      this.initialEventSent = true
    }
  }

  sendLoginEvent () {
    if (!this.LoginEventTrigger) return
    const { props } = this
    const {
      auth,
      subscriptions,
      setGa4Variables,
      setGa4InitEvent,
      setDefaultGa4Event,
    } = props
    if (getAuthIsLoggedIn(auth) && subscriptions.size > 0) {
      setGa4Variables()
      setGa4InitEvent()
      setDefaultGa4Event(PAGEVIEW_GA4_EVENT)
      this.LoginEventTrigger = false
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render () {
    return null
  }
}

GoogleTagManager.propTypes = {
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
  location: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  scriptId: PropTypes.string,
  app: ImmutablePropTypes.map.isRequired,
  auth: ImmutablePropTypes.map.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  resolver: ImmutablePropTypes.map.isRequired,
  plans: ImmutablePropTypes.map.isRequired,
  page: ImmutablePropTypes.map.isRequired,
  detail: ImmutablePropTypes.map.isRequired,
  video: ImmutablePropTypes.map.isRequired,
  checkout: ImmutablePropTypes.map.isRequired,
  setEventPageViewed: PropTypes.func,
}

export default compose(
  connectRedux(
    (state) => {
      const membershipStatus = selectMembershipStatus(state)

      return {
        app: state.app,
        auth: state.auth,
        user: state.user,
        resolver: state.resolver,
        plans: state.plans,
        page: state.page,
        detail: state.detail,
        video: state.video,
        checkout: state.checkout,
        upstreamContext: state.upstreamContext.get('data'),
        subscriptions: state.userAccount.getIn(['details', 'data', 'billing', 'subscriptions'], Map()),
        membershipStatus,
      }
    },
    (dispatch) => {
      const actions = getBoundActions(dispatch)
      return {
        actions,
        setEventPageViewed: actions.eventTracking.setEventPageViewed,
        clearUpstreamContext: actions.upstreamContext.clearUpstreamContext,
        setGa4Variables: actions.eventTracking.setGa4Variables,
        setDefaultGa4Event: actions.eventTracking.setDefaultGa4Event,
        setGa4InitEvent: actions.eventTracking.setGa4InitEvent,
      }
    },
  ),
)(GoogleTagManager)
