/* eslint-disable no-param-reassign */
import { Promise as BluebirdPromise } from 'bluebird'
import {
  getLocalPreferences,
  setLocalPreferences,
} from 'services/local-preferences'
import {
  fromJS,
  List,
  Map,
} from 'immutable'
import _toString from 'lodash/toString'
import _get from 'lodash/get'

const LOCAL_STORAGE_KEY = 'inboundTracking'
const INBOUND_TRACKING_QUERY_UTM_VARS = List([
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_campaign',
  'utm_content',
  'utm_rainbow',
  'utm_platcamp',
  'ch',
  'gclid',
])
const REFERRAL_TRACKING_QUERY_VARS = List([
  'sourceId',
  'source',
  'rfd',
])

const EVERFLOW_TRACKING_QUERY_VARS = List([
  'ef_txn',
  'transaction_id',
  'oid',
  'affid',
])

const EVERFLOW_TRACKING_VARS = List([
  'transactionId',
  'offerId',
  'affiliateId',
])

const INBOUND_TRACKING_CI_VARS = List(['ci_type', 'ci_id'])
const INBOUND_TRACKING_SCARAB_VARS = List(['sc_src', 'sc_llid'])
const INBOUND_TRACKING_GCLID_VARS = List(['gclid'])
const INBOUND_TRACKING_QUERY_VARS = INBOUND_TRACKING_QUERY_UTM_VARS.concat(
  List(['cid', 'siteID', 'chan'])
    .concat(INBOUND_TRACKING_CI_VARS)
    .concat(REFERRAL_TRACKING_QUERY_VARS)
    .concat(INBOUND_TRACKING_SCARAB_VARS)
    .concat(INBOUND_TRACKING_GCLID_VARS)
    .concat(EVERFLOW_TRACKING_QUERY_VARS),
)

export function parseQuery (query, timestamp = new Date()) {
  return fromJS(query || {})
    .filter((v, k) => INBOUND_TRACKING_QUERY_VARS.includes(k))
    .withMutations((mutateQuery) => {
      if (containsUtm(mutateQuery)) {
        const utm = mutateQuery.filter((v, k) => INBOUND_TRACKING_QUERY_UTM_VARS.includes(k))
        const utmString = utm
          .reduce((reduction, v, k) => {
            reduction.push(`${k}=${encodeURIComponent(v)}`)
            return reduction
          }, [])
          .join('&')
        mutateQuery.set('strings', Map({ utm: utmString }))
      }
      if (containsSiteID(mutateQuery)) {
        mutateQuery.set(
          'linkshare',
          Map({ siteID: mutateQuery.get('siteID'), timestamp }),
        )
      }
      if (containsCI(mutateQuery)) {
        mutateQuery.update('ci_id', (ciId) => _toString(ciId))
      }
      if (hasAmbassadorUTM(mutateQuery)) {
        mutateQuery.delete('rfd')
        mutateQuery.delete('source')
        mutateQuery.delete('sourceId')
      } else if (mutateQuery.get('rfd')) {
        mutateQuery.set('source', mutateQuery.get('source', 'REFERRAL'))
        mutateQuery.set('sourceId', mutateQuery.get('sourceId', undefined))
      }
      if (containsEverflowTrackingQueryVars(mutateQuery)) {
        if (mutateQuery.get('ef_txn')) {
          mutateQuery.set('transactionId', mutateQuery.get('ef_txn'))
          mutateQuery.delete('ef_txn')
        }

        if (mutateQuery.get('transaction_id')) {
          mutateQuery.set('transactionId', mutateQuery.get('transaction_id'))
          mutateQuery.delete('transaction_id')
        }

        if (mutateQuery.get('oid')) {
          mutateQuery.set('offerId', mutateQuery.get('oid'))
          mutateQuery.delete('oid')
        }

        if (mutateQuery.get('affid')) {
          mutateQuery.set('affiliateId', mutateQuery.get('affid'))
          mutateQuery.delete('affid')
        }
      }

      return mutateQuery
    })
}

export function queryContainsTracking (query) {
  return Map(query || {}).some((v, k) => INBOUND_TRACKING_QUERY_VARS.includes(k))
}

export function hasAmbassadorUTM (tracking) {
  const t = Map(tracking || {})
  return t.has('utm_medium')
    && /^ambassador$/.test(t.get('utm_source', ''))
}

// these utilities are demonstrated in ./inbound-tracking.unit.js
export function containsUtm (inboundTracking) {
  return inboundTracking.some((v, k) => INBOUND_TRACKING_QUERY_UTM_VARS.includes(k))
}

export function containsCI (inboundTracking) {
  return inboundTracking.some((v, k) => INBOUND_TRACKING_CI_VARS.includes(k))
}

export function containsGCLID (inboundTracking) {
  return inboundTracking.some((v, k) => INBOUND_TRACKING_GCLID_VARS.includes(k))
}

export function containsSiteID (inboundTracking) {
  return inboundTracking.has('siteID')
}

export function containsChan (inboundTracking) {
  return inboundTracking.has('chan')
}

export function containsCid (inboundTracking) {
  return inboundTracking.has('cid')
}

export function containsEverflowTrackingQueryVars (inboundTracking) {
  return inboundTracking.some((v, k) => EVERFLOW_TRACKING_QUERY_VARS.includes(k))
}

export function containsEverflowTrackingVars (inboundTracking) {
  return inboundTracking.some((v, k) => EVERFLOW_TRACKING_VARS.includes(k))
}

/**
 * Set inbound tracking using the API and save to local state
 * @param {Object} options The options
 * @param {Number} options.uid The user uid
 * @param {Map|Object} options.data The data
 * @param {Map} options.auth The auth state
 * @returns Inbound tracking Map
 */
export async function set (options = {}) {
  const data = Map(_get(options, 'data', {}))
  const auth = _get(options, 'auth')
  const uid = _get(options, 'uid')

  try {
    let localInboundTracking = await get({ uid })

    if (containsUtm(data)) {
      localInboundTracking = localInboundTracking.filter((v, k) => {
        return !REFERRAL_TRACKING_QUERY_VARS.includes(k)
      })
    }

    if (data.get('rfd')) {
      localInboundTracking = localInboundTracking.filter((v, k) => {
        return !INBOUND_TRACKING_QUERY_UTM_VARS.includes(k)
      }).withMutations((mutateLocalInboundTracking) => {
        // delete the 'strings' as it contains the complete utm string
        mutateLocalInboundTracking.delete('strings')
        return mutateLocalInboundTracking
      })
    }

    if (containsEverflowTrackingVars(data)) {
      localInboundTracking = localInboundTracking.filter((v, k) => {
        return !INBOUND_TRACKING_QUERY_UTM_VARS.includes(k)
      }).withMutations((mutateLocalInboundTracking) => {
        // if the affiliate is the same, but the offer is different,
        // or if the affiliate is different, delete the stored Everflow data
        if (
          (_toString(data.get('affiliateId')) === _toString(mutateLocalInboundTracking.get('affiliateId'))
          && _toString(data.get('offerId')) !== _toString(mutateLocalInboundTracking.get('offerId')))
          || (_toString(data.get('affiliateId')) !== _toString(mutateLocalInboundTracking.get('affiliateId')))
        ) {
          mutateLocalInboundTracking.delete('transactionId')
          mutateLocalInboundTracking.delete('offerId')
          mutateLocalInboundTracking.delete('affiliateId')
        }
        return mutateLocalInboundTracking
      })
    }

    const mergedInboundTracking = localInboundTracking.merge(data)
    const inboundTracking = mergedInboundTracking.filter((k) => k !== undefined)
    setLocalPreferences(uid, LOCAL_STORAGE_KEY, inboundTracking, auth)
    return inboundTracking
  } catch (e) {
    return Map()
  }
}

export function get (options = {}) {
  const { uid } = options
  return new BluebirdPromise(((resolve) => {
    const inboundTracking = getLocalPreferences(uid, LOCAL_STORAGE_KEY) || {}
    resolve(fromJS(inboundTracking))
  }))
}

export function purgeSessionTracking (options = {}) {
  const { uid, auth } = options
  try {
    const inboundTracking = getLocalPreferences(uid, LOCAL_STORAGE_KEY) || {}
    delete inboundTracking.sc_src
    delete inboundTracking.sc_llid
    setLocalPreferences(uid, LOCAL_STORAGE_KEY, inboundTracking, auth)
    return inboundTracking
  } catch (err) {
    return Map()
  }
}
