import {
  stringify as stringifyQs,
  parse as parseQs,
} from 'qs'

/** The format for an array query */
const ARRAY_FORMAT = 'brackets'

/** The query stringify options */
export const STRINGIFY_OPTIONS = Object.freeze({
  arrayFormat: ARRAY_FORMAT,
  encodeValuesOnly: true,
})

/** The query parser options */
export const PARSE_OPTIONS = Object.freeze({
  ignoreQueryPrefix: true,
})

/**
 * Stringify a query for using in a HTTP request
 * @param {Object} query The request object
 * @returns {String} The object converted to a query string
 */
export function stringify (query) {
  return stringifyQs(query, STRINGIFY_OPTIONS)
}

/**
 * Parse a URL for a query string
 * @param {String} url The url to parse
 * @returns {Object} The query as an object
 */
export function parse (url) {
  try {
    return parseQs(url, PARSE_OPTIONS)
  } catch (e) {
    if (!(e instanceof URIError)) {
      throw e
    }
  }
  return {}
}
