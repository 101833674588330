import { Map, fromJS } from 'immutable'
import * as actions from './actions'

export const initialState = Map()

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHECKOUT_ACCOUNT_VALID:
      return state.set('accountValid', action.payload)
    case actions.SET_CHECKOUT_USER_DATA:
      return state.set('account', fromJS(action.payload))
    case actions.SET_CHECKOUT_USER_VALID:
      return state.set('userValid', action.payload)
    case actions.SET_CHECKOUT_ORDER_PROCESSING:
      return state.set('processing', action.payload)
    case actions.SET_CHECKOUT_ORDER_ERROR:
      return state.withMutations((mutateState) => mutateState
        .set('orderError', action.payload)
        .delete('processing'))
    case actions.SET_CHECKOUT_ORDER_DATA:
      return state.set('orderData', fromJS(action.payload))
    case actions.SET_CHECKOUT_ORDER_COMPLETE:
      return state.withMutations((mutateState) => (mutateState
        .set('orderComplete', action.payload)
        // Clean up
        .deleteIn(['account', 'password'])
        .delete('billing')
        .delete('billingValid')
        .delete('accountValid')))
    case actions.SET_CHECKOUT_EMAIL_CAPTURE_STATUS:
      return state.withMutations((mutateState) => mutateState
        .set('emailCaptureSuccess', action.payload.status)
        .set('emailCaptureErrorCode', action.payload.errorCode))
    case actions.RESET_CHECKOUT_EMAIL_CAPTURE_STATUS:
      return state.withMutations((mutateState) => mutateState
        .delete('emailCaptureSuccess')
        .delete('emailCaptureErrorCode'))
    case actions.SET_CHECKOUT_STEP:
      return state.set('step', action.payload)
    case actions.SET_CHECKOUT_PLAN_SELECTION:
      return state.set('planSelection', action.payload)
    case actions.SET_CHECKOUT_EVENT_STEP:
      return state.set('eventStep', action.payload)
    case actions.RESET_CHECKOUT:
      return state.withMutations((mutateState) => (mutateState
        .delete('step')
        .delete('eventStep')
        .delete('orderComplete')
        .delete('processing')
        .delete('account')
        .delete('billing')
        .delete('billingValid')
        .delete('orderError')
        .delete('orderData')
        .delete('accountValid')))
    case actions.SET_CHECKOUT_REACTIVATION:
      return state.set('reactivation', action.payload)
    case actions.SET_CHECKOUT_ORDER_STATUS:
      return state.set('orderId', action.payload.id)
    case actions.SET_CHECKOUT_INITIATED:
      return state.set('checkoutInitiated', action.payload)
    case actions.SET_CHECKOUT_PLAN_PRE_SELECTED:
      return state.set('checkoutPlanPreselected', action.payload)
    case actions.SET_CHECKOUT_CREATION_SOURCE:
      return state.set('creationSource', action.payload)
    default:
      return state
  }
}
