import Link from 'components/Link'
import { createElement } from 'react'

const MARKDOWN_PARSE_RULES = [
  {
    type: 'link',
    regEx: /\[(.*?)\]\((.*?)\)/gim,
    createComponent: (string) => {
      const text = string.match(/\[(.*?)\]/)[1]
      const to = string.match(/\((.*?)\)/)[1]
      return createElement(Link, { to, key: to }, text)
    },
  },
  {
    type: 'bold',
    regEx: /\*\*(.*)\*\*/gim,
    createComponent: (string) => {
      const text = string.match(/\*\*(.*)\*\*/)[1]
      return createElement(
        'b',
        {
          key: text,
        },
        text,
      )
    },
  },
]

const getTransformedMessage = (original) => MARKDOWN_PARSE_RULES.reduce((acc, rule) => {
  // Get all text/markdown nodes
  return acc.map((str) => {
    const isMatch = rule.regEx.test(str)
    if (isMatch) {
      // If node is valid markdown create corresponding React.createElement call to return JSX
      return rule.createComponent(str)
    }
    // Otherwise simply return the string
    return str
  })
}, original)

/**
 * Find and replace markdown with the corellate React/DOM element
 * @param  {string} message - The markdown containing notification message to parse
 * @return {array} An array of strings and createElement calls to render in a component
 */
export function parseNotificationMessageMarkdown (message) {
  const splitMessage = message.split(/[\s]+/)
  const parsedMessageArr = getTransformedMessage(splitMessage)
  const renderArr = []

  parsedMessageArr.forEach((el, index, arr) => {
    // Add spaces back in after all elements but the last
    const isLast = index === arr.length - 1
    if (!isLast) {
      renderArr.push(el)
      renderArr.push(' ')
      return null
    }
    renderArr.push(el)
    return null
  })

  return renderArr
}

export default { parseNotificationMessageMarkdown }
