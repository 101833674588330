const BOOTSTRAP_PHASE_POST_RENDER = 'post-render'
const BOOTSTRAP_PHASE_PRE_RENDER = 'pre-render'
const BOOTSTRAP_PHASE_COMPLETE = 'complete'
const BOOTSTRAP_PHASE_INIT = 'init'

const APP_RELOAD_INTERVAL_SECONDS = 14400

export * from './selectors'
export {
  BOOTSTRAP_PHASE_POST_RENDER,
  BOOTSTRAP_PHASE_PRE_RENDER,
  BOOTSTRAP_PHASE_COMPLETE,
  BOOTSTRAP_PHASE_INIT,
  APP_RELOAD_INTERVAL_SECONDS,
}
