import { Map } from 'immutable'
import { doAuthLogin, doAuthRenew, SET_AUTH_LOGIN_SUCCESS } from 'services/auth/actions'
import { SET_RESOLVER_DATA, SET_RESOLVER_LOCATION } from 'services/resolver/actions'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { RESOLVER_TYPE_GIFT } from 'services/resolver/types'
import { renderModal } from 'services/dialog/actions'
import { getPlansData } from 'services/plans/actions'
import { TYPE_GIFT_CONFIRM, TYPE_GIFT_PERIOD_NOTIFICATION } from 'services/dialog'
import { USD } from 'services/currency'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import _get from 'lodash/get'
import _isNaN from 'lodash/isNaN'
import { get as getConfig } from 'config'

import {
  findPlanInDataList,
  decodeGiftUrlSku,
  skuIsGift,
  getPlanSubscriptionType,
  PLAN_SUBSCRIPTION_GIFT,
} from 'services/plans'

import {
  resetPaymentData,
  resetPaymentMethods,
} from 'services/payment/actions'

import {
  resetCheckout,
} from 'services/checkout/actions'

import {
  isGiftCompletePage,
  isGiftRedeemPage,
  isGiftReviewPage,
  isGiftGiverPage,
  createRelative,
  isCheckoutFlowPage,
  isFullPlayer,
  isActivate,
  isGetStarted,
  isPolicy,
  isPasswordReset,
  isNotFound,
  isLogout,
  isLivePage,
  isCartAccessDenied,
  isAccountCancelPath,
  isAccountPauseFlow,
  isAccountInvoice,
  isAccountUpdatePayment,
  isGift,
  isSharePage,
  isAmbassadorsPage,
} from 'services/url'

import {
  formatTracking,
  formatBilling,
} from 'services/checkout'

import {
  selectResolverSKUParam,
  selectPath,
} from 'services/resolver/selectors'

import {
  selectPaymentAuthorized,
} from 'services/payment/selectors'

import {
  updateAdyenAuthorization,
  ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED,
} from 'services/adyen'

import {
  resetAdyen,
} from 'services/adyen/actions'

import {
  pushHistory,
  replaceHistory,
} from 'services/navigation/actions'

import {
  URL_GIFT,
} from 'services/url/constants'

import {
  clearSessionStorage,
  getSessionStorage,
  setSessionStorage,
} from 'services/local-preferences'
import {
  getUserGiftSubscriptionsWithDetails,
  SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
  SET_USER_GIFT_SUBSCRIPTIONS_WITH_DETAILS,
} from 'services/user-account/actions'
import {
  selectBillingAccountId,
  selectEndDate,
  selectNextPlan,
  selectPaidThroughDate,
  selectProductRatePlanId,
} from 'services/user-account/selectors'
import { selectFeatureTrackingGiftExpiringMessage } from 'services/feature-tracking/selectors'
import { getDifferenceInDays } from 'services/date-time'
import { setFeatureTrackingDataPersistent } from 'services/feature-tracking/actions'

import * as selectors from './selectors'
import * as actions from './actions'
import { get as getGift, getRouteDetails } from '.'
import { GIFT_SUBROUTE_PAYMENT } from './utils'
import * as api from '.'

const config = getConfig()
const giftReminderActive = !!_get(config, ['features', 'gift', 'giftReminder'])
const planGraphQLActive = !!_get(config, ['features', 'checkout', 'planGraphQL'])

function getPath (state) {
  const { resolver } = state
  return resolver.get('path')
}

function isCompletePage ({ state }) {
  const path = getPath(state)
  return isGiftCompletePage(path)
}

function isRedeemPage ({ state }) {
  const path = getPath(state)
  return isGiftRedeemPage(path)
}

function isGiverPage ({ state }) {
  const path = getPath(state)
  return isGiftGiverPage(path)
}

function isGiftResolverType ({ state }) {
  const { resolver } = state
  const type = resolver.getIn(['data', 'type'])
  return type === RESOLVER_TYPE_GIFT
}

function isGiftNotificationAllowed ({ state }) {
  const path = getPath(state)

  return giftReminderActive
    && !isActivate(path)
    && !isGetStarted(path)
    && !isPolicy(path)
    && !isPasswordReset(path)
    && !isNotFound(path)
    && !isLogout(path)
    && !isLivePage(path)
    && !isCartAccessDenied(path)
    && !isAccountCancelPath(path)
    && !isAccountPauseFlow(path)
    && !isAccountInvoice(path)
    && !isAccountUpdatePayment(path)
    && !isGift(path)
    && !isSharePage(path)
    && !isAmbassadorsPage(path)
    && !isCheckoutFlowPage(path)
    && !isFullPlayer(path)
}

// -----------------------------------
// Watcher for componentDidMount
// -----------------------------------
export function onGiftPageMount ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_RESOLVER_LOCATION,
    SET_RESOLVER_DATA,
  ], async ({ dispatch, state }) => {
    const { plans, user, app } = state
    const isReady = app.get('bootstrapComplete')
    const language = user.getIn(['data', 'language'])
    const giftDetails = selectors.selectGiftDetails(state)
    const sessionGiftDetails = getSessionStorage('gift')

    const skuAsParam = selectResolverSKUParam(state)
    const sku = decodeGiftUrlSku(skuAsParam)

    // if the url :sku is not a gift sku
    // we are redirecting to /gift which is a proxy
    // for the Wordpress gift landing page
    if (skuAsParam && !skuIsGift(sku)) {
      dispatch(pushHistory({
        url: createRelative(URL_GIFT),
      }))
      return
    }

    // if we are bootstrapped and there is no plans data...
    if (isReady && !planGraphQLActive
      && !plans.get('data')
      && !plans.get('processing')) {
      dispatch(getPlansData({ language }))
    }

    // if our current gift detail redux state is empty
    if (giftDetails.size < 1 && sessionGiftDetails) {
      dispatch(actions.setGiftDetails(sessionGiftDetails))
    }

    // if the gift store does not have the sku
    // or if the sku in the url is different than the sku in the store
    if (!giftDetails.get('planSku')
      || (giftDetails.get('planSku') !== sku)) {
      dispatch(actions.setGiftPlanSKU(sku))
    }
  }).when(isGiverPage)
}

// -----------------------------------
// sets checkout paymentType when a 'type'
// is found in the resolver/url query string
// on page mount
// -----------------------------------
export function onReviewPageMount ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_RESOLVER_LOCATION,
    SET_RESOLVER_DATA,
  ], async ({ state, dispatch }) => {
    const cardPaymentInfo = selectPaymentAuthorized(state)
    // if we have payment data do not wipe out
    // state but if we do not have payment data we
    // want to wipe out state so that the iframe loads again
    if (!cardPaymentInfo) {
      const { resolver } = state
      const sku = resolver.getIn(['data', 'params', 'sku']) || ''
      const skuAsParam = sku.replace(/\s+/g, '').toLowerCase()
      dispatch(replaceHistory({
        url: `${URL_GIFT}/${skuAsParam}/payment`,
      }))
    }
  }).when(({ state }) => {
    const path = getPath(state)
    return isGiftReviewPage(path)
  })
}

// -----------------------------------
// Watcher for mount complete page
// -----------------------------------
export function onCompletePageMount ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_RESOLVER_LOCATION,
    SET_RESOLVER_DATA,
  ], async ({ state, dispatch }) => {
    const { resolver, gift } = state
    const token = resolver.getIn(['query', 'token'], '')
    const existingToken = gift.getIn(['details', 'token'])
    const lastStatusUnsuccessful = gift.getIn(['details', 'statusCode']) !== 200
    if (((token !== existingToken) || lastStatusUnsuccessful)) {
      const data = await getGift(token)
      dispatch(actions.setGiftDetails(data))
      clearSessionStorage('gift')
    }
  }).when(isCompletePage)
}

// -----------------------------------
// Watcher for mount redeem page
// -----------------------------------
export function onRedeemPageMount ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_RESOLVER_LOCATION,
    SET_RESOLVER_DATA,
  ], async ({ dispatch, state }) => {
    const { resolver, gift, app } = state
    const isReady = app.get('bootstrapComplete')
    const token = resolver.getIn(['query', 'token'], '')
    const existingToken = gift.getIn(['details', 'token'])
    if (isReady && token && token !== existingToken) {
      dispatch(actions.getGiftDetails(token))
    }
  }).when(isRedeemPage)
}

// -----------------------------------
// Watcher for creating a gift order
// -----------------------------------
export function onCreateGiftOrder ({ after }) {
  return after(actions.GIFT_CREATE_ORDER, async ({ dispatch, state, action }) => {
    const {
      inboundTracking,
      userAccount,
      payment,
      plans,
      auth,
    } = state
    const { payload } = action

    dispatch({
      type: actions.GIFT_SET_ORDER_PROCESSING,
      payload: true,
    })

    const giftData = selectors.selectGiftDetails(state)
    const giftSku = selectors.selectGiftSku(state)
    const plan = planGraphQLActive ? Map() : findPlanInDataList(plans, giftSku)

    const currencyIso = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'currencyIso']) || USD
    const productRatePlanId = planGraphQLActive ? _get(payload, 'value') : plan.get('id', '')
    const paymentResultCode = payment.getIn(['data', 'payments', 'data', 'resultCode'])
    const paymentIsAuthorized = paymentResultCode === ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED
    const billing = {
      currencyIso,
      productRatePlanId,
      country: payment.getIn(['data', 'payments', 'billingAddress', 'country']) || payment.getIn(['data', 'billingInfo', 'billingAddress', 'country']),
      postalCode: payment.getIn(['data', 'payments', 'billingAddress', 'postalCode']) || payment.getIn(['data', 'billingInfo', 'billingAddress', 'postalCode']),
      adyen: {
        shopperReference: payment.getIn(['data', 'payments', 'data', 'additionalData', 'recurring.shopperReference']),
        ...(paymentIsAuthorized) && { recurringDetailReference: payment.getIn(['data', 'payments', 'data', 'additionalData', 'recurring.recurringDetailReference']) },
        cardSummary: payment.getIn(['data', 'payments', 'data', 'additionalData', 'cardSummary']),
      },
    }

    const order = {
      tracking: formatTracking(inboundTracking),
      gift: _omitBy(giftData.toJS(), _isEmpty),
      billing: formatBilling(billing),
    }

    try {
      const orderData = await api.createOrder(order, auth)
      // --------
      // unsuccessful purchase
      // --------
      if (!_get(orderData, 'success')) {
        dispatch({
          type: actions.GIFT_SET_ORDER_ERROR_DATA,
          payload: {
            error: true,
            success: false,
            errorCode: _get(orderData, 'errorCode', null),
          },
        })
        dispatch({
          type: actions.GIFT_SET_ORDER_PROCESSING,
          payload: false,
        })
        dispatch(resetPaymentMethods())
        dispatch(resetPaymentData())
        dispatch(resetAdyen())
        dispatch(resetCheckout())
        const path = selectPath(state)
        const { encodedSku } = getRouteDetails(path)
        dispatch(pushHistory({
          scrollToTop: true,
          url: `${URL_GIFT}/${encodedSku}/${GIFT_SUBROUTE_PAYMENT}`,
        }))
        return
      }
      // --------
      // successful purchase
      // --------
      setSessionStorage('gift', _get(orderData, 'gift'))
      dispatch({
        type: actions.GIFT_SET_ORDER_DATA,
        payload: orderData,
      })
      dispatch({
        type: actions.GIFT_SET_ORDER_PROCESSING,
        payload: false,
      })
      const authorizationUuid = payment.getIn(['data', 'payments', 'authorization', 'uuid'])

      if (authorizationUuid) {
        updateAdyenAuthorization({
          uuid: authorizationUuid,
          billingAccountCreated: true,
          auth,
        })
      }

      dispatch(resetPaymentMethods())
      dispatch(resetPaymentData())
      dispatch(resetAdyen())
      dispatch(resetCheckout())
      // for edge cases that need updated billing info after buying a gift
      // such as a gift period user buying a gift
      dispatch(doAuthRenew(auth))
    } catch (e) {
      dispatch({
        type: actions.GIFT_SET_ORDER_ERROR_DATA,
        payload: e,
      })
    }
  })
}

// -----------------------------------
// Watcher for GIFT_SET_DETAILS_PERSISTENT
// -----------------------------------
export function onSetGiftDetailsPersistent ({ after }) {
  return after(actions.GIFT_SET_DETAILS_PERSISTENT, ({ action }) => {
    const { payload } = action
    setSessionStorage('gift', payload)
  })
    .when(isGiverPage)
}

// -----------------------------------
// Watcher for GIFT_GET
// -----------------------------------
export function onGetGift ({ takeMaybe }) {
  return takeMaybe(actions.GIFT_GET_DETAILS, ({ action, state }) => {
    const { gift } = state
    const { payload } = action
    const { token } = payload
    const existingToken = gift.getIn(['details', 'token'])
    const lastStatusUnsuccessful = gift.getIn(['details', 'statusCode']) !== 200
    if (token !== existingToken || lastStatusUnsuccessful) {
      return async () => {
        const data = await getGift(token)
        return actions.setGiftDetails(data)
      }
    }
    return null
  })
}

// -----------------------------------
// Watcher for GIFT_REDEEM
// -----------------------------------
export function onGiftRedeem ({ after }) {
  return after([actions.GIFT_REDEEM, SET_AUTH_LOGIN_SUCCESS], async ({
    dispatch,
    action,
    state,
  }) => {
    const { auth, gift } = state
    const { payload = {}, type } = action
    if (type === SET_AUTH_LOGIN_SUCCESS && gift.getIn(['redeemData', 'success'])) {
      return
    }
    dispatch({
      type: actions.GIFT_SET_REDEEM_PROCESSING,
      payload: true,
    })
    const token = gift.getIn(['details', 'token'], '')
    delete payload.terms // we don't use terms on BE right now
    try {
      const redeemData = await api.redeem({ token, auth, user: payload })
      if (!_get(redeemData, 'success')) {
        dispatch({
          type: actions.GIFT_SET_REDEEM_ERROR_DATA,
          payload: redeemData,
        })
        dispatch({
          type: actions.GIFT_SET_REDEEM_PROCESSING,
          payload: false,
        })
        dispatch(renderModal(TYPE_GIFT_CONFIRM, {
          onDismiss: () => dispatch({
            type: actions.GIFT_CLEAR_REDEEM_ERROR_DATA,
          }),
        }))
        return
      }
      if (type !== SET_AUTH_LOGIN_SUCCESS && payload) {
        const username = _get(payload, 'email')
        const password = _get(payload, 'password')
        if (username && password) {
          dispatch(doAuthLogin({
            username,
            password,
          }))
        }
      }
      dispatch({
        type: actions.GIFT_SET_REDEEM_DATA,
        payload: redeemData,
      })
      dispatch({
        type: actions.GIFT_SET_REDEEM_PROCESSING,
        payload: false,
      })
      dispatch(renderModal(TYPE_GIFT_CONFIRM, {
        hideDismiss: true,
      }))
      dispatch(doAuthRenew(auth))
    } catch (e) {
      dispatch({
        type: actions.GIFT_SET_REDEEM_ERROR_DATA,
        payload: e,
      })
      dispatch({
        type: actions.GIFT_SET_REDEEM_PROCESSING,
        payload: false,
      })
    }
  })
    .when(isRedeemPage)
}

// -----------------------------------
// Watcher for leaving gift flow
// -----------------------------------
export function onGiftUnmount ({ before }) {
  return before(SET_RESOLVER_DATA, async ({ dispatch, action }) => {
    const { payload = {} } = action
    const { data = {} } = payload
    const { type } = data
    if (type !== RESOLVER_TYPE_GIFT) {
      clearSessionStorage('gift')
      dispatch({ type: actions.GIFT_UNMOUNT })
    }
  }).when(isGiftResolverType)
}

// ---------------------------------------
// Watcher for get gift subscription data
// ---------------------------------------
export function watchUserAccountSubscriptionsData ({ after }) {
  return after([
    SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
  ], async ({ dispatch, state }) => {
    const { auth, userAccount } = state

    const billingStatus = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'status'])
    const hasGiftEndDate = !!userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'giftEndDate'])

    if (billingStatus === 400 || hasGiftEndDate) {
      dispatch(getUserGiftSubscriptionsWithDetails({ auth }))
    }
  })
}

// ---------------------------------------
// Watcher for Prompt gift receivers to enter payment
// ---------------------------------------
export function watchGiftPeriodOfEnding ({ after }) {
  return after([
    SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
    SET_USER_GIFT_SUBSCRIPTIONS_WITH_DETAILS,
  ], async ({ dispatch, state }) => {
    const { auth } = state

    const zuoraAccount = selectBillingAccountId(state)
    let paidThroughDate

    // check zuoraAccount or eligible value to set paidThroughDate
    if (zuoraAccount) {
      const nextPlan = selectNextPlan(state)
      const endDate = selectEndDate(state)
      const productRatePlanId = selectProductRatePlanId(state)
      const subscriptionType = getPlanSubscriptionType(productRatePlanId)
      const isGiftPlan = subscriptionType === PLAN_SUBSCRIPTION_GIFT

      if (isGiftPlan && (!nextPlan || endDate !== null)) {
        paidThroughDate = selectPaidThroughDate(state)
      }
    } else {
      const { eligible, paidThroughDate: eligiblePaidThroughDate } = await api.getEligible(auth)

      if (!eligible) {
        return
      }

      paidThroughDate = eligiblePaidThroughDate
    }

    const giftDaysRemaining = getDifferenceInDays(paidThroughDate, Date.now())

    // Don't show modal if more than 14 days left or is NaN
    if (_isNaN(giftDaysRemaining) || giftDaysRemaining > 14) {
      return
    }

    const giftExpiringMessage = selectFeatureTrackingGiftExpiringMessage(state)

    // AP-12806. AC 2.a
    const isBetween14and8 = giftDaysRemaining <= 14
      && giftDaysRemaining >= 8
      && giftExpiringMessage !== 1

    // AP-12806. AC 2.b
    const isBetween7and4 = giftDaysRemaining <= 7
      && giftDaysRemaining >= 4
      && giftExpiringMessage !== 2

    // AP-12806. AC 2.c
    const isBetween3and0 = giftDaysRemaining <= 3
      && giftDaysRemaining >= 0
      && giftExpiringMessage !== 3

    const getNewGiftExpiringMessage = () => {
      if (isBetween14and8) return 1
      if (isBetween7and4) return 2
      if (isBetween3and0) return 3

      return 0
    }

    if (isBetween14and8 || isBetween7and4 || isBetween3and0) {
      dispatch(
        setFeatureTrackingDataPersistent({
          auth,
          data: Map({ giftExpiringMessage: getNewGiftExpiringMessage() }),
        }),
      )
      dispatch(renderModal(TYPE_GIFT_PERIOD_NOTIFICATION, {
        giftDaysRemaining,
        hideOverflow: true,
        hideDismiss: true,
      }))
    }
  }).when(isGiftNotificationAllowed)
}
