import { BOOTSTRAP_PHASE_POST_RENDER, BOOTSTRAP_PHASE_COMPLETE } from '.'

export const SET_APP_STORE_INITIALIZED = 'SET_APP_STORE_INITIALIZED'
export const SET_APP_BOOTSTRAP_PHASE = 'SET_APP_BOOTSTRAP_PHASE'
export const SET_APP_SCROLLABLE = 'SET_APP_SCROLLABLE'
export const SET_APP_RENDERABLE = 'SET_APP_RENDERABLE'
export const SET_APP_VIEWPORT = 'SET_APP_VIEWPORT'
export const SET_APP_HEADER = 'SET_APP_HEADER'
export const SET_APP_FOOTER = 'SET_APP_FOOTER'
export const SET_APP_LAST_INTERACTED_TIMESTAMP = 'SET_APP_LAST_INTERACTED_TIMESTAMP'
export const SET_APP_INTERACTED_CHECK = 'SET_APP_INTERACTED_CHECK'
export const SET_APP_IS_INITIAL_RENDER = 'SET_APP_IS_INITIAL_RENDER'

export function setAppBootstrapPhase (phase) {
  return {
    type: SET_APP_BOOTSTRAP_PHASE,
    payload: {
      isComplete: phase === BOOTSTRAP_PHASE_POST_RENDER
        || phase === BOOTSTRAP_PHASE_COMPLETE,
      phase,
    },
  }
}

export function setAppViewport (payload) {
  return {
    type: SET_APP_VIEWPORT,
    payload,
  }
}

export function setAppScrollable (bool = true) {
  return {
    type: SET_APP_SCROLLABLE,
    payload: !!bool,
  }
}

export function setAppRenderable (bool = true) {
  return {
    type: SET_APP_RENDERABLE,
    payload: !!bool,
  }
}

export function setAppLastInteractedTimestamp (timestamp) {
  return {
    type: SET_APP_LAST_INTERACTED_TIMESTAMP,
    payload: timestamp,
  }
}

export function setAppInteractedCheck () {
  return {
    type: SET_APP_INTERACTED_CHECK,
  }
}

export function setAppIsInitialRender (isInitialRender) {
  return {
    type: SET_APP_IS_INITIAL_RENDER,
    payload: isInitialRender,
  }
}
