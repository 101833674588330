export const RESOLVER_TYPE_HOME = 'home'
export const RESOLVER_TYPE_CATEGORY = 'category'
export const RESOLVER_TYPE_SUBCATEGORY = 'subcategory'
export const RESOLVER_TYPE_NODE = 'node'
export const RESOLVER_TYPE_CLASSIC_FACET = 'classicFacet'
export const RESOLVER_TYPE_STATIC = 'static'
export const RESOLVER_TYPE_NOT_FOUND = 'notFound'
export const RESOLVER_TYPE_SHARE = 'share'
export const RESOLVER_TYPE_PORTAL = 'portal'
export const RESOLVER_TYPE_EVENTS = 'events'
export const RESOLVER_TYPE_PLAYLIST = 'playlist'
export const RESOLVER_TYPE_GIFT = 'gift'
