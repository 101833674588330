import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose } from 'recompose'
import { connect as connectRedux } from 'react-redux'
import connectRouter from 'components/Router/connect'
import _concat from 'lodash/concat'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _size from 'lodash/size'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _flattenDeep from 'lodash/flattenDeep'
import _trim from 'lodash/trim'
import _merge from 'lodash/merge'
import Helmet from 'react-helmet'
import { RESOLVER_TYPE_NOT_FOUND } from 'services/resolver/types'
import { parse as parseQuery } from 'services/query-string'
import { SEO_ALLOWED_INDEX_QUERY_PARAMS, getPageTitle } from 'services/page'
import { isVideo, isSeries } from 'services/url'
import { get as getConfig } from 'config'
import { format as formatDateTime, getDateTime } from 'services/date-time'
import { formatDuration } from 'theme/web-app'

const config = getConfig()

export const FB_PROPERTY_APP_ID = 'fb:app_id'
export const OG_PROPERTY_SITE_NAME = 'og:site_name'
export const OG_PROPERTY_TITLE = 'og:title'
export const OG_PROPERTY_IMAGE = 'og:image'
export const OG_PROPERTY_URL = 'og:url'
export const OG_PROPERTY_TYPE = 'og:type'
export const OG_PROPERTY_DESCRIPTION = 'og:description'
export const TWITTER_CARD = 'twitter:card'
export const TWITTER_IMAGE = 'twitter:image'
export const TWITTER_TITLE = 'twitter:title'
export const TWITTER_DESCRIPTION = 'twitter:description'

function getLinkPrev (page) {
  const href = page.get('prev')
  if (!href) {
    return []
  }
  return [{ rel: 'prev', href }]
}

function getLinkNext (page) {
  const href = page.get('next')
  if (!href) {
    return []
  }
  return [{ rel: 'next', href }]
}

function getLink (page, location) {
  const link = [
    { rel: 'icon', type: 'img/png', href: '/favicon.ico?ver=0.0.2' },
  ]
  return _concat(
    link,
    getLinkCanonical(page, location),
    getLinkPrev(page),
    getLinkNext(page),
  )
}

function getLinkCanonical (page, location) {
  return [{ rel: 'canonical', href: page.get('canonical', location.pathname) }]
}

function getGoogleSiteSearchMeta (detail, resolver) {
  const path = resolver.get('path', null)

  if (isSeries(path) || isVideo(path)) {
    const googleMetaTags = [
      {
        name: 'gaia-language',
        content: detail.getIn(['data', 'contentLanguage']),
      },
      {
        name: 'gaia-content-id',
        content: detail.getIn(['data', 'nid']),
      },
      {
        name: 'gaia-content-type',
        content: detail.getIn(['data', 'impressionType']),
      },
      {
        name: 'gaia-published-date',
        content: detail.getIn(['data', 'created']),
      },
    ]

    return googleMetaTags
  }

  return []
}

function getMeta (page, location, resolver, detail) {
  return _concat(
    getPageMetaDescription(page),
    getPageMetaOg(page),
    getMetaRobots(page, location, resolver),
    getPageMetaTwitter(page),
    getGoogleSiteSearchMeta(detail, resolver),
  )
}

function getMetaRobots (page, location, resolver) {
  const query = parseQuery(location.search)
  const resolverType = resolver.getIn(['data', 'type'])
  const defaultNoIndex = resolverType === RESOLVER_TYPE_NOT_FOUND
  // defaults to true if there is no query params, which is desired
  let seoAllowedQueryParams = _size(query) === 0

  // loop through the query params and see if there is an allowed param
  if (_size(query) > 0) {
    _forEach(query, (value, key) => {
      if (seoAllowedQueryParams) {
        return false
      }
      seoAllowedQueryParams = _find(SEO_ALLOWED_INDEX_QUERY_PARAMS, (param) => param === key)
      return seoAllowedQueryParams
    })
  }

  const noFollow = page.get('noFollow', false)
  let noIndex = page.get('noIndex', defaultNoIndex)

  // if there is not an allowed query param, do not allow the page to be indexed
  if (!seoAllowedQueryParams) {
    noIndex = true
  }

  const metaRobotsShouldRender = noFollow || noIndex || !seoAllowedQueryParams

  if (!metaRobotsShouldRender) {
    return []
  }

  const robotsContent = []
  // If there is a query string, noindex, follow
  // Otherwise, conditionally push on nofollow, noindex based on props
  if (noFollow) {
    robotsContent.push('nofollow')
  }
  if (noIndex) {
    robotsContent.push('noindex')
  }
  return [{ name: 'robots', content: robotsContent.join(', ') }]
}

function getPageMetaDescription (page) {
  const content = page.get('description')
  if (!content) {
    return []
  }
  return [{ name: 'description', content }]
}

function getPageMetaOg (page) {
  const meta = [
    { property: FB_PROPERTY_APP_ID, content: page.get('facebookAppId') },
    { property: OG_PROPERTY_SITE_NAME, content: page.get('ogSiteName') },
    { property: OG_PROPERTY_TITLE, content: page.get('ogTitle') },
    { property: OG_PROPERTY_IMAGE, content: page.get('ogImage') },
    { property: OG_PROPERTY_URL, content: page.get('ogUrl') },
    { property: OG_PROPERTY_TYPE, content: page.get('ogType') },
    { property: OG_PROPERTY_DESCRIPTION, content: page.get('ogDescription') },
  ]
  return _filter(meta, (v) => _get(v, 'content'))
}

function getPageMetaTwitter (page) {
  const meta = [
    { name: TWITTER_CARD, content: page.get('twitterCard') },
    { name: TWITTER_IMAGE, content: page.get('twitterImage') },
    { name: TWITTER_TITLE, content: page.get('twitterTitle') },
    { name: TWITTER_DESCRIPTION, content: page.get('twitterDescription') },
  ]
  return _filter(meta, (v) => _get(v, 'content'))
}

function getClass (isWebView, interstitial, scrollable, jwt) {
  const cls = [jwt ? 'app--member' : 'app--anon']
  // the app--webview class is for styling content
  // specific to a native application WebView
  if (isWebView) cls.push('app--webview')
  if (scrollable === false) cls.push('app--noscroll')
  if (interstitial.get('view')) cls.push('app--interstitial')
  if (interstitial.get('leaving')) cls.push('app--interstitial-leaving')
  return cls.join(' ')
}

function isEmbedded (location) {
  const embed = _get(location, 'query.embed', 'false')
  return embed === 'true'
}

function getStructuredMetadataArray (value, type) {
  const valueArray = []

  // return an empty array if no value is given
  if (!value || !type) {
    return valueArray
  }

  // split the string into an array on commas
  const arr = value.split(', ')
  // loop over the array, and split it again on &
  // then flatten deep so we a single level array
  const newArr = _flattenDeep(_map(arr, (v) => {
    return v.split('&')
  }))

  // push each name onto the values array, formatted into an object
  _map(newArr, (n) => {
    return valueArray.push({ '@type': type, name: _trim(n) })
  })

  return valueArray
}

function getActors (host, guest) {
  if (!host && !guest) {
    return []
  }

  let hostArray = []
  let guestArray = []

  if (host) {
    hostArray = getStructuredMetadataArray(host, 'Person')
  }

  if (guest) {
    guestArray = getStructuredMetadataArray(guest, 'Person')
  }

  return _merge(hostArray, guestArray)
}

function getDirector (director) {
  if (!director) {
    return []
  }

  return getStructuredMetadataArray(director, 'Person')
}

function Head ({
  interstitial,
  contentLang,
  scrollable,
  isWebView,
  resolver,
  location,
  page,
  detail,
  jwt,
}) {
  const pageTitle = getPageTitle(page)
  const embed = isEmbedded(location)
  // cache htmlAttributes
  const htmlAttributes = {
    class: getClass(isWebView, interstitial, scrollable, jwt),
    'data-embed': embed,
    lang: contentLang,
  }
  // cache link
  const link = getLink(page, location)
  // cache meta
  const meta = getMeta(page, location, resolver, detail)
  const getLdJson = (path) => {
    if (!path) {
      return null
    }

    if (isSeries(path)) {
      const url = `${_get(config, ['origin']) + detail.get('path')}`
      const reformattedDate = getDateTime(detail.getIn(['data', 'created'])) * 1000
      const dateFormatString = 'YYYY-MM-DD'
      const locale = page.get('locale')
      const formattedDate = formatDateTime(reformattedDate, locale, dateFormatString)

      const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'TVSeries',
        url,
        name: detail.getIn(['data', 'title'], ''),
        description: detail.getIn(['data', 'teaser'], ''),
        genre: detail.getIn(['data', 'adminCategory', 'name'], ''),
        image: detail.getIn(['data', 'keyArt'], ''),
        dateCreated: formattedDate,
        actors: getActors(detail.getIn(['data', 'host']), detail.getIn(['data', 'guest'])),
        creator: detail.getIn(['data', 'studio']) ? [{ '@type': 'Organization', name: detail.getIn(['data', 'studio']) }] : [],
        director: getDirector(detail.getIn(['data', 'director'])),
        numberOfSeasons: detail.getIn(['data', 'totalSeasons'], 1),
        startDate: formattedDate,
      }

      return (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )
    } if (isVideo(path)) {
      let url = `${_get(config, ['origin']) + detail.get('path')}`
      const reformattedDate = getDateTime(detail.getIn(['data', 'created'])) * 1000
      const dateFormatString = 'YYYY-MM-DD'
      const locale = page.get('locale')
      const formattedDate = formatDateTime(reformattedDate, locale, dateFormatString)

      if (detail.getIn(['data', 'preview', 'id']) > 0) {
        url += '?fullplayer=preview'
      }

      const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: detail.getIn(['data', 'title'], ''),
        description: detail.getIn(['data', 'teaser'], ''),
        thumbnailUrl: detail.getIn(['data', 'keyArt'], ''),
        uploadDate: formattedDate,
        duration: formatDuration(detail.getIn(['data', 'runtime'], 0), true),
        publisher: {
          '@type': 'Organization',
          name: 'Gaia',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.gaia.com/files/gaia-logo-gradient-social.png',
            width: 1024,
            height: 576,
          },
        },
        contentUrl: url,
      }

      return (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )
    }

    return null
  }

  return (
    <Helmet
      htmlAttributes={htmlAttributes}
      title={pageTitle}
      link={link}
      meta={meta}
    >
      {getLdJson(resolver.get('path', null))}
    </Helmet>
  )
}

Head.defaultProps = {
  scrollable: true,
  jwt: null,
}

Head.propTypes = {
  interstitial: ImmutablePropTypes.map.isRequired,
  resolver: ImmutablePropTypes.map.isRequired,
  page: ImmutablePropTypes.map.isRequired,
  detail: ImmutablePropTypes.map.isRequired,
  location: PropTypes.object.isRequired,
  scrollable: PropTypes.bool,
  jwt: PropTypes.string,
}

export default compose(
  connectRouter(),
  connectRedux(({
    user, app, interstitial, auth, resolver, page, detail,
  }) => ({
    contentLang: user.getIn(['data', 'language', 0], 'en'),
    scrollable: app.get('scrollable', true),
    isWebView: app.get('isWebView', false),
    jwt: auth.get('jwt', null),
    interstitial,
    resolver,
    page,
    detail,
  })),
)(Head)
