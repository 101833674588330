import _merge from 'lodash/merge'
import {
  buildEventVideoPlayed,
  buildEventVideoCompleted,
  buildEventVideoEngaged,
  buildEventVideoVisited,
  buildEventSeriesVisited,
  buildEventPlaylistVideoAdded,
  buildEventVideoView,
  buildEventGiftVideoViewed,
  buildEventPageViewed,
  buildEventExperimentVariantDecided,
  buildEventExperimentData,
  buildEventUserInteraction,
  buildEventUserMenuItemClicked,
  buildEventUserAddComment,
  buildCustomDataLayerEvent,
  buildEventVideoImpressed,
  buildEventSeriesImpressed,
  buildCheckoutStepDataLayerObject,
  buildEventEpisodeVisited,
  buildEventTileClick,
  buildEventUserShareVideo,
  buildEventUserSharePlaylist,
  buildEventNewToGaiaClick,
  buildEpisodeListPlayClicked,
  buildEpisodeListAddToPlaylistClicked,
  buildEpisodeListTitleClicked,
  QUALIFIED,
  EVENT_ACTION_PLAY_CLICK,
  EVENT_ACTION_CLICK_DETAIL_PAGE,
  EVENT_LABEL_REDDIT,
  EVENT_LABEL_FACEBOOK,
  EVENT_LABEL_TWITTER,
  EVENT_LABEL_EMAIL,
  EVENT_LABEL_EMBED,
  EVENT_LABEL_GENERATE_LINK,
  CATEGORY_NAVIGATION_V1,
  CATEGORY_NAVIGATION_V2,
  buildEventNavigationUserClick,
  CATEGORY_NAVIGATION_V3,
} from './index'
import {
  TRACK_EVENT_DELAY_MS,
} from './constants'

export const SET_DEFAULT_EVENT = 'SET_DEFAULT_EVENT'
export const SET_EVENT_VIDEO_PLAYED = 'SET_EVENT_VIDEO_PLAYED'
export const SET_EVENT_VIDEO_COMPLETED = 'SET_EVENT_VIDEO_COMPLETED'
export const SET_EVENT_VIDEO_ENGAGED = 'SET_EVENT_VIDEO_ENGAGED'
export const SET_EVENT_PAGE_CONTEXT_VIDEO_PLAYED = 'SET_EVENT_PAGE_CONTEXT_VIDEO_PLAYED'
export const SET_EVENT_VIDEO_VISITED = 'SET_EVENT_VIDEO_VISITED'
export const SET_EVENT_EPISODE_VISITED = 'SET_EVENT_EPISODE_VISITED'
export const SET_EVENT_SERIES_VISITED = 'SET_EVENT_SERIES_VISITED'
export const SET_EVENT_VIDEO_VIEW_QUALIFIED = 'SET_EVENT_VIDEO_VIEW_QUALIFIED'
export const SET_EVENT_PLAYLIST_VIDEO_ADDED = 'SET_EVENT_PLAYLIST_VIDEO_ADDED'
export const SET_EVENT_GIFT_VIDEO_VIEWED = 'SET_EVENT_GIFT_VIDEO_VIEWED'
export const SET_EVENT_PAGE_VIEWED = 'SET_EVENT_PAGE_VIEWED'
export const SET_EVENT_EXPERIMENT_VARIANT_DECIDED = 'SET_EVENT_EXPERIMENT_VARIANT_DECIDED'
export const SET_EVENT_EXPERIMENT_DATA = 'SET_EVENT_EXPERIMENT_DATA'
export const SET_EVENT_USER_INTERACTION = 'SET_EVENT_USER_INTERACTION'
export const SET_EVENT_USER_ADD_COMMENT = 'SET_EVENT_USER_ADD_COMMENT'
export const SET_EVENT_LOGIN_FAILED = 'SET_EVENT_LOGIN_FAILED'
export const SET_EVENT_USER_MENU_ITEM_CLICKED = 'SET_EVENT_USER_MENU_ITEM_CLICKED'
export const SET_EVENT_ACCOUNT_CANCEL_OFFER_POPUP = 'SET_EVENT_ACCOUNT_CANCEL_OFFER_POPUP'
export const SET_EVENT_POPUP_MARKETING_PROMO = 'SET_EVENT_POPUP_MARKETING_PROMO'
export const SET_EVENT_VIDEO_IMPRESSED = 'SET_EVENT_VIDEO_IMPRESSED'
export const SET_EVENT_VIDEO_AUDIO_CHANGED = 'SET_EVENT_VIDEO_AUDIO_CHANGED'
export const SET_EVENT_SERIES_IMPRESSED = 'SET_EVENT_SERIES_IMPRESSED'
export const SET_EVENT_PAYPAL_BUTTON_DISPLAYED = 'SET_EVENT_PAYPAL_BUTTON_DISPLAYED'
export const SET_EVENT_PAYPAL_BUTTON_CLICKED = 'SET_EVENT_PAYPAL_BUTTON_CLICKED'
export const SET_EVENT_MEMBERSHIP_BUTTON_CLICKED = 'SET_EVENT_MEMBERSHIP_BUTTON_CLICKED'
export const SET_EVENT_FOOTER_EMAIL_SUBMITTED = 'SET_EVENT_FOOTER_EMAIL_SUBMITTED'
export const SET_EVENT_CART_ABANDON_EMAIL_SUBMITTED = 'SET_EVENT_CART_ABANDON_EMAIL_SUBMITTED'
export const SET_EVENT_GIFT_VIDEO_EMAIL_SUBMITTED = 'SET_EVENT_GIFT_VIDEO_EMAIL_SUBMITTED'
export const SET_EVENT_CART_CHECKOUT_STEP = 'SET_EVENT_CART_CHECKOUT_STEP'
export const SET_EVENT_REFERRAL_LINK_COPY_BUTTON_CLICKED = 'SET_EVENT_REFERRAL_LINK_COPY_BUTTON_CLICKED'
export const SET_EVENT_TILE_CLICK = 'SET_EVENT_TILE_CLICK'
export const SET_EVENT_NAVIGATION_USER_CLICK = 'SET_EVENT_NAVIGATION_USER_CLICK'
export const SET_EVENT_SHARE_VIDEO = 'SET_EVENT_SHARE_VIDEO'
export const SET_EVENT_SHARE_PLAYLIST = 'SET_EVENT_SHARE_PLAYLIST'
export const SET_EVENT_NEW_TO_GAIA = 'SET_EVENT_NEW_TO_GAIA'
export const CREATE_SERVER_EVENT = 'CREATE_SERVER_EVENT'
export const SET_EVENT_LEAD = 'SET_EVENT_LEAD'
export const SET_EVENT_TAG_CLICKED = 'SET_EVENT_TAG_CLICKED'
// Detail Page V2 events actions
export const SET_EVENT_EPISODE_LIST_PLAY_CLICKED = 'SET_EVENT_EPISODE_LIST_PLAY_CLICKED'
export const SET_EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED = 'SET_EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED'
export const SET_EVENT_EPISODE_LIST_TITLE_CLICKED = 'SET_EVENT_EPISODE_LIST_TITLE_CLICKED'
// Ecommerce events actions
export const SET_CREATE_ACCOUNT_PRECURSOR_EVENT = 'SET_CREATE_ACCOUNT_PRECURSOR_EVENT'
export const SET_EVENT_SIGN_UP = 'SET_EVENT_SIGN_UP'
export const SET_EVENT_BEGIN_CHECKOUT = 'SET_EVENT_BEGIN_CHECKOUT'
export const SET_EVENT_PURCHASE = 'SET_EVENT_PURCHASE'
export const SET_EVENT_PURCHASED_PLAN = 'SET_EVENT_PURCHASED_PLAN'

// GA4 events
export const SET_DEFAULT_GA4_EVENT = 'SET_DEFAULT_GA4_EVENT'
export const SET_GA4_VARIABLES = 'SET_GA4_VARIABLES'
export const SET_GA4_INIT_EVENT = 'SET_GA4_INIT_EVENT'

export function setEventDataExperimentVariantDecided (options) {
  const event = buildEventExperimentVariantDecided(options)
  return {
    type: SET_EVENT_EXPERIMENT_VARIANT_DECIDED,
    payload: { event },
  }
}

export function setEventDataExperimentData (options) {
  const event = buildEventExperimentData(options)
  return {
    type: SET_EVENT_EXPERIMENT_DATA,
    payload: { event },
  }
}

export function setEventPageContextVideoPlayed (value) {
  return {
    type: SET_EVENT_PAGE_CONTEXT_VIDEO_PLAYED,
    payload: value,
  }
}

export function setEventDataVideoPlayed (options) {
  const event = buildEventVideoPlayed(options)
  return {
    type: SET_EVENT_VIDEO_PLAYED,
    payload: { event },
  }
}

export function setEventDataVideoCompleted (options) {
  const event = buildEventVideoCompleted(options)
  return {
    type: SET_EVENT_VIDEO_COMPLETED,
    payload: { event },
  }
}

export function setEventDataVideoEngaged (options) {
  const event = buildEventVideoEngaged(options)
  return {
    type: SET_EVENT_VIDEO_ENGAGED,
    payload: { event },
  }
}

export function setEventDataUserAddComment (options) {
  const event = buildEventUserAddComment(options)
  return {
    type: SET_EVENT_USER_ADD_COMMENT,
    payload: { event },
  }
}

export function setEventVideoAudioChanged (options) {
  const { lang } = options
  return {
    type: SET_EVENT_VIDEO_AUDIO_CHANGED,
    payload: { lang },
  }
}

export function setEventSeriesImpressed (options) {
  const { language } = options
  const event = buildEventSeriesImpressed(options)
  return {
    type: SET_EVENT_SERIES_IMPRESSED,
    payload: { event, language },
  }
}

export function setEventVideoImpressed (options) {
  const { auth, language } = options
  const event = buildEventVideoImpressed(options)
  return {
    type: SET_EVENT_VIDEO_IMPRESSED,
    payload: { auth, event, language },
  }
}

export function setEventEpisodeVisited (options) {
  const event = buildEventEpisodeVisited(options)
  return {
    type: SET_EVENT_EPISODE_VISITED,
    payload: { event },
  }
}

export function setEventVideoVisited (options) {
  const event = buildEventVideoVisited(options)
  return {
    type: SET_EVENT_VIDEO_VISITED,
    payload: { event },
  }
}

export function setEventSeriesVisited (options) {
  const event = buildEventSeriesVisited(options)
  return {
    type: SET_EVENT_SERIES_VISITED,
    payload: { event },
  }
}

export function setEventDataVideoViewQualified (options) {
  const event = buildEventVideoView(options, QUALIFIED)
  return {
    type: SET_EVENT_VIDEO_VIEW_QUALIFIED,
    payload: { event },
  }
}

export function setEventPlaylistVideoAdded (options) {
  return function setEventPlaylistVideoAddedThunk (dispatch, getState) {
    const {
      resolver, auth, page, app,
    } = getState()
    const { location } = resolver
    const params = _merge({}, options, {
      location, auth, page, app,
    })
    const event = buildEventPlaylistVideoAdded(params)
    dispatch({
      type: SET_EVENT_PLAYLIST_VIDEO_ADDED,
      payload: { auth, event },
    })
  }
}

export function setEventGiftVideoViewed (options) {
  const { auth } = options
  const event = buildEventGiftVideoViewed(options)
  return {
    type: SET_EVENT_GIFT_VIDEO_VIEWED,
    payload: { auth, event },
  }
}

export function setEventPageViewed (options) {
  const { auth, location, page } = options
  const event = buildEventPageViewed(options)
  return {
    type: SET_EVENT_PAGE_VIEWED,
    payload: {
      auth, event, location, page,
    },
  }
}

export function setEventPaypalButtonDisplayed (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_PAYPAL_BUTTON_DISPLAYED,
    payload: { event },
  }
}

export function setFooterEmailDataLayer (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_FOOTER_EMAIL_SUBMITTED,
    payload: { event },
  }
}

export function setCartAbandonEmailDataLayer (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_CART_ABANDON_EMAIL_SUBMITTED,
    payload: { event },
  }
}

export function setGiftVideoEmailDataLayer (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_GIFT_VIDEO_EMAIL_SUBMITTED,
    payload: { event },
  }
}

export function setEventStartMembershipClicked (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_MEMBERSHIP_BUTTON_CLICKED,
    payload: { event },
  }
}

export function setEventPaypalButtonClicked (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_PAYPAL_BUTTON_CLICKED,
    payload: { event },
  }
}
export function setEventDataTileClickPlay (options) {
  const event = buildEventTileClick({ ...options, action: EVENT_ACTION_PLAY_CLICK })
  return {
    type: SET_EVENT_TILE_CLICK,
    payload: { event },
  }
}

export function setEventDataTileClickDetailPage (options) {
  const event = buildEventTileClick({ ...options, action: EVENT_ACTION_CLICK_DETAIL_PAGE })
  return {
    type: SET_EVENT_TILE_CLICK,
    payload: { event },
  }
}

export function setEventNavigationUserClickV1 (options) {
  const event = buildEventNavigationUserClick({ ...options, eventCategory: CATEGORY_NAVIGATION_V1 })
  return {
    type: SET_EVENT_NAVIGATION_USER_CLICK,
    payload: { event },
  }
}

export function setEventNavigationUserClickV2 (options) {
  const event = buildEventNavigationUserClick({ ...options, eventCategory: CATEGORY_NAVIGATION_V2 })
  return {
    type: SET_EVENT_NAVIGATION_USER_CLICK,
    payload: { event },
  }
}

export function setEventNavigationUserClickV3 (options) {
  const event = buildEventNavigationUserClick({ ...options, eventCategory: CATEGORY_NAVIGATION_V3 })
  return {
    type: SET_EVENT_NAVIGATION_USER_CLICK,
    payload: { event },
  }
}

export function setEventDataUserShareVideoRedditCTA (options) {
  const event = buildEventUserShareVideo({ ...options, eventLabel: EVENT_LABEL_REDDIT })
  return {
    type: SET_EVENT_SHARE_VIDEO,
    payload: { event },
  }
}

export function setEventDataUserShareVideoFacebookCTA (options) {
  const event = buildEventUserShareVideo({ ...options, eventLabel: EVENT_LABEL_FACEBOOK })
  return {
    type: SET_EVENT_SHARE_VIDEO,
    payload: { event },
  }
}

export function setEventDataUserShareVideoTwitterCTA (options) {
  const event = buildEventUserShareVideo({ ...options, eventLabel: EVENT_LABEL_TWITTER })
  return {
    type: SET_EVENT_SHARE_VIDEO,
    payload: { event },
  }
}

export function setEventDataUserShareVideoEmailCTA (options) {
  const event = buildEventUserShareVideo({ ...options, eventLabel: EVENT_LABEL_EMAIL })
  return {
    type: SET_EVENT_SHARE_VIDEO,
    payload: { event },
  }
}

export function setEventDataUserShareVideoEmbedCTA (options) {
  const event = buildEventUserShareVideo({ ...options, eventLabel: EVENT_LABEL_EMBED })
  return {
    type: SET_EVENT_SHARE_VIDEO,
    payload: { event },
  }
}

export function setEventDataUserShareVideoGenerateLinkCTA (options) {
  const event = buildEventUserShareVideo({ ...options, eventLabel: EVENT_LABEL_GENERATE_LINK })
  return {
    type: SET_EVENT_SHARE_VIDEO,
    payload: { event },
  }
}

export function setEventDataUserSharePlaylistLinkCTA (options) {
  const event = buildEventUserSharePlaylist({ ...options })
  return {
    type: SET_EVENT_SHARE_PLAYLIST,
    payload: { event },
  }
}
export function setEventDataUserNewToGaiaLink (options) {
  const event = buildEventNewToGaiaClick(options)
  return {
    type: SET_EVENT_NEW_TO_GAIA,
    payload: { event },
  }
}

export function setEventUserInteraction (options) {
  return function setEventUserInteractionRedux (dispatch, getState) {
    const state = getState()
    const {
      app, auth, page, user,
    } = state
    const language = user.getIn(['data', 'language'])
    const newOptions = _merge({}, options, {
      auth, app, page, language,
    })
    dispatch(setEventUserInteractionBuilt(newOptions))
  }
}

function setEventUserInteractionBuilt (options) {
  const event = buildEventUserInteraction(options)
  return {
    type: SET_EVENT_USER_INTERACTION,
    payload: { event },
  }
}

export function setEventAccountCancelOfferPopup (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_ACCOUNT_CANCEL_OFFER_POPUP,
    payload: { event },
  }
}

export function setPopupMarketingPromo (options) {
  const event = buildCustomDataLayerEvent(options)
  return {
    type: SET_EVENT_POPUP_MARKETING_PROMO,
    payload: { event },
  }
}

export function setEventUserMenuClicked (options) {
  const {
    auth,
    delay = TRACK_EVENT_DELAY_MS,
  } = options
  const event = buildEventUserMenuItemClicked(options)
  return {
    type: SET_EVENT_USER_MENU_ITEM_CLICKED,
    payload: { auth, event },
    meta: {
      delay,
    },
  }
}

export function setEventCartCheckoutStep (options) {
  const event = buildCheckoutStepDataLayerObject(options)
  return {
    type: SET_EVENT_CART_CHECKOUT_STEP,
    payload: { event },
  }
}

export function setDefaultGaEvent (event) {
  return {
    type: SET_DEFAULT_EVENT,
    payload: { event },
  }
}

export function createServerEvent (options) {
  return {
    type: CREATE_SERVER_EVENT,
    payload: options,
  }
}

export function setCreateAccountPrecursorGaEvent (event) {
  return {
    type: SET_CREATE_ACCOUNT_PRECURSOR_EVENT,
    payload: { event },
  }
}

export function setEventLead (email) {
  return {
    type: SET_EVENT_LEAD,
    payload: email,
  }
}

export function setEventTagClicked (event) {
  return {
    type: SET_EVENT_TAG_CLICKED,
    payload: { event },
  }
}

export function setEventEpisodeListPlayClicked (options) {
  const event = buildEpisodeListPlayClicked(options)
  return {
    type: SET_EVENT_EPISODE_LIST_PLAY_CLICKED,
    payload: { event },
  }
}

export function setEventEpisodeListAddToPlaylistClicked (options) {
  const event = buildEpisodeListAddToPlaylistClicked(options)
  return {
    type: SET_EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED,
    payload: { event },
  }
}

export function setEventEpisodeListTitleClicked (options) {
  const event = buildEpisodeListTitleClicked(options)
  return {
    type: SET_EVENT_EPISODE_LIST_TITLE_CLICKED,
    payload: { event },
  }
}

export function setDefaultGa4Event (event) {
  return {
    type: SET_DEFAULT_GA4_EVENT,
    payload: { event },
  }
}

export function setGa4Variables (event) {
  return {
    type: SET_GA4_VARIABLES,
    payload: { event },
  }
}

export function setGa4InitEvent () {
  return {
    type: SET_GA4_INIT_EVENT,
  }
}
