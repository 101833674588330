export const EN = 'en'
export const EN_US = 'en-US'
export const ES = 'es'
export const ES_LA = 'es-LA'
export const ES_ES = 'es-ES'
export const DE = 'de'
export const DE_DE = 'de-DE'
export const FR = 'fr'
export const FR_FR = 'fr-FR'
export const DEFAULT_LANGUAGE = 'en'
