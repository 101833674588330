import { Map } from 'immutable'

/**
 * @param {ReduxState} state redux state
 * @returns {Map}
 */
export const selectFeatureTracking = (state) => state.featureTracking || Map()

/**
 * @param {ReduxState} state redux state
 * @returns {Map}
 */
export const selectFeatureTrackingData = (state) => selectFeatureTracking(state).get('data', Map())

/**
 * @param {ReduxState} state redux state
 * @returns {number}
 */
export const selectFeatureTrackingGiftExpiringMessage = (state) => selectFeatureTracking(state).getIn(['data', 'giftExpiringMessage'])
