import { useCallback } from 'react'
import { URL_GIFT as BASE_GIFT_URL } from 'services/url/constants'
import { selectPath } from 'services/resolver/selectors'
import pathToRegexp from 'path-to-regexp'
import { useSelector } from 'react-redux'
import { encodeUrlSku } from 'services/plans'

export const GIFT_SUBROUTE_CREATE = 'create'
export const GIFT_SUBROUTE_REVIEW = 'review'
export const GIFT_SUBROUTE_COMPLETE = 'complete'
export const GIFT_SUBROUTE_PAYMENT = 'payment'

/**
 * @typedef {Object} RouteDetails
 * @property {String} encodedSku
 * @property {String} [subRoute]
 *
 * @param {String} currentPath
 * @returns {RouteDetails}
 */
function getRouteDetails (currentPath) {
  const pathPattern = `${BASE_GIFT_URL}/:sku/:page?`
  const regExp = pathToRegexp(pathPattern)
  const result = regExp.exec(currentPath)
  return {
    encodedSku: result && result[1],
    subRoute: result && result[2],
  }
}

/**
 * React hook that generates a function
 * that can be used to replace the SKU or subRoute, or both, in the current path.
 * @returns {useNextGiftRoute~getNextGiftRoute}
 */
export function useNextGiftRoute () {
  const path = useSelector(selectPath)
  /**
   * @param {Object} params
   * @param {String=} params.sku
   * @param {String=} params.subRoute
   * @return {String}
   */
  const getNextGiftRoute = useCallback(
    ({ sku = '', subRoute: inputSubRoute = '' } = {}) => {
      const {
        encodedSku: currentEncodedSku,
        subRoute: currentSubRoute,
      } = getRouteDetails(path)
      const encodedSku = sku ? encodeUrlSku(sku) : currentEncodedSku
      const subRoute = inputSubRoute || currentSubRoute

      const basePath = `${BASE_GIFT_URL}/${encodedSku}`
      const nextRoute = subRoute ? `${basePath}/${subRoute}` : basePath
      return nextRoute
    },
    [path],
  )
  return getNextGiftRoute
}

export default getRouteDetails
